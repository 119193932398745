import Validators from "~/config/validation/validators/Validators";
import Version from "~/config/Version";
import SelectionEndpointsDefinition from "~/config/definitions/shared/SelectionEndpointsDefinition";

export default Validators.shape({
    description: "Configuration for api",
    shape: {
        url: Validators.string({
            description: "The base URL of the API. Should include the `/api` portion of the URL.",
        }).allowsUndefined,
        defaultLanguage: Validators.string({
            description:
                "The default `language` value used when making API calls. This value can be modified in the UI. If undefined, this is set based on the locale.",
        }).allowsUndefined,
        additionalHeaders: Validators.object({
            description: "Additional headers to send to the API for each call",
            defaultValue: {},
        }),
        configurationEndpoints: Validators.arrayOf({
            description:
                "API endpoints that will be called and are allowed to overwrite the final configuration.",
            items: Validators.string({
                description:
                    "API endpoint that will be called and is allowed to overwrite the final configuration.",
            }).allowsUndefined,
        }),
        selectionEndpoints: Validators.shape({
            description:
                "API endpoints to get and set selected candidates for jobs, or selected jobs for candidates, in a customer specific system.",
            shape: {
                job: SelectionEndpointsDefinition.allowsUndefined,
                candidate: SelectionEndpointsDefinition.allowsUndefined,
            },
        }).allowsUndefined,
        otpExchangeEndpoint: Validators.string({
            description: "API endpoint to use for exchanging the OTP",
        }).allowsUndefined,
        version: Validators.instanceOf({
            description: "the version of the software",
            objectType: Version,
        }),
    },
});
