import * as immutable from "object-path-immutable";
import TranslatableLabel from "~/components/TranslatableLabel";

export class Node {
    id = undefined;
    value = undefined;
    label = undefined;
    parent = undefined;
    children = [];

    constructor(id, value, label) {
        this.id = id;
        this.value = value;
        this.label = label;
    }

    countLeafNodes() {
        return this.children.reduce((count, child) => {
            const childLeafNodeCount = child.countLeafNodes();
            return count + (childLeafNodeCount > 0 ? childLeafNodeCount : 1);
        }, 0);
    }

    *recursiveChildIterator() {
        for (const child of this.children) {
            yield child;
            yield* child.recursiveChildIterator();
        }
    }

    *recursiveParentIterator() {
        let currentParent = this.parent;

        while (currentParent !== undefined) {
            yield currentParent;
            currentParent = currentParent.parent;
        }
    }

    getPath() {
        const labels = [TranslatableLabel.getFinalValue(this.label)];

        for (const parent of this.recursiveParentIterator()) {
            labels.unshift(TranslatableLabel.getFinalValue(parent.label));
        }

        return labels.join(" > ");
    }
}

export function convertToTreeModel(model) {
    if (Array.isArray(model)) {
        return model;
    }

    const idToNode = {};

    // Convert all data elements to nodes
    for (const dataElement of model.data) {
        const id = immutable.get(dataElement, model.valueDefinition.idPath);
        const parentId = immutable.get(dataElement, model.valueDefinition.parentIdPath);
        const value = immutable.get(dataElement, model.valueDefinition.valuePath);
        const label = immutable.get(dataElement, model.valueDefinition.labelPath);

        if (id === undefined || parentId === undefined || value === undefined || label === undefined) {
            continue;
        }

        idToNode[id] = new Node(id, value, label);
        idToNode[id].parentId = parentId;
    }

    // Fill parent and children
    for (const id in idToNode) {
        const parentId = idToNode[id].parentId;
        delete idToNode[id].parentId;

        if (parentId !== undefined && idToNode[parentId] !== undefined) {
            idToNode[id].parent = idToNode[parentId];
            idToNode[parentId].children.push(idToNode[id]);
        }
    }

    // Sort all children arrays by label
    for (const id in idToNode) {
        idToNode[id].children.sort((a, b) => TranslatableLabel.getFinalValue(a.label).localeCompare(TranslatableLabel.getFinalValue(b.label)));
    }

    // Find and return roots
    const roots = [];

    for (const id in idToNode) {
        if (idToNode[id].parent === undefined) {
            roots.push(idToNode[id]);
        }
    }

    return roots;
}
