import Api from "~/api";
import BackendSelection from "~/util/BackendSelection";
import ProfileType from "~/enums/ProfileType";
import {createCachedPromiseContainer} from "~/util/cached-promises";

const CACHE = createCachedPromiseContainer(
    (configuration, index, entityId) => {
        return `${index.selectionEndpoints.get}:${index.name}:${entityId}`;
    },
    (configuration, index, entityId) => {
        let backendSelectionAsyncPromise;

        if (index.selectionEndpoints.getAsync) {
            backendSelectionAsyncPromise = fetchSelectionPromise(
                index.selectionEndpoints.getAsync,
                index.resultType,
                entityId
            );
        } else {
            backendSelectionAsyncPromise = Promise.resolve(BackendSelection.empty());
        }

        return fetchSelectionPromise(index.selectionEndpoints.get, index.resultType, entityId).then(
            backendSelection => {
                return {
                    backendSelection,
                    backendSelectionAsyncPromise: backendSelectionAsyncPromise.then(
                        asyncBackendSelection => {
                            return BackendSelection.merge(backendSelection, asyncBackendSelection);
                        }
                    ),
                };
            }
        );
    }
);

export function fetchBackendSelection(configuration, index, entityId, maximumAge) {
    if (index.selectionEndpoints.get.derivedFromIndex !== undefined) {
        return handleDerivedSelection(configuration, index, entityId, maximumAge);
    }

    return CACHE.get(maximumAge, configuration, index, entityId);
}

function fetchSelectionPromise(endpoint, resultType, entityId) {
    return Api.fetchSelection(endpoint, entityId)
        .catch(e => {
            console.error("Coulnd't get existing selection", e);
            return BackendSelection.fromError(e);
        })
        .then(data => {
            data = convertOldStyleSelectionData(resultType, data);
            return BackendSelection.fromApi(data);
        });
}

function handleDerivedSelection(configuration, index, entityId, maximumAge) {
    const sourceIndexName = index.selectionEndpoints.get.derivedFromIndex;
    const derivationKey = index.selectionEndpoints.get.derivationKey;

    for (const sourceIndex of configuration.indices) {
        if (sourceIndex.name === sourceIndexName && !sourceIndex.disabled) {
            return fetchBackendSelection(configuration, sourceIndex, entityId, maximumAge).then(
                ({backendSelection, backendSelectionAsyncPromise}) => {
                    const derivedBackendSelection = getDerivedSelectionContent(
                        backendSelection,
                        derivationKey
                    );

                    return {
                        backendSelection: derivedBackendSelection,
                        backendSelectionAsyncPromise: backendSelectionAsyncPromise.then(
                            asyncBackendSelection =>
                                BackendSelection.merge(derivedBackendSelection, getDerivedSelectionContent(asyncBackendSelection, derivationKey))
                        ),
                    };
                }
            );
        }
    }

    return Promise.resolve(BackendSelection.empty());
}

function getDerivedSelectionContent(backendSelection, derivationKey) {
    if (backendSelection.derived[derivationKey] !== undefined) {
        return backendSelection.derived[derivationKey];
    } else {
        return BackendSelection.empty();
    }
}

function convertOldStyleSelectionData(resultType, data) {
    if (!Array.isArray(data)) {
        return data;
    }

    if (resultType === ProfileType.CANDIDATE) {
        return {candidatesForJob: data};
    } else {
        return {jobsForCandidate: data};
    }
}
