import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import c from "classnames";
import TranslatableLabel from "~/components/TranslatableLabel";
import TranslatableLabelPropType from "~/prop-types/translatable-label";

export default class Checkbox extends React.PureComponent {
    static propTypes = {
        label: TranslatableLabelPropType,
        checked: PropTypes.bool,
        disabled: PropTypes.bool.isRequired,
        color: PropTypes.oneOf([undefined, "purple", "green"]),
        title: PropTypes.string,
        type: PropTypes.oneOf(["checkbox", "switch"]).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        type: "checkbox",
    };

    constructor(props) {
        super(props);

        this.uuid = uuid();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.updateInput();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateInput();
    }

    render() {
        const {type, label, disabled, color, title} = this.props;

        return (
            <div
                className={c("custom-control", `custom-${type}`, {
                    [`custom-control-${color}`]: color,
                })}
                title={title}
            >
                <input
                    id={`check-${this.uuid}`}
                    type="checkbox"
                    className="custom-control-input"
                    disabled={disabled}
                    onClick={stopPropagation}
                    onChange={this.handleChange}
                    ref={this.inputRef}
                />
                <label
                    className="custom-control-label w-100"
                    htmlFor={`check-${this.uuid}`}
                    onClick={stopPropagation}
                >
                    {TranslatableLabel.getFinalValue(label)}
                </label>
            </div>
        );
    }

    handleChange = e => {
        this.props.onChange(e.target.checked);
        this.updateInput();
    };

    updateInput() {
        if (this.inputRef.current) {
            const {checked} = this.props;
            this.inputRef.current.checked = checked === true;
            this.inputRef.current.indeterminate = checked === undefined;
        }
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}
