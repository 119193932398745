import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import c from "classnames";
import config from "~/config";
import {getTranslatedValue, RANGE_UNIT_TRANSLATIONS} from "~/util/ui-translations";
import {
    getAspectValueMatchedTo,
    getMatchedToMessageId,
    HARD_SKILLS_GREEN,
    JOB_FUNCTIONS_GREEN,
    MatchedTo,
} from "~/util/match";
import ProgressBar from "~/components/ProgressBar";
import styles from "./styles.module.scss";
import baseStyles from "../styles.module.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
    translateAspectName,
    translateProfileTypeName,
} from "~/components/MatchDetailsDialog/MatchQualityTable";

class AspectRow extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        reverseLabel: PropTypes.string.isRequired,
        reverseHelp: PropTypes.string.isRequired,
        matches: PropTypes.array,
        isFirstRow: PropTypes.bool.isRequired,
        resultType: PropTypes.string.isRequired,
    };

    render() {
        return (
            <React.Fragment>
                {this.renderForward()}
                {this.renderReverse()}
            </React.Fragment>
        );
    }

    renderForward() {
        const {name, matches} = this.props;

        return (
            <tr className={styles.thickTopBorder}>
                <td className={baseStyles.stickyColumn}>
                    <FormattedMessage id={`aspect.${name}`} />
                </td>
                {matches.map((match, index) => this.renderMatch(match, index, "FORWARD"))}
            </tr>
        );
    }

    renderReverse() {
        const {intl, name, reverseLabel, reverseHelp, matches, isFirstRow} = this.props;

        if (ASPECTS_WITHOUT_REVERSE.includes(name)) {
            return null;
        }

        return (
            <tr>
                <td className={c(styles.reverseLabel, baseStyles.stickyColumn)}>
                    <FormattedMessage id={reverseLabel} />
                    {reverseHelp && isFirstRow && (
                        <span>
                            <br />
                            <i
                                title={intl.formatMessage({id: reverseHelp})}
                                className="fas fa-question-circle"
                            />
                        </span>
                    )}
                </td>
                {matches.map((match, index) => this.renderMatch(match, index, "REVERSE"))}
            </tr>
        );
    }

    renderMatch(match, index, scoreType) {
        const {name} = this.props;

        const aspects = match.convertedAspects;
        if (!aspects) return null;

        const details = filterByScoreType(match.details[name], scoreType);
        const aspect = aspects[name];

        if (details.length > 0) {
            return (
                <td key={index} className={baseStyles.matchColumn}>
                    {details.map((item, index) =>
                        this.renderDetailsItem(scoreType, item, index, aspect)
                    )}
                </td>
            );
        } else if (aspect && scoreType === "REVERSE") {
            return (
                <td key={index} className={baseStyles.matchColumn}>
                    {this.renderAspectLabels(aspect)}
                </td>
            );
        } else {
            return (
                <td key={index} className={baseStyles.matchColumn}>
                    {this.renderNotAvailable()}
                </td>
            );
        }
    }

    renderDetailsItem(scoreType, item, index, aspect) {
        const {name} = this.props;

        if (scoreType === "FORWARD" && ASPECTS_MATCHED_TO[name] !== undefined) {
            return <div key={index}>{this.renderMatchedToDetailsItem(item, aspect)}</div>;
        } else if (scoreType === "FORWARD") {
            return <div key={index}>{this.renderForwardDetailsItem(item, aspect)}</div>;
        } else {
            return <div key={index}>{this.renderReverseDetailsItem(item, aspect)}</div>;
        }
    }

    renderForwardDetailsItem(item, aspect) {
        const {name} = this.props;
        const icon = item.found ? "check-circle" : "times-circle";
        const label = this.getLabelForItem(item);
        const matchedTo = getAspectValueMatchedTo(item, name);
        return this.renderSingleItem(aspect, true, item, icon, label, {
            score: item.score,
            matchedTo,
        });
    }

    renderReverseDetailsItem(item, aspect) {
        const {name} = this.props;
        const icon = item.found ? "check-circle" : "circle";
        const label = this.getLabelForItem(item);
        const matchedTo = getAspectValueMatchedTo(item, name);
        return this.renderSingleItem(
            aspect,
            false,
            item,
            icon,
            label,
            item.found ? {score: item.score, matchedTo} : {}
        );
    }

    renderMatchedToDetailsItem(item, aspect) {
        const {name} = this.props;
        const matchedTo = getAspectValueMatchedTo(item, name);
        const icon = getIconForMatchedTo(matchedTo, ASPECTS_MATCHED_TO[name]);
        const label = this.getLabelForItem(item);
        return this.renderSingleItem(aspect, true, item, icon, label, {
            score: item.score,
            matchedTo,
        });
    }

    getLabelForItem(item) {
        const {name} = this.props;

        return TRANSLATED_ASPECTS.includes(name) ? (
            <FormattedMessage id={`${name}.${item.label}`} defaultMessage={item.label} />
        ) : (
            getTranslatedValue(name, item.label)
        );
    }

    renderAspectLabels(aspect) {
        //doesn't work, doesn't matter?
        const labels = this.getAspectLabels(aspect);

        if (labels.length > 0) {
            return labels.map((label, index) => (
                <div key={index}>{this.renderSingleItem(aspect, true, "circle", label)}</div>
            ));
        } else {
            return this.renderNotAvailable();
        }
    }

    renderNotAvailable() {
        //doesn't work, doesn't matter?
        return this.renderSingleItem(
            "question-circle",
            <FormattedMessage id="matchQuality.notAvailable" />,
            {classNames: styles.notAvailable}
        );
    }

    getAspectLabels(aspect) {
        const {name} = this.props;
        const aspectType = config(`aspects.${name}.type`);

        switch (aspectType) {
            case "concepts":
            case "keywords":
            case "keywords-tree":
                return aspect.value.map(v => v.label);

            case "locations":
                return aspect.value.map(
                    v => `${v.label} ≤ ${v.range}${RANGE_UNIT_TRANSLATIONS[v.rangeUnit] || ""}`
                );

            case "range":
                return aspect.value ? [`${aspect.value.from} – ${aspect.value.to}`] : [];

            case "float":
            case "integer":
                return aspect.value.value;

            default:
                return [];
        }
    }

    renderSingleItem(aspect, forward, item, icon, label, {score, matchedTo, classNames} = {}) {
        const {name, resultType} = this.props;
        let iconNode = <i className={`far fa-${icon}`} />;

        const noMatch = !item.found;
        const messageId = getMatchedToMessageId(
            matchedTo,
            this.props.name,
            noMatch,
            false,
            forward,
            item.fields
        );
        const aspects = translateAspectName(name);
        const profileType = translateProfileTypeName(resultType.toLowerCase());
        iconNode = (
            <OverlayTrigger
                placement="right"
                delay={{show: 250, hide: 400}}
                overlay={props => (
                    <Tooltip {...props} show={props.show.toString()}>
                        <FormattedMessage
                            id={messageId}
                            values={{
                                aspectType: aspects,
                                job: profileType,
                            }}
                        />
                    </Tooltip>
                )}
            >
                {iconNode}
            </OverlayTrigger>
        );

        return (
            <div className={c(styles.item, classNames)}>
                {iconNode}
                {score !== undefined && (
                    <ProgressBar
                        className={c(styles.itemProgress, "mr-2")}
                        variant="success"
                        now={score}
                        min={0}
                        max={1}
                    />
                )}
                <span>{label}</span>
            </div>
        );
    }
}

function filterByScoreType(details, scoreType) {
    return details ? details.filter(item => item.scoreType === scoreType) : [];
}

function getIconForMatchedTo(matchedTo, greenValues) {
    let icon = "check-circle unsure";

    if (matchedTo === MatchedTo.NOTHING) {
        icon = "times-circle";
    } else if (greenValues.includes(matchedTo)) {
        icon = "check-circle";
    }

    return icon;
}

const TRANSLATED_ASPECTS = ["contractTypes", "workingHours"];

const ASPECTS_WITHOUT_REVERSE = ["locations"];

const ASPECTS_MATCHED_TO = {
    jobFunctions: JOB_FUNCTIONS_GREEN,
    hardSkills: HARD_SKILLS_GREEN,
};

export default injectIntl(AspectRow, {forwardRef: true});
