import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class BooleanValidator extends Validator {
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {boolean}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            if (this.defaultValue !== false) {
                console.warn(
                    (path ? `[${path}] ` : "") +
                        "Default values for booleans should always be false!"
                );
            }

            return this.validate(this.defaultValue, path, rootValue);
        } else if (value === false || value === true) {
            return value;
        } else {
            throw new ValidationError(path, `Expected a boolean, but got ${betterTypeOf(value)}`);
        }
    }
}
