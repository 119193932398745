import uuid from "uuid/v4";
import languages from "@cospired/i18n-iso-languages";
import config from "~/config";
import objectPath from "object-path";
import {rescaleWeightIfEnabled} from "~/util/weights-calculations";
import {importJob} from "~/util/job";
import {importCandidate} from "~/util/candidate";
import {importProfile} from "~/util/match-profile";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";
import AspectType from "~/enums/AspectType";

export function gatherAspects(object, aspects) {
    const overwriteWeights = config("ui.weight.overwrite", false);
    const aspectWeightStats = calculateAspectWeightStats(object, overwriteWeights);
    const preferredLanguage = languages.alpha2ToAlpha3B(config("ui.locale")).toUpperCase();

    for (const aspectName in config("aspects")) {
        if (!EnabledAspectsBlackWhiteList.allows(aspectName)) {
            continue;
        }

        const aspectConfig = config(`aspects.${aspectName}`);
        const aspectPath = aspectConfig.customPath || aspectName;
        const aspect = objectPath.get(object, aspectPath);

        if (aspect === undefined || aspect === null || typeof aspect !== "object") {
            continue;
        }

        let converted;

        try {
            converted = convertAspectFromApi(
                aspectConfig,
                aspect,
                aspectWeightStats,
                preferredLanguage
            );
        } catch (e) {
            console.error("Couldn't convert aspect (possibly inside extraProperties)");
            console.error(e);
            continue;
        }

        if (converted === null) {
            continue;
        }

        if (overwriteWeights) {
            converted = {...converted, weight: aspectConfig.weight};
        }

        if (aspectConfig.forceRequired === true) {
            converted.required = true;
        } else if (aspectConfig.overrideApiRequired) {
            converted.required = aspectConfig.required;
        }

        aspects[aspectName] = converted;
    }
}

export function convertJobFromApi(job) {
    const aspects = {};
    gatherAspects(job, aspects);

    return importJob({
        title: job.jobTitle,
        description: job.jobText,
        matchProfile: {
            aspects,
        },
        extraProperties: job.extraProperties,
    });
}

export function convertCandidateFromApi(candidate) {
    const aspects = {};
    gatherAspects(candidate, aspects);

    let description = [candidate.cvText, candidate.preferredJobTitle, candidate.preferredJobInfo]
        .filter(v => v !== null)
        .join("\n\n");

    if (description === "") {
        if (candidate.jobFunctions !== null && candidate.jobFunctions.value !== null) {
            description = candidate.jobFunctions.value
                .flatMap(concept => concept.labels)
                .join("\n");
        }
    }

    // TODO: Temporary hack
    if (
        !aspects.jobFunctions ||
        !aspects.jobFunctions.value ||
        !aspects.jobFunctions.value.length
    ) {
        if (aspects.hardSkills) {
            aspects.hardSkills.required = true;
        }
    }

    try {
        return importCandidate({
            description,
            matchProfile: {
                aspects,
            },
            workExperiences: convertWorkExperiences(candidate.workExperiences),
            educations: convertEducations(candidate.educations),
            extraProperties: candidate.extraProperties,
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export function convertAspectFromApi(aspectConfig, aspect, aspectWeightStats, preferredLanguage) {
    switch (aspectConfig.type) {
        case "concepts":
            return convertConceptsAspect(
                aspectConfig,
                aspect,
                aspectWeightStats,
                preferredLanguage
            );

        case "keywords":
        case "keywords-tree":
            return convertKeywordsAspect(aspectConfig, aspect, aspectWeightStats);

        case "locations":
            return convertLocationsAspect(aspectConfig, aspect, aspectWeightStats);

        case "range":
            return convertRangeAspect(aspectConfig, aspect, aspectWeightStats);

        case "integer":
        case "float":
            return convertNumberAspect(aspectConfig, aspect, aspectWeightStats);

        default:
            return null;
    }
}

function convertConceptsAspect(aspectConfig, aspect, aspectWeightStats, preferredLanguage) {
    let aspectWeight = rescaleWeightIfEnabled(aspect.weight, aspectWeightStats);

    return {
        type: aspectConfig.type,
        weight: aspectWeight || config("ui.weight.default"),
        required: aspect.required !== undefined && aspect.required !== null ? aspect.required : aspectConfig.required,
        value: aspect.value.map(value => {
            return {
                id: uuid(),
                weight: value.weight ? value.weight * config("ui.weight.scale") : config("ui.weight.default"),
                required: value.required,
                label: getPreferredLabel(value, preferredLanguage),
            };
        }),
    };
}

function getPreferredLabel(conceptValue, preferredLanguage) {
    if (conceptValue.labelsMap !== undefined) {
        let label = getFirstlabel(conceptValue.labelsMap[preferredLanguage]);
        if (label === undefined) label = getFirstlabel(conceptValue.labelsMap["UNIVERSAL"]);
        if (label === undefined) label = getFirstlabel(conceptValue.labelsMap["ENG"]);
        if (label === undefined && Object.values(conceptValue.labelsMap).length > 0)
            label = getFirstlabel(Object.values(conceptValue.labelsMap)[0]);
        if (label !== undefined) return label;
    }

    return conceptValue.labels ? conceptValue.labels[0] : "";
}

function getFirstlabel(labels) {
    if (!labels) {
        return undefined;
    } else if (!Array.isArray(labels)) {
        return undefined;
    } else if (labels.length === 0) {
        return undefined;
    } else {
        return labels[0];
    }
}

function convertKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    if (aspect.profileValue || aspectConfig.temporarySendAsProfileAspectFix) {
        return convertCustomKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights);
    }

    let aspectWeight = rescaleWeightIfEnabled(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight || config("ui.weight.default"),
        required: aspect.required !== undefined && aspect.required !== null ? aspect.required : aspectConfig.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight ? value.weight * config("ui.weight.scale") : config("ui.weight.default"),
            required: value.required,
            label: value.value,
        })),
    };
}

function convertCustomKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    const aspectWeight = rescaleWeightIfEnabled(aspect.profileWeight, sumOfAspectWeights);
    const valueArray = aspect.profileValue !== undefined ? aspect.profileValue : aspect.value;

    return {
        type: aspectConfig.type,
        weight: aspectWeight || config("ui.weight.default"),
        required: aspect.profileRequirement === "REQUIRED",
        value: valueArray.map(value => ({
            id: uuid(),
            weight: value.weight ? value.weight * config("ui.weight.scale") : config("ui.weight.default"),
            required: value.required,
            label: value.term,
        })),
    };
}

function convertLocationsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeightIfEnabled(aspect.weight, sumOfAspectWeights);

    return {
        type: aspectConfig.type,
        weight: aspectWeight || config("ui.weight.default"),
        required: aspect.required !== undefined && aspect.required !== null ? aspect.required : aspectConfig.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight ? value.weight * config("ui.weight.scale") : config("ui.weight.default"),
            required: value.required,
            label: value.label,
            range: config("ui.overrideApiRange") || value.range === -1 ? config("ui.defaultRange") : value.range,
            rangeUnit: value.rangeUnit && value.rangeUnit !== 'UNKNOWN' ? value.rangeUnit : "KILOMETER",
        })),
    };
}

function convertRangeAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeightIfEnabled(aspect.weight, sumOfAspectWeights);
    const actualValue = Array.isArray(aspect.value) ? aspect.value[0] : aspect.value;

    return {
        type: aspectConfig.type,
        weight: aspectWeight || config("ui.weight.default"),
        required: aspect.required !== undefined && aspect.required !== null ? aspect.required : aspectConfig.required,
        value: {
            weight: actualValue.weight ? actualValue.weight * config("ui.weight.scale") : config("ui.weight.default"),
            required: actualValue.required,
            from: actualValue.from,
            to: actualValue.to,
        },
    };
}

function convertNumberAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeightIfEnabled(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight || config("ui.weight.default"),
        required: aspect.required !== undefined && aspect.required !== null ? aspect.required : aspectConfig.required,
        value: {
            weight: aspect.value.weight ? aspect.value.weight * config("ui.weight.scale") : config("ui.weight.default"),
            required: aspect.value.required,
            value: aspect.value.value,
        },
    };
}

function convertWorkExperiences(workExperiences) {
    const result = removeApiWrappers(workExperiences || []).map(convertWorkExperience);

    if (config("ui.sortWorkExperiences")) {
        result.sort((a, b) => reverseLocaleCompareUndefinedLast(a.dateFrom, b.dateFrom));
    }

    return result;
}

function convertEducations(educations) {
    const result = removeApiWrappers(educations || []).map(convertEducation);

    if (config("ui.sortEducations")) {
        result.sort((a, b) => reverseLocaleCompareUndefinedLast(a.dateFrom, b.dateFrom));
    }

    return result;
}

function reverseLocaleCompareUndefinedLast(a, b) {
    if (a === undefined && b === undefined) {
        return 0;
    } else if (a === undefined) {
        return 1;
    } else if (b === undefined) {
        return -1;
    } else {
        return b.localeCompare(a);
    }
}

function convertWorkExperience(workExperience) {
    return {
        id: uuid(),
        title: findAspectTypeValueOrDefaultToProperty(
            workExperience,
            AspectType.WORK_EXPERIENCE_TITLE,
            "title"
        ),
        company: findAspectTypeValueOrDefaultToProperty(
            workExperience,
            AspectType.WORK_EXPERIENCE_COMPANY,
            "company"
        ),
        text: findAspectTypeValueOrDefaultToProperty(
            workExperience,
            AspectType.WORK_EXPERIENCE_TEXT,
            "text"
        ),
        dateFrom: findAspectTypeValueOrDefaultToProperty(
            workExperience,
            AspectType.WORK_EXPERIENCE_FROM,
            "dateFrom"
        ),
        dateTo: findAspectTypeValueOrDefaultToProperty(
            workExperience,
            AspectType.WORK_EXPERIENCE_TO,
            "dateTo"
        ),
    };
}

function convertEducation(education) {
    return {
        id: uuid(),
        field: findAspectTypeValueOrDefaultToProperty(
            education,
            AspectType.EDUCATION_HISTORY_FIELD,
            "field"
        ),
        degree: findAspectTypeValueOrDefaultToProperty(
            education,
            AspectType.EDUCATION_HISTORY_DEGREE,
            "degree"
        ),
        institute: findAspectTypeValueOrDefaultToProperty(
            education,
            AspectType.EDUCATION_HISTORY_INSTITUTE,
            "institute"
        ),
        info: findAspectTypeValueOrDefaultToProperty(
            education,
            AspectType.EDUCATION_HISTORY_INFO,
            "info"
        ),
        dateFrom: findAspectTypeValueOrDefaultToProperty(
            education,
            AspectType.EDUCATION_HISTORY_FROM,
            "dateFrom"
        ),
        dateTo: findAspectTypeValueOrDefaultToProperty(
            education,
            AspectType.EDUCATION_HISTORY_TO,
            "dateTo"
        ),
    };
}

function findAspectTypeValueOrDefaultToProperty(object, aspectType, propertyName) {
    const aspectTypeValue = findAspectTypeValue(object, aspectType);
    return aspectTypeValue !== undefined ? removeApiWrappers(aspectTypeValue) : removeApiWrappers(object[propertyName]);
}

function findAspectTypeValue(object, aspectType) {
    for (const key in object) {
        if (!object.hasOwnProperty(key)) continue;
        const value = object[key];

        if (value.aspectType === aspectType) {
            return value.value;
        }
    }

    return undefined;
}

function removeApiWrappers(value) {
    if (value === undefined) {
        return undefined;
    } else if (value.text !== undefined && value.language !== undefined) {
        return value.text;
    } else if (value.aspectType !== undefined) {
        return value.value;
    } else {
        return value;
    }
}

function calculateAspectWeightStats(aspects, overwriteWeights) {
    let sumOfAspectWeights = 0;
    let maxAspectWeight = 0;
    for (const name in aspects) {
        let configAspect = config(`aspects.${name}`, undefined);
        if (configAspect === undefined) {
            continue;
        }
        const aspect = aspects[name];
        if (aspect === null) {
            continue;
        }

        let weight = overwriteWeights ? configAspect.weight : aspect.weight;
        sumOfAspectWeights += weight;
        maxAspectWeight = Math.max(weight, maxAspectWeight);
    }

    return {
        sum: sumOfAspectWeights,
        max: maxAspectWeight,
    };
}

export function convertParsedQueryItemsMatchProfile(keywords) {
    let matchProfile = importProfile({aspects: {}});
    if (!keywords) return matchProfile;

    for (const item of keywords) {
        switch (item.type) {
            case "FUNCTION":
                matchProfile = addValueToAspect(matchProfile, "jobFunctions", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "HARD_SKILL":
            case "COMPANY":
            case "TEXT":
                matchProfile = addValueToAspect(matchProfile, "hardSkills", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "SOFT_SKILL":
                matchProfile = addValueToAspect(matchProfile, "softSkills", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "LOCATION":
                matchProfile = addValueToAspect(matchProfile, "locations", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                    range: 10,
                    rangeUnit: "KILOMETER",
                });
                break;

            default:
                break;
        }
    }

    return matchProfile;
}

function addValueToAspect(matchProfile, aspect, value) {
    return {
        ...matchProfile,
        aspects: {
            ...matchProfile.aspects,
            [aspect]: {
                ...matchProfile.aspects[aspect],
                value: [...matchProfile.aspects[aspect].value, {...value, id: uuid()}],
            },
        },
    };
}
