import {gatherAspects} from "~/util/convert-from-api";

/**
 * IMPORTANT: Subclasses MUST accept a constructor that takes apiData as its only argument.
 */
export default class Match {
    constructor(apiData) {
        this._data = apiData;
    }

    get id() {
        return this._data.id;
    }

    get score() {
        return this._data.score;
    }

    get aspectDetails() {
        return this._data.aspectDetails;
    }

    get details() {
        return this._data.details;
    }

    get explanations() {
        return this._data.explanations;
    }

    get document() {
        return this._data.document;
    }

    /**
     * @deprecated Do not use in new code
     */
    get deprecatedEntity() {
        throw new Error("Not supported");
    }

    get detailsText() {
        return ""; // TODO: Find a better way to handle this
    }

    get convertedAspects() {
        const aspects = {};
        gatherAspects(this._data.document, aspects);
        return aspects;
    }

    updateApiData(updater) {
        return new this.constructor(updater(this._data));
    }
}
