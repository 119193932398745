import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import uuid from "uuid/v4";
import config from "~/config";
import AspectPropType from "~/prop-types/aspect";
import Weight from "~/components/Weight";
import updateById from "~/util/update-by-id";
import styles from "./styles.module.scss";
import Location from "./Location";
import ListGroup from "react-bootstrap/ListGroup";
import Checkbox from "~/components/Checkbox";

export default class LocationsAspectEditor extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        aspectConfig: AspectPropType.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            newLocation: createNewLocation(),
        };

        this.newLocationRef = React.createRef();
    }

    reset() {
        this.setState({newLocation: createNewLocation()})
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                {this.renderValues()}
            </Card>
        );
    }

    renderHeader() {
        const {title, aspect, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={true}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderValues() {
        const {aspect, aspectConfig} = this.props;

        const values = aspect.value.map(value => (
            <Location
                key={value.id}
                value={value}
                allowMultipleLocations={aspectConfig.allowMultipleLocations || false}
                onChange={this.handleLocationChange}
                onDelete={this.handleLocationDelete}
            />
        ));

        if (aspectConfig.allowMultipleLocations || aspect.value.length === 0) {
            this.renderNewValue(values);
        }

        return <ListGroup variant="flush">{values}</ListGroup>;
    }

    renderNewValue(values) {
        const {aspectConfig} = this.props;
        const {newLocation} = this.state;

        values.push(
            <Location
                key={newLocation.id}
                value={newLocation}
                allowMultipleLocations={aspectConfig.allowMultipleLocations || false}
                onChange={this.handleNewLocationChange}
                ref={this.newLocationRef}
            />
        );
    }

    handleLocationChange = (id, location) => {
        const {name, aspect, onChange} = this.props;
        const nextLocations = updateById(aspect.value, id, location);
        onChange(name, {...aspect, value: nextLocations});
    };

    handleNewLocationChange = (id, location) => {
        if (location.label === "") {
            this.setState({newLocation: location});
            return;
        }

        const {name, aspect, onChange} = this.props;
        const nextLocations = [];

        for (const existingLocation of aspect.value) {
            if (existingLocation.id === id) {
                // The location was already added, we can just ignore this second attempt.
                // No need to update the state either, that should have already been done.
                return;
            }

            nextLocations.push(existingLocation);
        }

        nextLocations.push(location);

        this.setState({newLocation: createNewLocation()});
        onChange(name, {...aspect, value: nextLocations});
    };

    handleLocationDelete = id => {
        const {name, aspect, onChange} = this.props;
        const nextValue = aspect.value.filter(location => location.id !== id);
        onChange(name, {...aspect, value: nextValue});

        // We use setTimeout to place this block of code at the very end of the
        // event queue, because the blur event is processed before the next
        // active element is focused. And we only want to focus the new value
        // input if there's no other element focused.
        setTimeout(() => {
            if (
                document.activeElement !== null &&
                document.activeElement !== document.body
            ) {
                return;
            }

            if (this.newLocationRef.current) {
                this.newLocationRef.current.focus();
            }
        }, 0);
    };

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };
}

function createNewLocation() {
    return {
        id: uuid(),
        weight: config("ui.weight.default"),
        required: false,
        label: "",
        range: undefined,
        rangeUnit: config("enums.rangeUnit.0"),
    };
}
