import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {FormattedMessage} from "react-intl";
import Table from "react-bootstrap/Table";
import Match from "./Match";
import styles from "./match-list.module.scss";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import TranslatableLabel from "~/components/TranslatableLabel";
import BackendSelection from "~/util/BackendSelection";
import Checkbox from "~/components/Checkbox";
import SelectAllState from "~/enums/SelectAllState";
import config from "~/config";
import DynamicProperty from "~/prop-types/dynamic-property";
import NumberInput from "~/components/NumberInput";
import {createSelector} from "reselect";

export default class MatchList extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                score: PropTypes.number.isRequired,
                details: PropTypes.object.isRequired,
                explanations: PropTypes.array,
            })
        ).isRequired,
        annotations: PropTypes.objectOf(PropTypes.string).isRequired,
        hideDetailsColumn: PropTypes.bool.isRequired,
        extraColumns: PropTypes.arrayOf(
            PropTypes.shape({
                label: TranslatableLabelPropType.isRequired,
                property: DynamicProperty.isRequired,
            })
        ),
        matchInformationGetters: PropTypes.objectOf(PropTypes.func).isRequired,
        allowSelection: PropTypes.bool.isRequired,
        selection: PropTypes.array.isRequired,
        selectAllState: SelectAllState.propType,
        existingSelection: PropTypes.instanceOf(BackendSelection),
        noResultsLabel: PropTypes.string.isRequired,
        className: PropTypes.string,
        renderDetailsColumnHeader: PropTypes.func.isRequired,
        renderDetailsColumn: PropTypes.func.isRequired,
        renderMatchActions: PropTypes.func.isRequired,
        isGreenMatch: PropTypes.func.isRequired,
        openExternalExistingSelection: PropTypes.func,
        onView: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func,
        onSelectAllChange: PropTypes.func,
    };

    static defaultProps = {
        annotations: {},
        selection: {},
        allowSelection: false,
        renderMatchActions: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectAllAmount: undefined,
        };

        this.mainRef = React.createRef();
        this.selectionSelector = createSelectionSelector();
    }

    scrollToTop() {
        this.mainRef.current.scrollTop = 0;
    }

    render() {
        const {className} = this.props;

        return (
            <div className={c(styles.matchList, className)} ref={this.mainRef}>
                <Table striped className={c(styles.table, styles.stickyHeader)} size="sm">
                    {this.renderHeader()}
                    {this.renderRows()}
                </Table>
            </div>
        );
    }

    renderHeader() {
        const {
            allowSelection,
            existingSelection,
            renderDetailsColumnHeader,
            hideDetailsColumn,
            extraColumns,
        } = this.props;

        const nowrap = config("ui.resultColumnsNoWrap");

        return (
            <thead className={c(styles.header)}>
                <tr>
                    {allowSelection && existingSelection !== undefined && (
                        <th className={styles.existingSelectionColumn}>
                            <div />
                        </th>
                    )}
                    {allowSelection && (
                        <th className={styles.selectionColumn}>
                            <div>{this.renderSelectAllUi()}</div>
                        </th>
                    )}
                    <th className={c(styles.relevanceColumn, styles.headerCell)}>
                        <div>
                            <FormattedMessage id="column.relevance" />
                        </div>
                    </th>
                    {!hideDetailsColumn && (
                        <th
                            className={c(
                                styles.detailsColumn,
                                {[styles.nowrap]: nowrap},
                                styles.headerCell
                            )}
                        >
                            <div>{renderDetailsColumnHeader()}</div>
                        </th>
                    )}
                    {extraColumns &&
                        extraColumns.map(extraColumn => (
                            <th
                                key={extraColumn.property}
                                style={{width: extraColumn.width}}
                                className={c({[styles.nowrap]: nowrap}, styles[extraColumn.class])}
                            >
                                <div>
                                    <TranslatableLabel value={extraColumn.label} />
                                </div>
                            </th>
                        ))}
                    <th className={c(styles.contextToggleColumn, styles.headerCell)}>
                        <div />
                    </th>
                </tr>
            </thead>
        );
    }

    renderRows() {
        const {
            matches,
            annotations,
            matchInformationGetters,
            allowSelection,
            selection,
            existingSelection,
            noResultsLabel,
            renderDetailsColumn,
            renderMatchActions,
            isGreenMatch,
            hideDetailsColumn,
            extraColumns,
            openExternalExistingSelection,
            onView,
        } = this.props;

        return (
            <tbody>
                {matches.length === 0 && (
                    <tr>
                        <td className="text-center" colSpan={this.columnCount()}>
                            <FormattedMessage id={noResultsLabel} />
                        </td>
                    </tr>
                )}
                {matches.map(match => {
                    return (
                        <Match
                            key={match.id}
                            match={match}
                            annotationKey={annotations[match.id]}
                            matchInformationGetters={matchInformationGetters}
                            existingSelection={existingSelection}
                            openExternalExistingSelection={openExternalExistingSelection}
                            isSelected={this.selectionSelector(selection)[match.id] === true}
                            allowSelection={allowSelection}
                            showExistingSelection={existingSelection !== undefined}
                            hideDetailsColumn={hideDetailsColumn}
                            extraColumns={extraColumns}
                            renderDetailsColumn={renderDetailsColumn}
                            renderMatchActions={renderMatchActions}
                            isGreenMatch={isGreenMatch}
                            onView={onView}
                            onSelectedChange={this.handleSelectedChange}
                        />
                    );
                })}
            </tbody>
        );
    }

    renderSelectAllUi() {
        const {selectAllState, onSelectAllChange} = this.props;
        const {selectAllAmount} = this.state;

        if (!selectAllState) {
            return null;
        }

        if (config("ui.selectAllCustomizable", false)) {
            return (
                <NumberInput
                    className={c("form-control", "form-control-sm", styles.selectAllAmount)}
                    value={selectAllAmount}
                    onChange={this.handleSelectAllAmountChange}
                    onReturnPress={this.handleSelectAllReturnPress}
                />
            );
        } else {
            return (
                <Checkbox
                    checked={selectAllState === SelectAllState.SELECTED_ALL}
                    disabled={selectAllState === SelectAllState.SELECTING}
                    onChange={onSelectAllChange}
                />
            );
        }
    }

    columnCount() {
        const {allowSelection, extraColumns, existingSelection} = this.props;

        return (
            3 +
            (extraColumns ? extraColumns.length : 0) +
            (allowSelection ? 1 : 0) +
            (allowSelection && existingSelection !== undefined ? 1 : 0)
        );
    }

    handleSelectedChange = (id, selected) => {
        const {selection, onSelectionChange} = this.props;

        const nextSelectionAsObject = {...this.selectionSelector(selection)};

        if (selected) {
            nextSelectionAsObject[id] = true;
        } else {
            delete nextSelectionAsObject[id];
        }

        onSelectionChange(Object.keys(nextSelectionAsObject));
    };

    handleSelectAllAmountChange = selectAllAmount => {
        this.setState({selectAllAmount});
    };

    handleSelectAllReturnPress = () => {
        const {onSelectAllChange} = this.props;
        const {selectAllAmount} = this.state;

        if (selectAllAmount > 0) {
            onSelectAllChange(selectAllAmount);
        } else {
            onSelectAllChange(false);
        }

        this.setState({selectAllAmount: undefined});
    };
}

function createSelectionSelector() {
    return createSelector(
        selection => selection,
        selection => {
            return selection.reduce((selection, id) => {
                selection[id] = true;
                return selection;
            }, {});
        }
    );
}
