import Validator from "~/config/validation/validators/Validator";
import {betterTypeOf, childPath} from "~/config/validation/validators/util";
import ValidationError from "~/config/validation/ValidationError";

export default class ArrayOfValidator extends Validator {
    constructor(description, itemValidator) {
        super(description, undefined);
        this.itemValidator = itemValidator;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {array}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (!Array.isArray(value)) {
            throw new ValidationError(path, `Expected an array, but got ${betterTypeOf(value)}`);
        }
        const result = [];

        for (let index = 0; index < value.length; index++) {
            result.push(this.itemValidator.validate(value[index], childPath(path, index), rootValue));
        }

        return result;
    }
}

Object.defineProperty(Validator.prototype, "arrayOf", {
    get: function() {
        return new ArrayOfValidator(this)
    },
});

