import React from "react";
import PropTypes from "prop-types";
import BasicPageLayout from "~/components/BasicPageLayout";
import {getDynamicProperty} from "~/util/match";
import config from "~/config";
import {indexDefaults} from "~/config/base-config";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";
import MatchDetailsOverview from "~/components/MatchDetailsOverview";
import ProfileType from "~/enums/ProfileType";

export default class StandaloneMatchDetails extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired, // react-router
        sourceIndex: PropTypes.string,
        sourceId: PropTypes.string,
        targetIndex: PropTypes.string,
        targetId: PropTypes.string,
        result: PropTypes.any,
        error: PropTypes.any,
        setSourceAndTarget: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {match, setSourceAndTarget} = this.props;

        setSourceAndTarget(
            match.params.sourceIndex,
            match.params.sourceId,
            match.params.targetIndex,
            decodeURIComponent(match.params.targetId)
        );
    }

    render() {
        const {result, error} = this.props;
        return (
            <BasicPageLayout isLoading={!result && !error}>{this.renderContent()}</BasicPageLayout>
        );
    }

    renderContent() {
        const {result} = this.props;

        if (!result) {
            return null;
        }

        const targetIndexConfig = this.getTargetIndexConfig();

        const detailsTitleProperty = config(
            "features.profileToCandidates.indices.0.detailsTitleProperty",
            undefined
        ); // TODO

        const labels = {
            forwardLabel: <FormattedMessage id="matchQuality.searchProfile" />,
            forwardSubLabel: undefined,
            reverseLabel: getDynamicProperty(result.document, detailsTitleProperty) || "—",
        };

        return (
            <div className={styles.content}>
                <MatchDetailsOverview
                    scoreType={targetIndexConfig.scoreType}
                    resultType={targetIndexConfig.resultType}
                    match={result}
                    extraPropertiesOrganization={targetIndexConfig.extraProperties}
                    extraPropertyPhoneNumbers={targetIndexConfig.extraPropertyPhoneNumbers}
                    disableWhatsappButton={targetIndexConfig.disableWhatsappButton || config("ui.disableWhatsappButton", false)}
                    forwardLabel={labels.forwardLabel}
                    forwardSubLabel={labels.forwardSubLabel}
                    reverseLabel={labels.reverseLabel}
                    hideForwardColumn={false}
                    externalDetailsTabDefaultActive={false}
                />
            </div>
        );
    }

    getTargetIndexConfig() {
        // TODO: This probably needs its own configuration...
        // TODO: Result type is hardcoded to ProfileType.CANDIDATE

        const {targetIndex} = this.props;

        if (targetIndex) {
            const indexConfigs = config("features.profileToCandidates.indices", []);

            for (const indexConfig of indexConfigs) {
                if (indexConfig.name === targetIndex) {
                    return {
                        ...indexConfig,
                        resultType: ProfileType.CANDIDATE,
                    };
                }
            }
        }

        return {
            ...indexDefaults(),
            resultType: ProfileType.CANDIDATE,
        };
    }
}
