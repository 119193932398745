import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Value from "./Value";
import styles from "./styles.module.scss";

export default class ObjectValue extends React.PureComponent {
    static propTypes = {
        value: PropTypes.any,
    };

    render() {
        const {value} = this.props;

        if (containsObjects(value)) {
            return value.map((item, index) => (
                <div key={index} className={styles.slightlySpaced}>
                    <Value value={item} />
                </div>
            ));
        } else {
            return (
                <Table bordered={true} className={styles.objectValue} size="sm">
                    <tbody>
                        {value.map((item, index) => (
                            <tr key={index}>
                                <td style={{width: "100%"}}>
                                    <Value value={item} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            );
        }
    }
}

function containsObjects(value) {
    if (
        value.length > 0 &&
        !Array.isArray(value[0]) &&
        value[0] instanceof Object &&
        !(value[0] instanceof String)
    ) {
        return true;
    }
}
