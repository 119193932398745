import Validators from "~/config/validation/validators/Validators";
import ValidationError from "~/config/validation/ValidationError";

export default Validators.objectOf({
    description: "Configuration for sort",
    defaultValue: {},
    values: Validators.arrayOf({
        description:
            "Keys are sort group names, values are an array of sort options. Sort group names provided here are the values.",
        items: Validators.shape({
            description:
                "used for the `filters` property in `features.jobToCandidates.indices.N`, `features.profileToCandidates.indices.N` and `features.profileToJobs.indices.N`.",
            shape: {
                id: Validators.string({
                    description: "id of sort option",
                }).allowsUndefined,
                default: Validators.boolean({
                    description: "First option with default set to true is the default selection.",
                    defaultValue: false,
                }),
                label: Validators.string({
                    description: "Displayed label for the sort option. Currently not translated.",
                }).allowsUndefined,
                sort: Validators.arrayOf({
                    description: "Sort expression passed to the API.",
                    defaultValue: [],
                    items: Validators.objectOf({
                        description: "Sort expression passed to the API.",
                        values: Validators.object({
                            description: "Sort expression passed to the API.",
                        }).allowsUndefined,
                    }).custom((value, path) => {
                        if (Object.keys(value).length > 1) {
                            throw new ValidationError(path, `invalid amount of keys: ${value}`);
                        }
                        return value;
                    }),
                }),
            },
        }),
    }),
});
