import Validator from "~/config/validation/validators/Validator";
import {childPath} from "~/config/validation/validators/util";
import ValidationError from "~/config/validation/ValidationError";

export default class ShapeValidator extends Validator {
    constructor(description, shape) {
        if (shape === undefined) {
            throw new ValidationError("shape is undefined");
        }

        super(description, undefined);
        this.shape = shape;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {object}
     */
    validate(value, path = undefined, rootValue = value) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        }

        const result = {};

        for (const key in this.shape) {
            result[key] = this.shape[key].validate(value[key], childPath(path, key), rootValue);
        }

        for (const key in value) {
            if (this.shape[key] !== undefined) {
                continue;
            }

            console.warn(`Unknown configuration value at [${childPath(path, key)}]`);
            result[key] = value[key];
        }

        return result;
    }

    defaultValue() {
        throw new Error("TODO");
    }
}
