const PATH_SEPARATOR = ".";
const PATH_BRACKET_START = "[";
const PATH_BRACKET_END = "]";

export function toObjectPath(ourPath) {
    const resultPath = [];
    let insideBrackets = false;
    let startedAt = 0;

    function terminatePathItem(index) {
        if (index <= startedAt) {
            startedAt = index + 1;
            return;
        }

        resultPath.push(ourPath.substring(startedAt, index));
        startedAt = index + 1;
    }

    for (let i = 0; i < ourPath.length; i++) {
        const character = ourPath.charAt(i);

        if (!insideBrackets) {
            if (character === PATH_SEPARATOR) {
                terminatePathItem(i);
            } else if (character === PATH_BRACKET_START) {
                terminatePathItem(i);
                insideBrackets = true;
            }
        } else {
            if (character === PATH_BRACKET_END) {
                terminatePathItem(i);
                insideBrackets = false;
            }
        }
    }

    terminatePathItem(ourPath.length);
    return resultPath;
}