import Validators from "~/config/validation/validators/Validators";
import Auth0Definition from "~/config/definitions/features/Auth0Definition";
import MatchingPageDefinition from "~/config/definitions/features/MatchingPageDefinition";
import TextMiningDefinition from "~/config/definitions/features/TextMiningDefinition";
import EnabledDefinition from "~/config/definitions/shared/EnabledFalseDefinition";
import StandaloneMatchDetailsDefinition from "~/config/definitions/features/StandaloneMatchDetailsDefinition";

export default Validators.shape({
    description: "Configuration for various UI features",
    shape: {
        auth0: Auth0Definition,
        profileToJobs: MatchingPageDefinition,
        profileToCandidates: MatchingPageDefinition,
        jobToCandidates: MatchingPageDefinition,
        textMining: TextMiningDefinition,
        matchingStrategy: Validators.shape({
            description: "boolean to activate matching strategy.",
            shape: {
                enabled: EnabledDefinition,
            },
        }),
        monitoring: Validators.shape({
            description: "boolean to activate monitoring.",
            shape: {
                enabled: EnabledDefinition,
            },
        }),
        standaloneMatchDetails: StandaloneMatchDetailsDefinition,
    },
});
