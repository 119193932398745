import {Enum} from "~/enums/index";

export default new Enum({
    PROFILE_LANGUAGE: "PROFILE_LANGUAGE",
    PROFILE_LOCALE: "PROFILE_LOCALE",
    JOB_FUNCTION: "JOB_FUNCTION",
    HARD_SKILL: "HARD_SKILL",
    SOFT_SKILL: "SOFT_SKILL",
    LANGUAGE_KNOWLEDGE: "LANGUAGE_KNOWLEDGE",
    CERTIFICATION: "CERTIFICATION",
    DRIVING_LICENSE: "DRIVING_LICENSE",
    EDUCATION: "EDUCATION",
    SENIORITY: "SENIORITY",
    WORKING_HOURS: "WORKING_HOURS",
    CONTRACT_TYPE: "CONTRACT_TYPE",
    SHIFT_TYPE: "SHIFT_TYPE",
    DOMAIN: "DOMAIN",
    SECTOR: "SECTOR",
    WAGE: "WAGE",
    LOCATION: "LOCATION",
    JOB_TITLE: "JOB_TITLE",
    JOB_TEXT: "JOB_TEXT",
    CV_TEXT: "CV_TEXT",
    CV_LANGUAGE: "CV_LANGUAGE",
    WORK_EXPERIENCE: "WORK_EXPERIENCE",
    WORK_EXPERIENCE_TITLE: "WORK_EXPERIENCE_TITLE",
    WORK_EXPERIENCE_TEXT: "WORK_EXPERIENCE_TEXT",
    WORK_EXPERIENCE_COMPANY: "WORK_EXPERIENCE_COMPANY",
    WORK_EXPERIENCE_FROM: "WORK_EXPERIENCE_FROM",
    WORK_EXPERIENCE_TO: "WORK_EXPERIENCE_TO",
    EDUCATION_HISTORY: "EDUCATION_HISTORY",
    EDUCATION_HISTORY_FIELD: "EDUCATION_HISTORY_FIELD",
    EDUCATION_HISTORY_DEGREE: "EDUCATION_HISTORY_DEGREE",
    EDUCATION_HISTORY_INSTITUTE: "EDUCATION_HISTORY_INSTITUTE",
    EDUCATION_HISTORY_INFO: "EDUCATION_HISTORY_INFO",
    EDUCATION_HISTORY_FROM: "EDUCATION_HISTORY_FROM",
    EDUCATION_HISTORY_TO: "EDUCATION_HISTORY_TO",
    PREFERRED_JOB_TITLE: "PREFERRED_JOB_TITLE",
    PREFERRED_JOB_INFO: "PREFERRED_JOB_INFO",
    LOCATION_LEVEL_MAP: "LOCATION_LEVEL_MAP",
    CONCEPT_SUGGESTION_DATA: "CONCEPT_SUGGESTION_DATA",
    CUSTOM_CONCEPT: "CUSTOM_CONCEPT",
    CUSTOM_KEYWORD: "CUSTOM_KEYWORD",
    CUSTOM_LOCATION: "CUSTOM_LOCATION",
    CUSTOM_INTEGER: "CUSTOM_INTEGER",
    CUSTOM_INTEGER_RANGE: "CUSTOM_INTEGER_RANGE",
    CUSTOM_FLOAT: "CUSTOM_FLOAT",
    CUSTOM_FLOAT_RANGE: "CUSTOM_FLOAT_RANGE",
    CUSTOM_DOUBLE: "CUSTOM_DOUBLE",
    CUSTOM_DOUBLE_RANGE: "CUSTOM_DOUBLE_RANGE",
    CUSTOM_ES_QUERY: "CUSTOM_ES_QUERY",
    CUSTOM_TEXT: "CUSTOM_TEXT",
    CUSTOM_OBJECT: "CUSTOM_OBJECT",
    CUSTOM_NESTED: "CUSTOM_NESTED",
    UNMAPPED_CONCEPT: "UNMAPPED_CONCEPT",
    NONE: "NONE",
});
