import Validator from "~/config/validation/validators/Validator";

class CustomValidator extends Validator {
    constructor(baseValidator, validatorFunction) {
        super(baseValidator.description, baseValidator.defaultValue);
        this.baseValidator = baseValidator;
        this.validatorFunction = validatorFunction;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue = undefined) {
        const valueAfterBase = this.baseValidator.validate(value, path, rootValue);
        return this.validatorFunction(valueAfterBase, path, rootValue);
    }
}

Validator.prototype.custom = function (validatorFunction){
    return new CustomValidator(this, validatorFunction);
}
