import React from "react";
import TreePicker from "~/components/TreePicker";

const model = {
    data: [
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyU0QAK"
            },
            "Id": "a2p7Y000000XyU0QAK",
            "Name": "Milano",
            "Parent__c": "a2p7Y000000XyTqQAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyU5QAK"
            },
            "Id": "a2p7Y000000XyU5QAK",
            "Name": "Benelux",
            "Parent__c": "a2p7Y000000NjuYQAS"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUFQA0"
            },
            "Id": "a2p7Y000000XyUFQA0",
            "Name": "Bruxelles",
            "Parent__c": "a2p7Y000000XyU5QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyU6QAK"
            },
            "Id": "a2p7Y000000XyU6QAK",
            "Name": "Gheel",
            "Parent__c": "a2p7Y000000XyU5QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyTvQAK"
            },
            "Id": "a2p7Y000000XyTvQAK",
            "Name": "Torino",
            "Parent__c": "a2p7Y000000XyTqQAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUKQA0"
            },
            "Id": "a2p7Y000000XyUKQA0",
            "Name": "Wavre",
            "Parent__c": "a2p7Y000000XyU5QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUPQA0"
            },
            "Id": "a2p7Y000000XyUPQA0",
            "Name": "Gand",
            "Parent__c": "a2p7Y000000XyU5QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NkDaQAK"
            },
            "Id": "a2p7Y000000NkDaQAK",
            "Name": "Ile-de-France",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NjuYQAS"
            },
            "Id": "a2p7Y000000NjuYQAS",
            "Name": "Internationale",
            "Parent__c": null
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NkDBQA0"
            },
            "Id": "a2p7Y000000NkDBQA0",
            "Name": "Sophia-Antipolis",
            "Parent__c": "a2p7Y000000NkD1QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NkD6QAK"
            },
            "Id": "a2p7Y000000NkD6QAK",
            "Name": "Montpellier",
            "Parent__c": "a2p7Y000000NkD1QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NkD7QAK"
            },
            "Id": "a2p7Y000000NkD7QAK",
            "Name": "Aix-en-Provence",
            "Parent__c": "a2p7Y000000NkD1QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NkD1QAK"
            },
            "Id": "a2p7Y000000NkD1QAK",
            "Name": "Sud-Est",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000NkDVQA0"
            },
            "Id": "a2p7Y000000NkDVQA0",
            "Name": "France",
            "Parent__c": "a2p7Y000000NjuYQAS"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyTqQAK"
            },
            "Id": "a2p7Y000000XyTqQAK",
            "Name": "Italia",
            "Parent__c": "a2p7Y000000NjuYQAS"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUUQA0"
            },
            "Id": "a2p7Y000000XyUUQA0",
            "Name": "Rotterdam",
            "Parent__c": "a2p7Y000000XyU5QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUZQA0"
            },
            "Id": "a2p7Y000000XyUZQA0",
            "Name": "Suisse",
            "Parent__c": "a2p7Y000000NjuYQAS"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUeQAK"
            },
            "Id": "a2p7Y000000XyUeQAK",
            "Name": "Lausanne",
            "Parent__c": "a2p7Y000000XyUZQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000XyUAQA0"
            },
            "Id": "a2p7Y000000XyUAQA0",
            "Name": "Luxembourg",
            "Parent__c": "a2p7Y000000XyU5QAK"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUADQA4"
            },
            "Id": "a2p7Y000000KUADQA4",
            "Name": "Grenoble",
            "Parent__c": "a2p7Y000000KU9tQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUANQA4"
            },
            "Id": "a2p7Y000000KUANQA4",
            "Name": "Rouen",
            "Parent__c": "a2p7Y000000KU9zQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUAcQAO"
            },
            "Id": "a2p7Y000000KUAcQAO",
            "Name": "Metz",
            "Parent__c": "a2p7Y000000KUA4QAO"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUASQA4"
            },
            "Id": "a2p7Y000000KUASQA4",
            "Name": "Brest",
            "Parent__c": "a2p7Y000000KU9zQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUAhQAO"
            },
            "Id": "a2p7Y000000KUAhQAO",
            "Name": "Strasbourg",
            "Parent__c": "a2p7Y000000KUA4QAO"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUAXQA4"
            },
            "Id": "a2p7Y000000KUAXQA4",
            "Name": "Nantes",
            "Parent__c": "a2p7Y000000KU9zQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KU9tQAG"
            },
            "Id": "a2p7Y000000KU9tQAG",
            "Name": "RAA",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUAwQAO"
            },
            "Id": "a2p7Y000000KUAwQAO",
            "Name": "Lille",
            "Parent__c": "a2p7Y000000KUA1QAO"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KU9yQAG"
            },
            "Id": "a2p7Y000000KU9yQAG",
            "Name": "Lyon",
            "Parent__c": "a2p7Y000000KU9tQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KU9zQAG"
            },
            "Id": "a2p7Y000000KU9zQAG",
            "Name": "Ouest",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUA0QAO"
            },
            "Id": "a2p7Y000000KUA0QAO",
            "Name": "Sud-Ouest",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUA1QAO"
            },
            "Id": "a2p7Y000000KUA1QAO",
            "Name": "Nord",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUB1QAO"
            },
            "Id": "a2p7Y000000KUB1QAO",
            "Name": "Valenciennes",
            "Parent__c": "a2p7Y000000KUA1QAO"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUArQAO"
            },
            "Id": "a2p7Y000000KUArQAO",
            "Name": "Toulouse",
            "Parent__c": "a2p7Y000000KUA0QAO"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUAIQA4"
            },
            "Id": "a2p7Y000000KUAIQA4",
            "Name": "Rennes",
            "Parent__c": "a2p7Y000000KU9zQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUA8QAO"
            },
            "Id": "a2p7Y000000KUA8QAO",
            "Name": "Clermont-Ferrand",
            "Parent__c": "a2p7Y000000KU9tQAG"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUA4QAO"
            },
            "Id": "a2p7Y000000KUA4QAO",
            "Name": "Est",
            "Parent__c": "a2p7Y000000NkDVQA0"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUAmQAO"
            },
            "Id": "a2p7Y000000KUAmQAO",
            "Name": "Bordeaux",
            "Parent__c": "a2p7Y000000KUA0QAO"
        },
        {
            "attributes": {
                "type": "Mobilite__c",
                "url": "/services/data/v53.0/sobjects/Mobilite__c/a2p7Y000000KUA3QAO"
            },
            "Id": "a2p7Y000000KUA3QAO",
            "Name": "Annecy",
            "Parent__c": "a2p7Y000000KU9tQAG"
        }
    ],
    valueDefinition: {
        idPath: "Id",
        parentIdPath: "Parent__c",
        valuePath: "Name",
        labelPath: "Name",
    }
};

export default class Sandbox extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: [],
        }
    }

    render() {
        return (
            <div>
                <TreePicker model={model} value={this.state.value} onChange={this.handleChange} />
                <TreePicker.Input model={model} value={this.state.value} onChange={this.handleChange} />
            </div>
        );
    }

    handleChange = value => {
        const displayValue = [...value];
        displayValue.sort();
        console.log(displayValue);
        this.setState({value});
    };
}