import React from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import c from "classnames";
import {parsePhoneNumberFromString} from "libphonenumber-js/max";
import config from "~/config";
import {isString} from "~/util/misc";

export default class PhoneNumber extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onlyButtons: PropTypes.bool.isRequired,
        disableWhatsappButton: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        onlyButtons: false,
        disableWhatsappButton: false,
    };

    constructor(props) {
        super(props);

        this.parsePhoneNumber = memoizeOne(value => {
            return parsePhoneNumberFromString(value, config("ui.phoneNumberDefaultCountry"));
        });
    }

    render() {
        const {value, onlyButtons, disableWhatsappButton} = this.props;

        if (!isString(value)) {
            return this.renderInvalidPhoneNumber();
        }

        const phoneNumber = this.parsePhoneNumber(value);

        if (phoneNumber === undefined) {
            return this.renderInvalidPhoneNumber();
        }

        const formattedInternational = phoneNumber.formatInternational();
        const whatsAppDisabled = disableWhatsappButton || phoneNumber.getType() !== "MOBILE";

        return (
            <span>
                {!onlyButtons && value}
                <a
                    href={`${config("ui.phoneNumberProtocol")}:${phoneNumber.number}`}
                    title={formattedInternational}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-secondary ml-1"
                    onClick={stopPropagation}
                >
                    <i className="fas fa-phone" />
                </a>
                {!disableWhatsappButton && (
                    <a
                        href={`https://wa.me/${encodeURIComponent(
                            phoneNumber.number
                        )}`}
                        title={formattedInternational}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={c("btn btn-sm btn-secondary ml-1", {
                            disabled: whatsAppDisabled,
                        })}
                        onClick={stopPropagation}
                    >
                        <i className="fab fa-whatsapp" />
                    </a>
                )}
            </span>
        );
    }

    renderInvalidPhoneNumber() {
        const {disableWhatsappButton, value, onlyButtons} = this.props;

        return (
            <span>
                {!onlyButtons && value}
                <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-secondary ml-1 disabled"
                    onClick={stopPropagation}
                >
                    <i className="fas fa-phone" />
                </a>
                {!disableWhatsappButton && (
                    <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-sm btn-secondary ml-1 disabled"
                        onClick={stopPropagation}
                    >
                        <i className="fab fa-whatsapp" />
                    </a>
                )}
            </span>
        );
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}
