import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {RawIntlProvider} from "react-intl";
import Api from "~/api";
import configureStore from "~/store/configure";
import "~/components/QuietFormattedMessage";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {Auth0Provider, Auth0Context, auth0RedirectUri} from "~/util/auth0-provider";
import TEXT_MINING_INPUT from "!raw-loader!~/data/text-mining-input.txt"; // eslint-disable-line
import TEXT_MINING_API_RESULT from "~/data/text-mining-api-result";
import {updateResults} from "~/actions/text-mining";
import config from "~/config";
import {initializeLocalization} from "~/util/localization.js";
import ConfigurationError from "~/pages/ConfigurationError";
import {createMatchingContext} from "~/actions/matching";
import {updateLanguage} from "~/actions/ui";
import ProfileToCandidates from "~/pages/ProfileToCandidates";
import ProfileToJobs from "~/pages/ProfileToJobs";

export const STORE = configureStore();

export function bootstrap() {
    const intl = initializeLocalization();

    STORE.dispatch(
        createMatchingContext(
            ProfileToCandidates.MATCHING_CONTEXT_ID,
            ProfileToCandidates.getConfiguration()
        )
    );

    STORE.dispatch(
        createMatchingContext(
            ProfileToJobs.MATCHING_CONTEXT_ID,
            ProfileToJobs.getConfiguration()
        )
    );

    if (config("api.defaultLanguage", undefined) === undefined) {
        updateUiLanguage(STORE);
    } else {
        STORE.dispatch(updateLanguage(config("api.defaultLanguage", undefined)));
    }

    if (config("debug.enabled", false)) {
        STORE.dispatch(updateResults(TEXT_MINING_INPUT, TEXT_MINING_API_RESULT));
        window.Api = Api;
    }

    if (config("features.auth0.enabled", false)) {
        ReactDOM.render(
            <RawIntlProvider value={intl}>
                <Auth0Provider
                    audience={config("features.auth0.audience")}
                    scope={config("features.auth0.scopes")}
                    domain={config("features.auth0.domain")}
                    client_id={config("features.auth0.clientId")}
                    redirect_uri={auth0RedirectUri}
                    onRedirectCallback={onRedirectCallback}
                >
                    <Provider store={STORE}>
                        <Auth0Context.Consumer>
                            {auth0Context => <App auth0Context={auth0Context} />}
                        </Auth0Context.Consumer>
                    </Provider>
                </Auth0Provider>
            </RawIntlProvider>,
            document.getElementById("root")
        );
    } else {
        ReactDOM.render(
            <RawIntlProvider value={intl}>
                <Provider store={STORE}>
                    <App />
                </Provider>
            </RawIntlProvider>,
            document.getElementById("root")
        );
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();

    // A function that routes the user to the right place
    // after login
    function onRedirectCallback(appState) {
        window.history.replaceState(
            {},
            document.title,
            appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
        );
    }
}

export function displayConfigurationError(error) {
    ReactDOM.render(<ConfigurationError error={error} />, document.getElementById("root"));
}

function updateUiLanguage(store) {
    switch (config("ui.locale")) {
        case "nl":
            store.dispatch(updateLanguage("DUT"));
            break;

        case "fr":
            store.dispatch(updateLanguage("FRE"));
            break;

        case "it":
            store.dispatch(updateLanguage("ITA"));
            break;

        case "de":
            store.dispatch(updateLanguage("GER"));
            break;

        default:
        case "en":
            store.dispatch(updateLanguage("ENG"));
            break;
    }
}