import React from "react";
import PropTypes from "prop-types";
import objectPath from "object-path";
import {FormattedMessage} from "react-intl";
import Value from "./Value";
import baseStyles from "../styles.module.scss";

export default class ExtraPropertyRow extends React.PureComponent {
    static propTypes = {
        propertyPath: PropTypes.string.isRequired,
        matches: PropTypes.array,
    };

    render() {
        const {propertyPath} = this.props;

        return (
            <tr>
                <td className={baseStyles.stickyColumn}>
                    <FormattedMessage id={propertyPath} defaultMessage={propertyPath} />
                </td>
                {this.renderMatches()}
            </tr>
        );
    }

    renderMatches() {
        const {matches} = this.props;
        return matches.map((match, index) => (
            <td key={index} className={baseStyles.matchColumn}>
                {this.renderMatch(match)}
            </td>
        ));
    }

    renderMatch(match) {
        const {propertyPath} = this.props;
        return <Value value={objectPath.get(match.document, propertyPath)} />;
    }
}
