import Validator from "~/config/validation/validators/Validator";
import {betterTypeOf, childPath} from "~/config/validation/validators/util";
import ValidationError from "~/config/validation/ValidationError";
import {isPlainObject} from "~/util/misc";

export default class ObjectOfValidator extends Validator {
    constructor(description, valueValidator) {
        super(description, undefined);
        this.valueValidator = valueValidator;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (!isPlainObject(value)) {
            console.warn(`${this.description}`, value)
            throw new ValidationError(path, `Expected an object, but got ${betterTypeOf(value)}`);
        }else{
            const result = {};
            for (const key in value){
                result[key] = this.valueValidator.validate(value[key], childPath(path, key), rootValue);
                //console.warn(`Unknown configuration value with [${value[key]}][${key}]`);
            }
            return result;
        }
    }
}

Object.defineProperty(Validator.prototype, "objectOf", {
    get: function() {
        return new ObjectOfValidator(this)
    },
});

