import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Button from "react-bootstrap/Button";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";
import Select from "~/components/Select";
import {getSearchStrategies} from "~/util/search-strategies";

export default class MatchBlock extends React.PureComponent {
    static propTypes = {
        indexConfig: PropTypes.object.isRequired,
        searchStrategyName: PropTypes.string,
        className: PropTypes.string,
        language: PropTypes.string.isRequired,
        matchRequestHasChanged: PropTypes.bool.isRequired,
        matchActionLabel: PropTypes.string.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onSearchStrategyNameChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            className,
            language,
            matchRequestHasChanged,
            matchActionLabel,
            onLanguageChange,
            onMatch,
        } = this.props;

        return (
            <div className={c(className, styles.main)} onClick={stopPropagation}>
                {this.renderSearchStrategySelect()}
                <Button
                    variant={matchRequestHasChanged ? "success" : "secondary"}
                    size="sm"
                    onClick={onMatch}
                    className={styles.matchButton}
                >
                    <FormattedMessage id={matchActionLabel} />
                </Button>
            </div>
        );
    }

    renderSearchStrategySelect() {
        const {indexConfig, searchStrategyName} = this.props;

        const searchStrategies = getSearchStrategies(indexConfig);

        if (searchStrategies.length < 2) {
            return null;
        }

        const options = searchStrategies.map(searchStrategy => ({
            value: searchStrategy.name,
            label: searchStrategy.label,
        }));

        return (
            <Select
                value={searchStrategyName}
                onChange={this.handleSearchStrategyNameChange}
                options={options}
                variant="small"
                className={styles.leftHandSelect}
            />
        );
    }

    handleSearchStrategyNameChange = option => {
        const {onSearchStrategyNameChange} = this.props;
        onSearchStrategyNameChange(option.value);
    };
}

function stopPropagation(e) {
    e.stopPropagation();
}
