import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class AnyValidator extends Validator {
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (value !== undefined && value !== null) {
            return value;
        } else {
            throw new ValidationError(path, `Expected any value, but got ${betterTypeOf(value)}`);
        }
    }
}
