import React from "react";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import {isString} from "~/util/misc";
import {I18N} from "~/util/localization";

export default class TranslatableLabel extends React.PureComponent {
    static propTypes = {
        value: TranslatableLabelPropType.isRequired,
    };

    render() {
        const {value} = this.props;
        return getFinalValue(value);
    }
}

function getFinalValue(label, interpolationValues) {
    if (label === undefined) {
        return undefined;
    } else if (isString(label)) {
        return label;
    } else if (label.id !== undefined) {
        return I18N.intl.formatMessage(label, interpolationValues);
    } else {
        return findBestLocale(label, I18N.intl.locale);
    }
}

function findBestLocale(label, locale) {
    if (label[locale] !== undefined) {
        return label[locale];
    } else if (label["en"] !== undefined) {
        return label["en"];
    } else {
        return Object.values(label)[0] || null;
    }
}

TranslatableLabel.getFinalValue = getFinalValue;