import Api from "~/api";
import config from "~/config";

export const START_MATCH = "standalone-match-details/START_MATCH";
export const MATCH_SUCCESS = "standalone-match-details/MATCH_SUCCESS";
export const MATCH_FAILURE = "standalone-match-details/MATCH_FAILURE";

export function setSourceAndTarget(sourceIndex, sourceId, targetIndex, targetId) {
    return (dispatch, getState) => {
        const globalState = getState();

        dispatch(startMatch(sourceIndex, sourceId, targetIndex, targetId));

        const apiVersion = config("api.version");
        let apiCall;

        if (apiVersion.gte("2.2.0")) {
            apiCall = Api.matchDetailsForJobToCandidateV2.bind(Api);
        } else {
            apiCall = Api.matchDetailsForJobToCandidate.bind(Api);
        }

        const matchingStrategyName = config(
            `features.standaloneMatchDetails.indexSettings.${sourceIndex}.matchingStrategyName`,
            undefined
        );

        return apiCall(
            sourceIndex,
            sourceId,
            targetIndex,
            targetId,
            globalState.ui.language,
            matchingStrategyName
        )
            .then(matches => {
                if (matches.length > 0) {
                    dispatch(matchSuccess(matches[0]));
                } else {
                    dispatch(matchFailure("No result"));
                }
            })
            .catch(error => {
                dispatch(matchFailure(error));
            });
    };
}

function startMatch(sourceIndex, sourceId, targetIndex, targetId) {
    return {
        type: START_MATCH,
        sourceIndex,
        sourceId,
        targetIndex,
        targetId,
    };
}

function matchSuccess(result) {
    return {
        type: MATCH_SUCCESS,
        result,
    };
}

function matchFailure(error) {
    return {
        type: MATCH_FAILURE,
        error,
    };
}
