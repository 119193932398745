export function getSearchStrategies(config) {
    // Use actual search strategies if available
    if (config.searchStrategies && config.searchStrategies.length > 0) {
        return config.searchStrategies;
    }

    // Fall back on matchingStrategyNames of the first index otherwise, replicating previous
    // behaviour
    if (
        config.indices &&
        config.indices[0] &&
        config.indices[0].matchingStrategyNames &&
        config.indices[0].matchingStrategyNames.length > 0
    ) {
        return config.indices[0].matchingStrategyNames.map(matchingStrategyName => {
            return {
                name: matchingStrategyName,
                label: {id: `matchingStrategy.${matchingStrategyName}`},
                indexConfiguration: {
                    [config.indices[0].name]: {
                        matchingStrategyName,
                    },
                },
            };
        });
    }

    // Or just fall back on using defaults everywhere
    return [
        {
            name: "standard",
            label: "Standard",
            indexConfiguration: {},
        },
    ];
}

export function getSearchStrategy(config, searchStrategyName) {
    if (searchStrategyName === undefined) {
        return undefined;
    }

    for (const searchStrategy of getSearchStrategies(config)) {
        if (searchStrategy.name === searchStrategyName) {
            return searchStrategy;
        }
    }

    return undefined;
}

export function getSearchStrategyIndexConfiguration(config, searchStrategyName, indexName) {
    const searchStrategy = getSearchStrategy(config, searchStrategyName);

    if (
        searchStrategy === undefined ||
        indexName === undefined ||
        searchStrategy.indexConfiguration === undefined
    ) {
        return {};
    } else {
        const indexConfiguration = searchStrategy.indexConfiguration[indexName];
        return indexConfiguration !== undefined ? indexConfiguration : {};
    }
}
