import uuid from "uuid/v4";
import abortableCall from "./abortable-call";
import urlJoin from "url-join";
import config from "~/config";
import {sharedAuth0Client} from "~/util/auth0-provider";

let internalToken = null;

export function setInternalToken(token) {
    internalToken = token;
}

export default class Client {
    activePromises = {};

    call(method, endpoint, queryParameters = {}, body = null, headers = {}, options = {}) {
        const url = urlJoin(config("api.url"), endpoint);
        const finalHeaders = {...headers};

        if (config("api.additionalHeaders", undefined) !== undefined) {
            const additionalHeaders = config("api.additionalHeaders");

            for (const name in additionalHeaders) {
                finalHeaders[name] = additionalHeaders[name];
            }
        }

        // We need to handle the token with a promise because of Auth0. If we make call() async and await the token here,
        // we lose our ability to maintain the abort() function on the promise. It would be better to rewrite abortableCall()
        // to return both a promise and an abort call, but this means rewriting a lot of client code and there is no time
        // right now.
        let tokenPromise;

        if (internalToken !== null) {
            tokenPromise = Promise.resolve(internalToken);
        } else if (config("features.auth0.enabled", false) && sharedAuth0Client) {
            tokenPromise = sharedAuth0Client.getTokenSilently();
        } else {
            tokenPromise = Promise.resolve();
        }

        const promise = abortableCall(
            method,
            url,
            queryParameters,
            body,
            finalHeaders,
            tokenPromise,
            options
        );

        this.addActivePromise(promise);
        return promise;
    }

    get(endpoint, queryParameters = {}, headers = {}, options) {
        return this.call("get", endpoint, queryParameters, undefined, headers, options);
    }

    post(endpoint, body = null, queryParameters = {}, headers = {}, options) {
        return this.call("post", endpoint, queryParameters, body, headers, options);
    }

    put(endpoint, body = null, headers = {}, options) {
        return this.call("put", endpoint, undefined, body, headers, options);
    }

    delete(endpoint, headers = {}, options) {
        return this.call("delete", endpoint, undefined, undefined, headers, options);
    }

    addActivePromise(promise) {
        const id = this.uniqueId();
        this.activePromises[id] = promise;

        promise
            .then(() => {
                delete this.activePromises[id];
            })
            .catch(() => {
                delete this.activePromises[id];
            });
    }

    abortAllCalls() {
        const abortedPromises = [];

        for (const id in this.activePromises) {
            if (this.activePromises.hasOwnProperty(id)) {
                const promise = this.activePromises[id];
                promise.abort();
                abortedPromises.push(promise);
            }
        }

        return Promise.all(
            // Transform rejections into resolves so that Promise.all() waits for all
            // abortions to complete instead of rejecting on the first rejection.
            // This way calling code can properly wait for all abortions to finish.
            abortedPromises.map(promise => promise.catch(error => error))
        );
    }

    uniqueId() {
        for (;;) {
            const id = uuid();
            if (this.activePromises[id] === undefined) return id;
        }
    }
}
