import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Table from "react-bootstrap/Table";
import styles from "./styles.module.scss";
import config from "~/config";
import ProgressBar from "~/components/ProgressBar";
import {FormattedMessage} from "react-intl";
import {getTranslatedValue, RANGE_UNIT_TRANSLATIONS} from "~/util/ui-translations";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";
import {
    JOB_FUNCTIONS_GREEN,
    getAspectValueMatchedTo,
    MatchedTo,
    HARD_SKILLS_GREEN,
    getMatchedToMessageId,
    GENERAL_GREEN,
} from "~/util/match";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {I18N} from "~/util/localization";
import ScoreType from "~/enums/ScoreType";

export default class MatchQualityTable extends React.PureComponent {
    static propTypes = {
        scoreType: ScoreType.propType,
        aspectDetails: PropTypes.array.isRequired,
        details: PropTypes.object.isRequired,
        otherAspects: PropTypes.object.isRequired,
        forwardLabel: PropTypes.node.isRequired,
        forwardSubLabel: PropTypes.node,
        reverseLabel: PropTypes.node.isRequired,
        hideForwardColumn: PropTypes.bool.isRequired,
        onForwardLabelClick: PropTypes.func,
        className: PropTypes.string,
        resultType: PropTypes.string.isRequired,
    };

    static defaultProps = {
        otherAspects: {},
    };

    render() {
        const {
            forwardLabel,
            forwardSubLabel,
            reverseLabel,
            hideForwardColumn,
            onForwardLabelClick,
            className,
        } = this.props;

        return (
            <Table size="sm" className={c(className, styles.table)}>
                <thead>
                    <tr className={styles.noTopBorder}>
                        <th className={styles.aspectColumn}>
                            <FormattedMessage id="matchQuality.aspectColumn" />
                        </th>
                        {/*<th>*/}
                        {/*    <FormattedMessage id="matchQuality.matchProfile" />*/}
                        {/*</th>*/}

                        {!hideForwardColumn && (
                            <th
                                className={c({[styles.clickable]: onForwardLabelClick})}
                                onClick={onForwardLabelClick}
                            >
                                {forwardSubLabel && (
                                    <div className={styles.normalWeight}>{forwardSubLabel}</div>
                                )}
                                <div className={styles.ellipsis}>{forwardLabel}</div>
                            </th>
                        )}
                        <th>
                            <div className={styles.ellipsis}>{reverseLabel}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderDetails()}</tbody>
            </Table>
        );
    }

    renderDetails() {
        return config("ui.aspectOrder")
            .filter(aspect => EnabledAspectsBlackWhiteList.allows(aspect))
            .map(aspect => this.renderDetailsForAspect(aspect));
    }

    renderDetailsForAspect(aspectName) {
        const {scoreType, aspectDetails, details, otherAspects} = this.props;
        const displayedScoreType = getDisplayedScoreType(scoreType);
        const itemsByScoreType = removeInvalidAndSplitByScoreType(details[aspectName]);
        itemsByScoreType.FORWARD = itemsByScoreType.FORWARD || [];
        itemsByScoreType.REVERSE = itemsByScoreType.REVERSE || [];

        if (itemsByScoreType.REVERSE.length === 0) {
            itemsByScoreType.REVERSE = convertAspectToItems(otherAspects[aspectName]);
        }

        const aspectData = aspectDetails.find(
            data => data.aspectName === aspectName && data.scoreType === displayedScoreType
        );

        const rowCount = Math.max(
            itemsByScoreType.FORWARD.length,
            itemsByScoreType.REVERSE.length,
            1
        );
        const rows = [];

        for (let i = 0; i < rowCount; i++) {
            rows.push(
                this.renderItemRow(
                    i,
                    itemsByScoreType.FORWARD[i],
                    itemsByScoreType.REVERSE[i],
                    aspectName,
                    aspectData,
                    displayedScoreType,
                    i === 0
                )
            );
        }

        return rows;
    }

    renderItemRow(
        index,
        forwardItem,
        reverseItem,
        aspectName,
        aspectData,
        displayedScoreType,
        firstRow
    ) {
        const {hideForwardColumn} = this.props;

        return (
            <tr key={`${aspectName}:${index}`}>
                <td className={c(styles.detailsItemRow, {[styles.noTopBorder]: !firstRow})}>
                    {firstRow && this.renderAspectLabel(aspectName, aspectData)}
                </td>
                {!hideForwardColumn && (
                    <td className={c({[styles.noTopBorder]: !firstRow})}>
                        {forwardItem &&
                            this.renderItem(aspectName, forwardItem, displayedScoreType)}
                        {!forwardItem && firstRow && this.renderNotAvailable()}
                    </td>
                )}
                <td className={c({[styles.noTopBorder]: !firstRow})}>
                    {reverseItem && this.renderItem(aspectName, reverseItem, displayedScoreType)}
                    {!reverseItem && firstRow && this.renderNotAvailable()}
                </td>
            </tr>
        );
    }

    renderAspectLabel(aspectName, aspectData) {
        return (
            <React.Fragment>
                {aspectData && (
                    <OverlayTrigger
                        placement="right"
                        delay={{show: 250, hide: 400}}
                        overlay={props => (
                            <Tooltip {...props} show={props.show.toString()}>
                                <div className={styles.detailsItemRow}>
                                    <ProgressBar
                                        className={styles.detailsProgress}
                                        variant="success"
                                        now={aspectData.contributingScore}
                                        min={0}
                                        max={1}
                                    />
                                    <FormattedMessage id="score.contributionToTotalScore" />
                                </div>
                            </Tooltip>
                        )}
                    >
                        <ProgressBar
                            className={styles.detailsProgress}
                            variant="success"
                            now={aspectData.individualScore}
                            min={0}
                            max={1}
                        />
                    </OverlayTrigger>
                )}
                {<FormattedMessage id={`aspect.${aspectName}`} /> || aspectName}
            </React.Fragment>
        );
    }

    renderNotAvailable() {
        return (
            <div className={c(styles.detailsItemRow, styles.notAvailable)}>
                <OverlayTrigger
                    placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={props => (
                        <Tooltip {...props} show={props.show.toString()}>
                            <FormattedMessage id="matchQuality.notAvailable" />
                        </Tooltip>
                    )}
                >
                    <i className="far fa-question-circle" />
                </OverlayTrigger>
                <div className={styles.detailsItemLabel}>
                    <FormattedMessage id="matchQuality.notAvailable" />
                </div>
            </div>
        );
    }

    renderItem(aspectName, item, displayedScoreType) {
        const staticOrUnmatchedReverse =
            item.isStatic ||
            (!item.isStatic && !item.found && item.scoreType !== displayedScoreType);

        let classes = {
            "fa-check-circle": !item.isStatic && item.found,
            "fa-times-circle":
                !item.isStatic && !item.found && item.scoreType === displayedScoreType,
            "fa-circle": staticOrUnmatchedReverse,
        };

        let matchedTo;

        if (aspectName === "jobFunctions") {
            matchedTo = getAspectValueMatchedTo(item, aspectName);
            classes.unsure =
                !item.isStatic && ![...JOB_FUNCTIONS_GREEN, MatchedTo.NOTHING].includes(matchedTo);
        } else if (aspectName === "hardSkills") {
            matchedTo = getAspectValueMatchedTo(item, aspectName);
            classes.unsure =
                !item.isStatic && ![...HARD_SKILLS_GREEN, MatchedTo.NOTHING].includes(matchedTo);
        } else if (["softSkills", "languages"].includes(aspectName)) {
            matchedTo = getAspectValueMatchedTo(item, aspectName);
            classes.unsure =
                !item.isStatic && ![...GENERAL_GREEN, MatchedTo.NOTHING].includes(matchedTo);
        }

        return (
            <div key={item.label} className={styles.detailsItemRow}>
                {this.renderItemIcon(classes, aspectName, matchedTo, item, displayedScoreType)}
                {!staticOrUnmatchedReverse && item.scoreType === displayedScoreType && (
                    <ProgressBar
                        className={styles.detailsProgress}
                        variant="success"
                        now={item.score}
                        min={0}
                        max={1}
                    />
                )}
                <div className={styles.detailsItemLabel}>
                    {TRANSLATED_ASPECTS.includes(aspectName) ? (
                        <FormattedMessage
                            id={`${aspectName}.${item.label}`}
                            defaultMessage={item.label}
                        />
                    ) : (
                        getTranslatedValue(aspectName, item.label)
                    )}
                </div>
            </div>
        );
    }

    renderItemIcon(classes, aspect, matchedTo, item, displayedScoreType) {
        if (item.scoreType !== displayedScoreType) {
            return this.renderIcon(matchedTo, aspect, item, classes);
        } else if (
            !matchedTo ||
            matchedTo === MatchedTo.NOTHING ||
            matchedTo === MatchedTo.UNKNOWN
        ) {
            if (item.scoreType === displayedScoreType) {
                return this.renderIcon(matchedTo, aspect, item, classes);
            } else {
                return this.renderIcon(matchedTo, aspect, item, classes);
            }
        } else if (item.fields) {
            return this.renderIcon(matchedTo, aspect, item, classes);
        } else {
            return <i className={c("far", classes)} />;
        }
    }

    renderIcon(matchedTo, aspect, item, classes) {
        const {resultType} = this.props;

        const forward = item.scoreType === ScoreType.FORWARD;
        const noMatch = !item.found;
        const unsure = classes.unsure && forward;
        const messageId = getMatchedToMessageId(
            matchedTo,
            aspect,
            noMatch,
            unsure,
            forward,
            resultType,
            item.fields
        );

        const profileType = translateProfileTypeName(resultType.toLowerCase());
        if (aspect !== undefined) {
            const aspects = translateAspectName(aspect);

            if (item.fields !== undefined) {
                let uniqFields = [...new Set(item.fields)];
                const fieldsDescription = translateFieldNames(uniqFields);

                return (
                    <OverlayTrigger
                        placement="right"
                        delay={{show: 250, hide: 400}}
                        overlay={props => (
                            <Tooltip {...props} show={props.show.toString()}>
                                <div style={{whiteSpace: "pre-wrap"}}>
                                    <FormattedMessage
                                        id={messageId}
                                        values={{
                                            fields: fieldsDescription,
                                            aspectType: aspects,
                                            job: profileType,
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    >
                        <i className={c("far", classes)} />
                    </OverlayTrigger>
                );
            } else {
                return (
                    <OverlayTrigger
                        placement="right"
                        delay={{show: 250, hide: 400}}
                        overlay={props => (
                            <Tooltip {...props} show={props.show.toString()}>
                                <div style={{whiteSpace: "pre-wrap"}}>
                                    <FormattedMessage
                                        id={messageId}
                                        values={{aspectType: aspects, job: profileType}}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    >
                        <i className={c("far", classes)} />
                    </OverlayTrigger>
                );
            }
        } else {
            return (
                <OverlayTrigger
                    placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={props => (
                        <Tooltip {...props} show={props.show.toString()}>
                            <div style={{whiteSpace: "pre-wrap"}}>
                                <FormattedMessage id={messageId} values={{job: profileType}} />
                            </div>
                        </Tooltip>
                    )}
                >
                    <i className={c("far", classes)} />
                </OverlayTrigger>
            );
        }
    }
}

function translateFieldNames(fieldNames) {
    if (fieldNames === undefined) {
        return "null";
    }
    return fieldNames.map(translateFieldName).join(", ");
}

function translateFieldName(fieldName) {
    if (!!I18N.intl.messages[`aspect.${fieldName}`]) {
        return I18N.intl.formatMessage({id: `aspect.${fieldName}`});
    } else if (!!I18N.intl.messages[`others.${fieldName}`]) {
        return I18N.intl.formatMessage({id: `others.${fieldName}`});
    } else {
        return fieldName;
    }
}

export function translateAspectName(fieldName) {
    if (!!I18N.intl.messages[`aspect.${fieldName}.singular`]) {
        return I18N.intl.formatMessage({id: `aspect.${fieldName}.singular`});
    } else {
        return fieldName;
    }
}

export function translateProfileTypeName(profileType) {
    if (!!I18N.intl.messages[`profileType.${profileType}`]) {
        return I18N.intl.formatMessage({id: `profileType.${profileType}`});
    } else {
        return profileType.toString();
    }
}

const TRANSLATED_ASPECTS = ["contractTypes", "workingHours"];

function removeInvalidAndSplitByScoreType(items) {
    items = items || [];
    const result = {};

    for (const item of items) {
        if (!item.label) {
            continue;
        }

        if (result[item.scoreType] === undefined) {
            result[item.scoreType] = [];
        }

        result[item.scoreType].push(item);
    }

    return result;
}

function convertAspectToItems(aspect) {
    if (!aspect) return [];

    switch (aspect.type) {
        case "concepts":
        case "keywords":
            return aspect.value.map(value => ({
                isStatic: true,
                label: value.label,
            }));

        case "locations":
            return aspect.value.map(value => ({
                isStatic: true,
                label: `${value.label} ≤ ${value.range}${RANGE_UNIT_TRANSLATIONS[value.rangeUnit] ||
                    ""}`,
            }));

        case "range":
            return aspect.value
                ? [
                      {
                          isStatic: true,
                          label: `${aspect.value.from} – ${aspect.value.to}`,
                      },
                  ]
                : [];
        case "float":
        case "integer":
            return aspect.value
                ? [
                      {
                          isStatic: true,
                          label: `${aspect.value.value}`,
                      },
                  ]
                : [];

        default:
            return [];
    }
}

function getDisplayedScoreType(scoreType) {
    switch (scoreType) {
        case ScoreType.REVERSE:
        case ScoreType.MUTUAL_REVERSE_SCORE:
            return ScoreType.REVERSE;

        default:
            return ScoreType.FORWARD;
    }
}
