import languages from "@cospired/i18n-iso-languages";
import {isString} from "~/util/misc";
import config from "~/config";
import {I18N} from "~/util/localization";

export const RANGE_UNIT_TRANSLATIONS = {
    KILOMETER: "km",
    METER: "m",
    MILE: "mi",
};

export function messageExists(intl, id) {
    return !!intl.messages[id];
}

export function formatMessageMaybe(intl, settings, parameters, defaultResult) {
    const exists = messageExists(intl, settings.id);
    return exists ? intl.formatMessage(settings, parameters) : defaultResult;
}

export function getTranslatedValue(propertyPath, value) {
    if (!isString(value)) {
        return value;
    }

    const valueTranslations = config("i18n.valueTranslations");

    if (valueTranslations[propertyPath] === undefined) {
        // TODO: Temporary fix for custom properties, whose keyword property is "<aspectName>.key",
        //       but translated values are at "<aspectName>".
        if (propertyPath.endsWith(".key")) {
            return getTranslatedValue(propertyPath.substring(0, propertyPath.length - 4), value);
        } else {
            return value;
        }
    }

    if (valueTranslations[propertyPath][value] === undefined) {
        return value;
    }

    const language = I18N.intl.locale;

    let translation = valueTranslations[propertyPath][value][language];
    if (translation !== undefined) return translation;

    translation = valueTranslations[propertyPath][value][languages.alpha2ToAlpha3B(language).toUpperCase()];
    if (translation !== undefined) return translation;

    return value;
}
