import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import ListGroup from "react-bootstrap/ListGroup";
import config from "~/config";
import NumberInput from "~/components/NumberInput";
import RangeUnitSelect from "~/components/RangeUnitSelect";
import styles from "./styles.module.scss";
import Foco from "react-foco";
import {I18N} from "~/util/localization";
import Checkbox from "~/components/Checkbox";
import Weight from "~/components/Weight";

export default class Location extends React.PureComponent {
    static propTypes = {
        value: PropTypes.shape({
            id: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            range: PropTypes.number,
            rangeUnit: PropTypes.oneOf(config("enums.rangeUnit")).isRequired,
        }).isRequired,
        allowMultipleLocations: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
    };

    static defaultProps = {
        placeholder: "",
    };

    constructor(props) {
        super(props);

        this.labelInputRef = React.createRef();
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    focus() {
        if (this.labelInputRef.current) {
            this.labelInputRef.current.focus();
        }
    }

    render() {
        const {value, allowMultipleLocations} = this.props;

        return (
            <ListGroup.Item className={c(styles.listItem, styles.main)}>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    className={styles.foco}
                    component="div"
                >
                    {allowMultipleLocations && (
                        <Checkbox
                            checked={value.required}
                            color="purple"
                            onChange={this.handleRequiredChange}
                        />
                    )}
                    {this.renderValue()}
                    {this.renderRemoveButton()}
                    {allowMultipleLocations && (
                        <Weight
                            value={value.weight}
                            isEditing={true}
                            className="ml-auto"
                            color="purple"
                            onChange={this.handleWeightChange}
                        />
                    )}
                </Foco>
            </ListGroup.Item>
        );
    }

    renderValue() {
        const {value} = this.props;

        return (
            <React.Fragment>
                <input
                    value={value.label}
                    placeholder={I18N.intl.formatMessage({id: "placeholder.location"})}
                    className={styles.locationInput}
                    onChange={this.handleLabelChange}
                    ref={this.labelInputRef}
                />
                <span>{" ≤ "}</span>
                <NumberInput
                    value={value.range}
                    placeholder={I18N.intl.formatMessage({id: "placeholder.distance"})}
                    className={styles.distanceInput}
                    onChange={this.handleRangeChange}
                />
                <RangeUnitSelect
                    value={value.rangeUnit}
                    className={styles.unitSelect}
                    onChange={this.handleRangeUnitChange}
                />
            </React.Fragment>
        );
    }

    renderRemoveButton() {
        const {allowMultipleLocations} = this.props;

        if (!allowMultipleLocations) {
            return null;
        }

        return (
            <div
                className={c(styles.removeButton, {hidden: !this.props.onDelete})}
                onClick={this.handleRemoveClick}
            >
                <i className="fas fa-times" />
            </div>
        );
    }

    handleLabelChange = e => {
        const {value, onChange, onDelete} = this.props;
        const nextLabel = e.target.value;

        if (onDelete && nextLabel === "") {
            onDelete(value.id);
        } else {
            onChange(value.id, {...value, label: nextLabel});
        }
    };

    handleRangeChange = range => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, range});
    };

    handleRangeUnitChange = rangeUnit => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, rangeUnit});
    };

    handleWeightChange = nextWeight => {
        const {value, onChange} = this.props;

        onChange(value.id, {
            ...value,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {value, onChange} = this.props;

        onChange(value.id, {
            ...value,
            required: nextRequired,
        });
    };

    handleRemoveClick = () => {
        const {value, onDelete} = this.props;
        if (onDelete) onDelete(value.id);
    };

    normalizeValue = () => {
        const {value, onDelete} = this.props;

        if (onDelete && value.label === "") {
            onDelete(value.id);
        } else if (value.label !== "" && value.range === undefined) {
            this.handleRangeChange(config("ui.defaultRange"));
        }
    };
}
