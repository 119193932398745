import React from "react";
import c from "classnames";
import PropTypes from "prop-types";
import {NodePropType} from "./prop-types";
import styles from "./styles.module.scss";
import Checkbox from "~/components/Checkbox";
import {createNodeSelectors} from "./selectors";

export default class TreeNode extends React.PureComponent {
    static propTypes = {
        node: NodePropType.isRequired,
        value: PropTypes.instanceOf(Map).isRequired,
        expanded: PropTypes.instanceOf(Map).isRequired,
        onChange: PropTypes.func.isRequired,
        onExpandedChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.selectors = createNodeSelectors(this);
    }

    render() {
        const {node} = this.props;
        const checked = this.selectors.checkedSelector();

        return (
            <div>
                <div className={styles.label}>
                    {this.renderExpansionToggle()}
                    <Checkbox label={node.label} onChange={this.handleChange} checked={checked} />
                </div>
                {this.renderChildren()}
            </div>
        );
    }

    renderExpansionToggle() {
        const {node, expanded} = this.props;
        const isExpanded = expanded.has(node.value);

        if (node.children.length > 0) {
            return (
                <div className={c(styles.expansionToggle, styles.expansionToggleActive)} onClick={this.handleToggleExpanded}>
                    <i className={c("fas", {"fa-caret-right": !isExpanded, "fa-caret-down": isExpanded})}/>
                </div>
            );
        } else {
            return <div className={styles.expansionToggle}/>;
        }
    }

    renderChildren() {
        const {node, expanded} = this.props;
        const isExpanded = expanded.has(node.value);
        const hidden = node.children.length === 0 || !isExpanded;
        return <div className={c(styles.children, {[styles.hidden]: hidden})}>{node.children.map(this.renderNode)}</div>;
    }

    renderNode = node => {
        const {value, expanded, onChange, onExpandedChange} = this.props;

        return (
            <TreeNode
                key={node.id}
                node={node}
                value={value}
                expanded={expanded}
                onChange={onChange}
                onExpandedChange={onExpandedChange}
            />
        );
    };

    handleChange = checked => {
        const {node, value, onChange} = this.props;

        if (checked) {
            onChange(checkNode(value, node));
        } else {
            onChange(uncheckNode(value, node));
        }
    };

    handleToggleExpanded = () => {
        const {node, expanded, onExpandedChange} = this.props;
        const isExpanded = expanded.has(node.value);
        const nextExpanded = new Map(expanded);

        if (isExpanded) {
            nextExpanded.delete(node.value);
        } else {
            nextExpanded.set(node.value, true);
        }

        onExpandedChange(nextExpanded);
    }
}

function checkNode(value, node) {
    const nextValue = new Map(value);

    nextValue.set(node.value, true);

    for (const child of node.recursiveChildIterator()) {
        nextValue.set(child.value, true);
    }

    outer:
    for (const parent of node.recursiveParentIterator()) {
        for (const child of parent.children) {
            if (!nextValue.has(child.value)) {
                break outer;
            }
        }

        nextValue.set(parent.value, true);
    }

    return nextValue;
}

function uncheckNode(value, node) {
    const nextValue = new Map(value);

    nextValue.delete(node.value);

    for (const child of node.recursiveChildIterator()) {
        nextValue.delete(child.value);
    }

    for (const parent of node.recursiveParentIterator()) {
        nextValue.delete(parent.value);
    }

    return nextValue;
}
