import objectPath from "object-path";
import {isFunction, isFunctionAndArguments, isString} from "~/util/misc";
import {getTranslatedValue} from "~/util/ui-translations";

export const MatchedTo = {
    UNKNOWN: "UNKNOWN",
    NOTHING: "NOTHING",
    ASPECT: "ASPECT",
    JOB_TITLE: "JOB_TITLE",
    JOB_TEXT: "JOB_TEXT",
    CV_TEXT: "CV_TEXT",
    WORK_EXPERIENCES: "WORK_EXPERIENCES",
    EDUCATIONS: "EDUCATIONS",
    OTHER_ASPECT_JOB_FUNCTIONS: "OTHER_ASPECT_JOB_FUNCTIONS",
    OTHER_ASPECT_HARD_SKILLS: "OTHER_ASPECT_HARD_SKILLS",
    FIELDS: "FIELDS",
    CONCEPT_ID: "CONCEPT_ID",
};

export const JOB_FUNCTIONS_GREEN = [
    MatchedTo.CONCEPT_ID,
    MatchedTo.ASPECT,
    MatchedTo.JOB_TITLE,
    MatchedTo.OTHER_ASPECT_HARD_SKILLS,
];
export const HARD_SKILLS_GREEN = [
    MatchedTo.CONCEPT_ID,
    MatchedTo.ASPECT,
    MatchedTo.OTHER_ASPECT_JOB_FUNCTIONS,
];
export const GENERAL_GREEN = [MatchedTo.CONCEPT_ID, MatchedTo.ASPECT];

export function isGreenMatch(match, matchRequest) {
    const jobFunctionsMatchedTo = getAspectMatchedTo(match, "jobFunctions");

    if (!JOB_FUNCTIONS_GREEN.includes(jobFunctionsMatchedTo)) {
        return false;
    }

    if (
        !match.details ||
        !match.details.hardSkills ||
        !Array.isArray(match.details.hardSkills) ||
        !matchRequest ||
        !matchRequest.matchProfile ||
        !matchRequest.matchProfile.aspects ||
        !matchRequest.matchProfile.aspects.hardSkills
    ) {
        return true;
    }

    for (const hardSkill of matchRequest.matchProfile.aspects.hardSkills.value) {
        if (!hardSkill.required) {
            continue;
        }

        const value = match.details.hardSkills.find(v => v.label === hardSkill.label);

        if (
            !value ||
            !value.found ||
            !HARD_SKILLS_GREEN.includes(getAspectValueMatchedTo(value, "hardSkills"))
        ) {
            return false;
        }
    }

    return true;
}

export function getAspectMatchedTo(match, aspect) {
    if (!match.details) {
        return MatchedTo.NOTHING;
    }

    if (!match.details[aspect]) {
        return MatchedTo.NOTHING;
    }

    if (!Array.isArray(match.details[aspect])) {
        return MatchedTo.NOTHING;
    }

    let matched = false;
    let result = undefined;

    for (const value of match.details[aspect]) {
        if (value.scoreType !== "FORWARD" || !value.found || !value.info) {
            continue;
        }

        matched = true;
        result = getAspectValueMatchedTo(value, aspect);

        if (JOB_FUNCTIONS_GREEN.includes(result)) {
            return result;
        }
    }

    if (!matched) {
        return MatchedTo.NOTHING;
    } else {
        return result || MatchedTo.UNKNOWN;
    }
}

export function getAspectValueMatchedTo(value, aspect) {
    if (!value.found) {
        return MatchedTo.NOTHING;
    }

    if (!value.info) {
        console.warn("[getAspectValueMatchedTo] Empty info value");
        return MatchedTo.UNKNOWN;
    }

    if (value.info === "CONCEPT_ID") {
        return MatchedTo.CONCEPT_ID;
    } else if (value.info.startsWith(aspect)) {
        return MatchedTo.ASPECT;
    } else if (value.info.startsWith("jobFunctions")) {
        return MatchedTo.ASPECT;
    } else if (value.info.startsWith("hardSkills")) {
        return MatchedTo.ASPECT;
    } else if (value.info.startsWith("jobTitle")) {
        return MatchedTo.JOB_TITLE;
    } else if (value.info.startsWith("jobText")) {
        return MatchedTo.JOB_TEXT;
    } else if (value.info.startsWith("cvText")) {
        return MatchedTo.CV_TEXT;
    } else if (value.info.startsWith("workExperiences")) {
        return MatchedTo.WORK_EXPERIENCES;
    } else if (value.info.startsWith("educations")) {
        return MatchedTo.EDUCATIONS;
    } else {
        console.warn("[getAspectValueMatchedTo] Unsupported info value: " + value.info);
        return MatchedTo.FIELDS;
    }
}

export function getMatchedToMessageId(
    matchedTo,
    aspect = undefined,
    noMatch,
    unsure,
    isDisplayedScoreType,
    resultType,
    fields
) {
    let result;
    // sure/unsure.forward/reverse.matched/notMatched.candidate/job.
    if (unsure) {
        result = "unsure";
    } else {
        result = "sure";
    }
    if (isDisplayedScoreType) {
        result += ".forward";
    } else {
        result += ".reverse";
    }
    if (noMatch) {
        result += ".notMatched";
    } else {
        result += ".matched";
    }

    if (aspect !== undefined) {
        //todo: tighter check?
        matchedTo = MatchedTo.ASPECT;
    }

    switch (matchedTo) {
        case MatchedTo.ASPECT:
            result += `.ASPECT`;
            //availabilityAspect, contractTypes, jobCategories, languages, locations, softSkills, wage, workingHours
            break;
        case MatchedTo.OTHER_ASPECT_JOB_FUNCTIONS:
            result += ".ASPECT";
            break;
        case MatchedTo.OTHER_ASPECT_HARD_SKILLS:
            result += ".ASPECT";
            break;
        case MatchedTo.FIELDS:
            result += ".FIELDS"; //no longer relevant I guess?
            break;
        default:
            result += `.${matchedTo}`;
    }

    if (fields !== undefined && isDisplayedScoreType) {
        result += ".FIELDS";
    }
    return result;
}

export function getDynamicProperty(document, property) {
    if (!property) {
        return undefined;
    } else if (isFunction(property)) {
        return property(document);
    } else if (isFunctionAndArguments(property)) {
        return property.function(document, property.arguments);
    } else if (isString(property)) {
        const value = objectPath.get(document, property);
        return getTranslatedValue(property, value);
    } else {
        console.error("Not a valid dynamic property", property);
        return undefined;
    }
}
