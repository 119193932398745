import PropTypes from "prop-types";
import config from "~/config";

const ValuePropType = PropTypes.oneOfType([
    PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            range: PropTypes.number.isRequired,
            rangeUnit: PropTypes.oneOf(config("enums.rangeUnit")).isRequired,
        })
    ),
    PropTypes.shape({
        weight: PropTypes.number.isRequired,
        required: PropTypes.bool.isRequired,
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
    }),
    PropTypes.shape({
        weight: PropTypes.number.isRequired,
        required: PropTypes.bool.isRequired,
        value: PropTypes.number.isRequired,
    }),
]);

export default PropTypes.shape({
    type: PropTypes.oneOf(["concepts", "keywords", "keywords-tree", "locations", "range", "float", "integer"]).isRequired,
    weight: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    custom: PropTypes.bool,
    value: ValuePropType,
});

export const AspectConfig = PropTypes.shape({
    type: PropTypes.oneOf(["concepts", "keywords", "keywords-tree", "locations", "range", "float", "integer"]).isRequired,
    weight: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    overrideApiRequired: PropTypes.bool.isRequired,
    value: ValuePropType,
    values: PropTypes.any, // TODO
    autocomplete: PropTypes.arrayOf(PropTypes.string),
    suggestionInput: PropTypes.bool,
});
