import {Enum} from "~/enums/index";

export default new Enum({
    FORWARD: "FORWARD",
    REVERSE: "REVERSE",
    MUTUAL: "MUTUAL",
    MUTUAL_FORWARD_WEIGHT: "MUTUAL_FORWARD_WEIGHT",
    MUTUAL_FORWARD_SCORE: "MUTUAL_FORWARD_SCORE",
    MUTUAL_REVERSE_SCORE: "MUTUAL_REVERSE_SCORE",
});
