import Match from "./Match";
import {convertJobFromApi} from "~/util/convert-from-api";

export default class JobMatch extends Match {
    _deprecatedJobConverted = false;
    _deprecatedJob = undefined;

    /**
     * @deprecated Do not use in new code
     */
    get job() {
        if (!this._deprecatedJobConverted) {
            this._deprecatedJobConverted = true;
            this._deprecatedJob = this._data.document !== undefined ? convertJobFromApi(this._data.document) : undefined;
        }

        return this._deprecatedJob;
    }

    get detailsText() {
        return {
            label: "detailsModal.jobText",
            value: this._data.document.jobText,
        };
    }

    /**
     * @deprecated Do not use in new code
     */
    get deprecatedEntity() {
        return this.job;
    }
}
