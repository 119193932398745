import Validators from "~/config/validation/validators/Validators";
import FilterValuesDefinition from "~/config/definitions/shared/FilterValuesDefinition";

export default Validators.objectOf({
    defaultValue: {},
    description:
        "Filter names as keys and filter configurations as values. Filter names provided here are the values used for the `filters` properly in `features.jobToCandidates.indices.N`, `features.profileToCandidates.indices.N` and `features.profileToJobs.indices.N`.",
    values: Validators.shape({
        description:
            "Filter names as keys and filter configurations as values. Filter names provided here are the values used for the `filters` properly in `features.jobToCandidates.indices.N`, `features.profileToCandidates.indices.N` and `features.profileToJobs.indices.N`.",
        shape: {
            label: Validators.string({
                description: "Displayed label for the filter. Currently not translated.",
                defaultValue: "Visible NL",
            }),
            type: Validators.string({
                description:
                    "Term filters allow you to select a single option from a list. Filter type, one of: `term`, `multipleTerm`, `autocompleteTerm`.",
                defaultValue: "term",
            }),
            property: Validators.string({
                description:
                    "Property to filter on. This can be overriden in a certain case, see `term` filter values below.",
                defaultValue: "extraProperties.visibleNL",
            }),
            values: FilterValuesDefinition.allowsUndefined,
            defaultValue: Validators.oneOfType({
                description: "The default value for the term filter (optional).",

                validTypes: [
                    Validators.string({
                        description: "The default value for the term filter (optional).",
                    }).allowsUndefined,
                    Validators.boolean({
                        description: "The default value for the term filter (optional).",
                        defaultValue: false,
                    }),
                ],
            }),
        },
    }),
});
