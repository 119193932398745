import Validator from "~/config/validation/validators/Validator";
import {isString} from "~/util/misc";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class StringValidator extends Validator {
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {string}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (isString(value)) {
            return value;
        } else {
            //console.warn(this.description);
            throw new ValidationError(path, `Expected a string, but got ${betterTypeOf(value)}`);
        }
    }
}
