import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import {FormattedMessage} from "react-intl";
import c from "classnames";
import config from "~/config";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";
import AspectRow from "./AspectRow";
import WorkExperiencesRow from "./WorkExperiencesRow";
import EducationsRow from "./EducationsRow";
import styles from "./styles.module.scss";
import DynamicProperty from "~/prop-types/dynamic-property";
import ExtraPropertyRow from "~/components/MatchComparisonTable/ExtraPropertyRow";
import MatchHeader from "~/components/MatchComparisonTable/MatchHeader";

const Sections = {
    ASPECTS: "aspects",
    EXTRA_PROPERTIES: "extraProperties",
    WORK_EXPERIENCES: "workExperiences",
    EDUCATIONS: "educations",
};

export default class MatchComparisonTable extends React.PureComponent {
    static propTypes = {
        indexConfig: PropTypes.object.isRequired,
        indexState: PropTypes.object.isRequired,
        matches: PropTypes.array, // TODO: More specific
        detailsTitleProperty: DynamicProperty,
        comparisonSubtitleProperty: DynamicProperty,
        comparisonExtraProperties: PropTypes.arrayOf(PropTypes.string),
        reverseLabel: PropTypes.string.isRequired,
        reverseHelp: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.string),
        onSelectionChange: PropTypes.func.isRequired,
        resultType: PropTypes.string.isRequired,
    };

    static defaultProps = {
        sections: [Sections.ASPECTS, Sections.EXTRA_PROPERTIES],
    };

    render() {
        const {sections} = this.props;

        return (
            <Table size="sm" className={styles.table}>
                <thead>{this.renderHeader()}</thead>
                <tbody>
                    {sections.includes(Sections.ASPECTS) && this.renderAspects()}
                    {sections.includes(Sections.EXTRA_PROPERTIES) && this.renderExtraProperties()}
                    {sections.includes(Sections.WORK_EXPERIENCES) && this.renderWorkExperiences()}
                    {sections.includes(Sections.EDUCATIONS) && this.renderEducations()}
                </tbody>
            </Table>
        );
    }

    renderHeader() {
        const {indexConfig, indexState, matches} = this.props;

        return (
            <tr>
                <th className={c(styles.inlineHeader)}>
                    <FormattedMessage id="matchQuality.aspectColumn" />
                </th>
                {matches.map((match, index) => (
                    <MatchHeader
                        key={index}
                        indexConfig={indexConfig}
                        match={match}
                        isSelected={indexState.selection.includes(match.id)}
                        onSelectedChange={this.handleSelectedChange}
                    />
                ))}
            </tr>
        );
    }

    renderAspects() {
        const {matches, reverseLabel, reverseHelp, resultType} = this.props;

        return config("ui.aspectOrder")
            .filter(aspect => EnabledAspectsBlackWhiteList.allows(aspect))
            .map((aspect, index) => (
                <AspectRow
                    key={aspect}
                    name={aspect}
                    reverseLabel={reverseLabel}
                    reverseHelp={reverseHelp}
                    isFirstRow={index === 0}
                    matches={matches}
                    resultType={resultType}
                />
            ));
    }

    renderExtraProperties() {
        const {comparisonExtraProperties, matches} = this.props;

        if (comparisonExtraProperties === undefined) {
            return null;
        }

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.extraProperties" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                {comparisonExtraProperties.map(propertyPath => (
                    <ExtraPropertyRow
                        key={propertyPath}
                        propertyPath={propertyPath}
                        matches={matches}
                    />
                ))}
            </React.Fragment>
        );
    }

    renderWorkExperiences() {
        const {matches} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.workExperiences" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                <WorkExperiencesRow matches={matches} />
            </React.Fragment>
        );
    }

    renderEducations() {
        const {matches} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.educations" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                <EducationsRow matches={matches} />
            </React.Fragment>
        );
    }

    handleSelectedChange = (id, selected) => {
        const {indexState, onSelectionChange} = this.props;
        let nextSelection;

        if (selected) {
            nextSelection = [...indexState.selection, id];
        } else {
            nextSelection = indexState.selection.filter(x => x !== id);
        }

        onSelectionChange(nextSelection);
    };
}
