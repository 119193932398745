import {isString} from "~/util/misc";

export function childPath(path, key) {
    if (isString(key) && key.includes(".")) {
        return path ? `${path}.[${key}]` : `[${key}]`;
    } else {
        return path ? `${path}.${key}` : key;
    }
}

export function betterTypeOf(value) {
    if (value === null) {
        return "null";
    } else {
        return typeof value;
    }
}