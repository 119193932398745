import Validators from "~/config/validation/validators/Validators";
import ValidationError from "~/config/validation/ValidationError";

export default Validators.shape({
    description:
        "The job-to-candidates matching UI, which is a process with several steps: providing a job description, job parsing, and finally candidate matching.",
    shape: {
        enabled: Validators.boolean({
            description: "Text mining enabled or not.",
            defaultValue: false,
        }),
        parser: Validators.string({
            description: "Case insenstive name of the parser class to use.",
        }).allowsUndefined,
        inputType: Validators.string({
            description: "Input type used for parsing. One of: `txt`, `csv`.",
        }).custom((value, path) => {
            if (value !== "csv" && value !== "txt") {
                throw new ValidationError(
                    path,
                    `invalid input type: ${value}, expected csv or txt`
                );
            }
            return value;
        }),
    },
});
