import React from "react";
import PropTypes from "prop-types";
import {TreePickerModelPropType, ValuePropType} from "./prop-types";
import {createTreeSelectors} from "./selectors";
import TreeNode from "~/components/TreePicker/TreeNode";
import Input from "./Input";

export default class TreePicker extends React.PureComponent {
    static propTypes = {
        model: TreePickerModelPropType.isRequired,
        value: ValuePropType.isRequired,
        expanded: ValuePropType,
        onChange: PropTypes.func.isRequired,
        onExpandedChange: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.selectors = createTreeSelectors(this);
        const treeModel = this.selectors.convertToTreeModelSelector();

        this.state = {
            expanded: getDefaultExpanded(treeModel),
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.model !== this.props.model) {
            const treeModel = this.selectors.convertToTreeModelSelector();
            this.setState({expanded: getDefaultExpanded(treeModel)});
        }
    }

    getExpandedAsMap() {
        const {expanded} = this.props;

        if (expanded !== undefined && expanded !== null) {
            return this.selectors.expandedAsMapSelector();
        } else {
            return this.state.expanded;
        }
    }

    render() {
        const treeModel = this.selectors.convertToTreeModelSelector();

        return (
            <div>
                {treeModel.map(this.renderNode)}
            </div>
        );
    }

    renderNode = node => {
        const valueAsMap = this.selectors.valueAsMapSelector();
        const expandedAsMap = this.getExpandedAsMap();

        return (
            <TreeNode
                key={node.id}
                node={node}
                value={valueAsMap}
                expanded={expandedAsMap}
                onChange={this.handleChange}
                onExpandedChange={this.handleExpandedChange}
            />
        );
    };

    handleChange = valueAsMap => {
        const {value, onChange} = this.props;

        if (Array.isArray(value)) {
            onChange([...valueAsMap.keys()]);
        } else {
            onChange(valueAsMap);
        }
    };

    handleExpandedChange = expandedAsMap => {
        const {expanded, onExpandedChange} = this.props;

        if (expanded !== undefined && expanded !== null) {
            if (Array.isArray(expanded)) {
                onExpandedChange([...expandedAsMap.keys()]);
            } else {
                onExpandedChange(expandedAsMap);
            }
        } else {
            this.setState({expanded: expandedAsMap});
        }
    }
}

TreePicker.Input = Input;

export function getDefaultExpanded(treeModel) {
    const expanded = new Map();

    for (const node of treeModel) {
        expanded.set(node.value, true);
    }

    return expanded;
}