import React from "react";
import {NodePropType} from "../prop-types";
import TranslatableLabel from "~/components/TranslatableLabel";
import styles from "./styles.module.scss";

export default class Value extends React.PureComponent {
    static propTypes = {
        node: NodePropType.isRequired,
    }

    render() {
        const {node} = this.props;
        const leafNodeCount = node.countLeafNodes();

        return (
            <div className={styles.value} title={node.getPath()}>
                <span><TranslatableLabel value={node.label} /></span>
                {leafNodeCount > 0 && <span className={styles.count}>{leafNodeCount}</span>}
            </div>
        );
    }
}