import {
    convertJobFromApi,
    convertCandidateFromApi,
    convertParsedQueryItemsMatchProfile,
} from "~/util/convert-from-api";
import {removeDuplicateValues, removeUndefinedValues} from "~/util/misc";

export default function addCommonCalls(Client) {
    Client.prototype.parseJob = function(job) {
        return this.post("/parsing/parseJob", {
            title: job.title,
            text: job.description,
            locale: "bel_dut",
        });
    };

    Client.prototype.parseQuery = function(query, language) {
        return this.post(`/ontology/parseQuery`, {
            query,
            lang: language,
        }).then(({response}) => {
            return convertParsedQueryItemsMatchProfile(response.parsedQueryItems);
        });
    };

    Client.prototype.fetchJobV1 = function(jobIndex, id, matchingStrategy, language) {
        return this.get(
            `/hrmatching/jobs_index/${jobIndex}/doc/${id}`,
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
                language,
            })
        ).then(({response}) => convertJobFromApi(response));
    };

    Client.prototype.fetchSelection = function(endpoint, subjectId) {
        return this.get(`${endpoint}/${subjectId}`).then(({response}) => response);
    };

    Client.prototype.makeSelection = function(endpoint, subjectId, selectionIds, selectionStatus) {
        return this.post(endpoint, {
            subjectId,
            selectionIds,
            selectionStatus,
        });
    };

    Client.prototype.fetchJobs = function(jobIndex, from, size) {
        return this.post(`/hrmatching/match2jobs/${jobIndex}`, {
            from,
            size,
        }).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const jobs = response.matches.map(match => {
                const job = convertJobFromApi(match.document);
                job.id = match.id;
                return job;
            });

            return {metadata, jobs};
        });
    };

    Client.prototype.fetchCandidateV1 = function(candidateIndex, id, matchingStrategy, language) {
        return this.get(
            `/hrmatching/candidates_index/${candidateIndex}/doc/${id}`,
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
                language,
            })
        ).then(({response}) => convertCandidateFromApi(response));
    };

    Client.prototype.fetchCandidates = function(candidateIndex, from, size) {
        return this.post(`/hrmatching/match2candidates/${candidateIndex}`, {
            from,
            size,
        }).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const candidates = response.matches.map(match => {
                const candidate = convertCandidateFromApi(match.document);
                candidate.id = match.id;
                return candidate;
            });

            return {metadata, candidates};
        });
    };

    Client.prototype.textMiningParse = function(parser, input, inputType) {
        return this.post(
            `/parsing/parse/${parser}/toJson`,
            input,
            {inputType: inputType, features: "LEMMA_SPLIT_SPELL"},
            {"Content-Type": "text/plain"}
        );
    };

    Client.prototype.textMiningNGrams = function(input) {
        return this.post(
            "/stats/ngrams/1,2,3,4,5,6/DUT,ENG",
            input,
            {cutoff: 2, cutoffType: "MIN_FREQUENCY"},
            {"Content-Type": "text/plain"}
        );
    };

    Client.prototype.findPrefix = function(input, language, conceptTypes = ["AGENT", "PROPERTY"]) {
        return this.post(
            "/ontology/findPrefix",
            {text: input, lang: language, conceptTypes: conceptTypes},
            {filterOnIndex: false}
        );
    };

    Client.prototype.findPrefixStrings = function(input, language, conceptTypes) {
        // Reverse sort order for types (last in the list will come first in results)
        const typeSortOrder = ["PROPERTY", "AGENT"];

        return this.findPrefix(input, language, conceptTypes)
            .then(({response}) =>
                response
                    .filter(item => item.matchedTerm.language === language)
                    .sort((a, b) => typeSortOrder.indexOf(b.type) - typeSortOrder.indexOf(a.type))
                    .map(item => item.matchedTerm.text)
            )
            .then(removeDuplicateValues);
    };

    Client.prototype.keywordSuggestions = function(index, keywords, language) {
        return this.post(`/hrmatching/suggest/${index}`, {keywords, language});
    };
}
