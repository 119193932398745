import Validators from "~/config/validation/validators/Validators";

export default Validators.shape({
    description: "Auth0 configuration",
    shape: {
        enabled: Validators.boolean({
            description: "If true, login through Auth0 is enabled",
            defaultValue: false,
        }),
        audience: Validators.string({
            description: "OAuth2 audience",
            defaultValue: "https://api.thematchbox.be/services",
        }),
        scopes: Validators.string({
            description: "Scopes to request when requesting the access token. Space-separated.",
            defaultValue: "read:hrmatching read:parsing read:ontology",
        }),
        domain: Validators.string({
            description: "Domain used for Auth0 component",
            defaultValue: "thematchbox.eu.auth0.com",
        }),
        clientId: Validators.string({
            description: "Client ID for requesting the token",
            defaultValue: "QQ1d3UvPH8YcOQCDGFu8gnlreaffpqGp",
        }),
        connection: Validators.string({
            description: "name of connection",
        }).allowsUndefined
    },
});
