import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class ArrayValidator extends Validator {
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (Array.isArray(value)) {
            return value;
        } else {
            throw new ValidationError(path, `Expected an array, but got ${betterTypeOf(value)}`);
        }
    }
}
