import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class IntegerValidator extends Validator {
    constructor(description, defaultValue, maxValue) {
        super(description, defaultValue);
        this.maxValue = maxValue;
    }
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {number} an integer
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (Number.isInteger(value)) {
            if (this.maxValue !== undefined && this.maxValue != null){
                if (value < this.maxValue){
                    return value;
                } else{
                    throw new ValidationError(path, `Expected an integer with a value smaller than ${this.maxValue}, but got ${value}`)
                }
            }else{
                return value;
            }
        } else {
            throw new ValidationError(path, `Expected an integer, but got ${betterTypeOf(value)}`);
        }
    }
}
