import Validator from "~/config/validation/validators/Validator";
import {isPlainObject} from "~/util/misc";
import ValidationError from "~/config/validation/ValidationError";

export default class ObjectValidator extends Validator {
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (isPlainObject(value)) {
            return value;
        } else {
            throw new ValidationError(path, `Expected a plain javascript object, but got ${value}`);
        }
    }
}
