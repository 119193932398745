import moment from "moment";
import * as immutable from "object-path-immutable";
import config from "~/config";
import {
    MATCH_SOURCE_ENTITY_LOADED,
    SET_INDEX_LAST_REQUEST,
    SET_LAST_REQUEST,
    setIndexNextRequest,
    setNextRequest,
} from "~/actions/matching";
import {storageAvailable} from "~/util/misc";

const CURRENT_VERSION = 1;

export const localStorageSearchState = store => next => action => {
    const result = next(action);

    if (!localStorageEnabled()) {
        return result;
    }

    try {
        if (action.type === MATCH_SOURCE_ENTITY_LOADED) {
            const state = store.getState();
            const matchingContext = state.matching.contexts[action.contextId];
            const matchSourceEntity = matchingContext.matchSourceEntity;
            loadStateForContext(action.contextId, matchSourceEntity, matchingContext, store);
        } else if ((action.type === SET_LAST_REQUEST || action.type === SET_INDEX_LAST_REQUEST) && !action.dontSaveRequest) {
            // Note: This does save the state more times than needed (for example, with 2 indicies it saves the
            //       state 3 times), but it's a very robust solution.
            const state = store.getState();
            const matchingContext = state.matching.contexts[action.contextId];
            const matchSourceEntity = matchingContext.matchSourceEntity;
            saveStateForContext(action.contextId, matchSourceEntity, matchingContext);
        }
    } catch (e) {
        console.error(e);
    }

    return result;
};

function saveStateForContext(contextId, matchSourceEntity, matchingContext) {
    if (matchSourceEntity === undefined) {
        return;
    }

    const savedState = getSavedState(matchSourceEntity, matchingContext);
    const savedStateJson = JSON.stringify(savedState);
    window.localStorage.setItem(getKey(contextId, matchSourceEntity), savedStateJson);
    window.localStorage.removeItem(getOldKey(contextId, matchSourceEntity));
}

function loadStateForContext(contextId, matchSourceEntity, matchingContext, store) {
    // Check if we have JSON stored at all
    let savedStateJson = window.localStorage.getItem(getKey(contextId, matchSourceEntity));

    if (!savedStateJson) {
        savedStateJson = window.localStorage.getItem(getOldKey(contextId, matchSourceEntity));
    }

    if (!savedStateJson) {
        return;
    }

    // Check if we have valid JSON and a valid version
    const savedState = JSON.parse(savedStateJson);

    if (!savedState || savedState.version !== CURRENT_VERSION) {
        window.localStorage.removeItem(getKey(contextId, matchSourceEntity));
        window.localStorage.removeItem(getOldKey(contextId, matchSourceEntity));
        return;
    }

    // Check that the state isn't too old
    if (savedState.storedAt !== undefined && config("localStorage.maxAge") !== undefined) {
        const oldestAllowedStoredAt = moment.utc().subtract(moment.duration(config("localStorage.maxAge")));
        const storedAt = moment.utc(savedState.storedAt);

        if (storedAt.isBefore(oldestAllowedStoredAt)) {
            window.localStorage.removeItem(getKey(contextId, matchSourceEntity));
            window.localStorage.removeItem(getOldKey(contextId, matchSourceEntity));
            return;
        }
    }

    // Check if the entity version is correct
    const versionProperty = config("localStorage.versionProperty");

    if (versionProperty !== undefined && versionProperty !== null && savedState.entityVersion !== immutable.get(matchSourceEntity.document, versionProperty)) {
        window.localStorage.removeItem(getKey(contextId, matchSourceEntity));
        window.localStorage.removeItem(getOldKey(contextId, matchSourceEntity));
        return;
    }

    // Load state
    const configuration = matchingContext.configurationFn(store.getState());
    const data = savedState.data;

    store.dispatch(
        setNextRequest(contextId, {
            ...matchingContext.nextRequest,
            ...respectConfig(data.lastRequest),
        })
    );

    for (const indexName in matchingContext.results) {
        if (!data.results[indexName]) {
            continue;
        }

        const index = configuration.indices.find(x => x.name === indexName);

        store.dispatch(
            setIndexNextRequest(contextId, index, {
                ...matchingContext.results[indexName].nextRequest,
                ...respectConfig(data.results[indexName].lastRequest),
            })
        );
    }
}

function respectConfig(lastRequest) {
    const result = {...lastRequest};
    delete result.excludedIds;

    if (!config("localStorage.storeAspects")) {
        delete result.matchProfile;
    }

    if (!config("localStorage.storeFilters")) {
        delete result.filters;
        delete result.customFiltersPredicate;
    }

    if (!config("localStorage.storeSortMode")) {
        delete result.sortMode;
    }

    return result;
}

function getSavedState(matchSourceEntity, matchingContext) {
    const state = {
        version: CURRENT_VERSION,
        storedAt: moment.utc().format(),
        data: {
            lastRequest: matchingContext.lastRequest,
            results: Object.keys(matchingContext.results).reduce((results, indexName) => {
                results[indexName] = {
                    lastRequest: matchingContext.results[indexName].lastRequest,
                };

                return results;
            }, {}),
        },
    };

    const versionProperty = config("localStorage.versionProperty");

    if (versionProperty !== undefined && versionProperty !== null) {
        state.entityVersion = immutable.get(matchSourceEntity.document, versionProperty);
    }

    return state;
}

function localStorageEnabled() {
    return storageAvailable("localStorage") && (
        config("localStorage.storeAspects") ||
        config("localStorage.storeFilters") ||
        config("localStorage.storeSortMode")
    );
}

function getKey(contextId, matchSourceEntity) {
    return `search-state:${contextId}:${matchSourceEntity.type}:${matchSourceEntity.index}:${matchSourceEntity.id}`;
}

/**
 * TODO: In the future, remove this and related code.
 *
 * @param contextId
 * @param matchSourceEntity
 * @returns {string}
 */
function getOldKey(contextId, matchSourceEntity) {
    return `search-state:${contextId}:${matchSourceEntity.type}:${matchSourceEntity.id}`;
}
