import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import FetchState from "~/enums/FetchState";
import TabBar from "~/components/TabBar";

export default class Tab extends React.PureComponent {
    static propTypes = {
        index: PropTypes.object.isRequired,
        state: PropTypes.object,
        isActive: PropTypes.bool.isRequired,
        isFakeTab: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {index, isActive} = this.props;

        return (
            <TabBar.Tab
                title={index.label}
                subtitle={this.getSubtitle()}
                isActive={isActive}
                isProcessing={this.isMatching()}
                onClick={this.handleClick}
            />
        );
    }

    getSubtitle() {
        const {state, isFakeTab} = this.props;

        if (!state || state.count === undefined) {
            if (isFakeTab || !this.isError()) {
                return null;
            } else {
                return <i className="fas fa-exclamation-triangle" />;
            }
        }

        return <FormattedMessage id="results.count" values={{count: state.count}} />;
    }

    isMatching() {
        const {state} = this.props;

        if (!state) {
            return false;
        }

        return (
            Object.values(state.pages).find(page => page.state === FetchState.FETCHING) !==
            undefined
        );
    }

    isError() {
        const {state} = this.props;

        if (!state) {
            return false;
        }

        return (
            Object.values(state.pages).find(page => page.state === FetchState.ERROR) !==
            undefined
        );
    }

    handleClick = () => {
        const {index, onClick} = this.props;
        onClick(index);
    };
}
