import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import config from "~/config";
import FilterValuePropType from "~/prop-types/filter-value";
import Filter from "../Filter";
import styles from "./styles.module.scss";
import {getValuesForFilter} from "~/util/filter-values";
import TranslatableLabel from "~/components/TranslatableLabel";

class TermFilter extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: FilterValuePropType.isRequired,
        filterName: PropTypes.string.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            values: [],
        };

        getValuesForFilter(props.filterName).then(values => {
            this.setState({values});
        });
    }

    render() {
        const {intl, value, filterName, className} = this.props;
        const label = config(`filters.${filterName}.label`);

        return (
            <Filter
                label={intl.formatMessage(
                    {id: "termFilter.label"},
                    {label: TranslatableLabel.getFinalValue(label)}
                )}
                value={value.enabled}
                disabled={value.value === null}
                className={className}
                onChange={this.handleEnabledChange}
            >
                {this.renderSelect()}
            </Filter>
        );
    }

    renderSelect() {
        const {value, intl} = this.props;
        const {values} = this.state;

        return (
            <div className={styles.selectWrapper}>
                <select
                    value={JSON.stringify(value.value)}
                    onChange={this.handleSelectChange}
                    className={styles.select}
                >
                    {value.value === null && (
                        <option key="__none__" value={JSON.stringify(null)} disabled={true}>
                            {intl.formatMessage({id: "select.chooseValue"})}
                        </option>
                    )}
                    {values.map(value => (
                        <option
                            key={JSON.stringify(value.value)}
                            value={JSON.stringify(value.value)}
                        >
                            {TranslatableLabel.getFinalValue(value.label)}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    handleEnabledChange = nextEnabled => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            enabled: nextEnabled,
        });
    };

    handleSelectChange = e => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            enabled: true,
            value: JSON.parse(e.target.value),
        });
    };
}

export default injectIntl(TermFilter, {forwardRef: true});
