import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";
import PredicateEditorPropTypes from "../prop-types";
import Select from "~/components/Select";
import {createSelector} from "reselect";
import TranslatableLabel from "~/components/TranslatableLabel";

export default class AddGroupOrRule extends React.PureComponent {
    static propTypes = {
        canAddGroup: PropTypes.bool.isRequired,
        propertyDefinitions: PredicateEditorPropTypes.propertyDefinitions.isRequired,
        className: PropTypes.string,
        onAddGroup: PropTypes.func.isRequired,
        onAddRule: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.optionsSelector = createOptionsSelector();
    }

    render() {
        const {className} = this.props;
        const options = this.optionsSelector(this.props);

        return (
            <div className={c(styles.add, className)}>
                <Select
                    selected={null}
                    onChange={this.handleAdd}
                    options={options}
                    className={styles.select}
                    variant="extra-small"
                />
            </div>
        );
    }

    handleAdd = option => {
        if (option.value.type === "group") {
            this.props.onAddGroup(option.value.combinator);
        } else if (option.value.type === "rule") {
            this.props.onAddRule(option.value.property);
        }
    };
}

function createOptionsSelector() {
    return createSelector(
        props => props,
        ({canAddGroup, propertyDefinitions}) => {
            const options = [
                {
                    label: canAddGroup
                        ? [{id: "predicateEditor.addRuleOrGroup"}, "..."]
                        : [{id: "predicateEditor.addRule"}, "..."],
                    value: null,
                    style: {display: "none"},
                },
                {
                    label: {id: "predicateEditor.addRule"},
                    options: Object.keys(propertyDefinitions).map(name => ({
                        value: {type: "rule", property: name},
                        label: propertyDefinitions[name].label,
                    })).sort(compareOptionsByLabel),
                },
            ];

            if (canAddGroup) {
                options.push({
                    label: {id: "predicateEditor.addGroup"},
                    options: [
                        {
                            label: [{id: "predicateEditor.all"}, "..."],
                            value: {type: "group", combinator: "and"},
                        },
                        {
                            label: [{id: "predicateEditor.any"}, "..."],
                            value: {type: "group", combinator: "or"},
                        },
                    ],
                });
            }

            return options;
        }
    );
}

export function compareOptionsByLabel(a, b) {
    return TranslatableLabel.getFinalValue(a.label).localeCompare(TranslatableLabel.getFinalValue(b.label));
}

