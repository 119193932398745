import Client from "./client";
import addCommonCalls from "./calls/common";
import addMatchingStrategyCalls from "./calls/matching-strategy";
import addMonitoringCalls from "./calls/monitoring";
import addMatchingCalls from "./calls/matching";
import addV2Calls from "~/api/calls/v2-calls";

addCommonCalls(Client);
addMatchingStrategyCalls(Client);
addMonitoringCalls(Client);
addMatchingCalls(Client);
addV2Calls(Client);

const client = new Client();
export default client;
