import Api from "~/api";
import {SaveStatus, Status} from "~/reducers/matching-strategy";
import ProfileType from "~/enums/ProfileType";

export const UPDATE_STRATEGY = "matching-strategy/UPDATE_STRATEGY";
export const REMOVE_STRATEGY = "matching-strategy/REMOVE_STRATEGY";
export const SET_STATUS = "matching-strategy/SET_STATUS";
export const SET_SAVE_STATUS = "matching-strategy/SET_SAVE_STATUS";
export const SET_SCHEMA = "matching-strategy/SET_SCHEMA";
export const SET_ALL_STRATEGIES = "matching-strategy/SET_ALL_STRATEGIES";
export const SET_DEFAULT_STRATEGY = "matching-strategy/SET_DEFAULT_STRATEGY";

export function initialize() {
    return dispatch => {
        dispatch(setStatus(Status.INITIALIZING));

        Promise.all([
            Api.getMatchingStrategyDescription(),
            Api.getMatchingStrategies(),
            Api.getDefaultMatchingStrategy(ProfileType.JOB),
            Api.getDefaultMatchingStrategy(ProfileType.CANDIDATE),
        ])
            .then(([schema, allStrategies, defaultJobStrategy, defaultCandidateStrategy]) => {
                dispatch(setSchema(schema));
                dispatch(setAllStrategies(allStrategies));
                dispatch(setDefaultStrategy(ProfileType.JOB, defaultJobStrategy));
                dispatch(setDefaultStrategy(ProfileType.CANDIDATE, defaultCandidateStrategy));
                dispatch(setStatus(Status.INITIALIZED));
            })
            .catch(error => {
                console.error("Couldn't initialize matching strategies", error);
            });
    };
}

export function updateStrategy(name, strategy) {
    return {
        type: UPDATE_STRATEGY,
        name,
        strategy,
    };
}

export function removeStrategy(name) {
    return {
        type: REMOVE_STRATEGY,
        name,
    };
}

export function saveChanges() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(setSaveStatus(SaveStatus.SAVING));

        return Promise.all(generateSavePromises(state))
            .then(() => {
                dispatch(setSaveStatus(SaveStatus.SAVED));
                dispatch(setAllStrategies(state.matchingStrategy.allStrategies));
            })
            .catch(() => {
                dispatch(setSaveStatus(SaveStatus.CHANGED));
            });
    };
}

function generateSavePromises(state) {
    const strategies = state.matchingStrategy.allStrategies;
    const originalStrategies = state.matchingStrategy.originalAllStrategies;
    const promises = [];

    for (const name in strategies) {
        if (
            originalStrategies[name] === undefined ||
            JSON.stringify(strategies[name]) !== JSON.stringify(originalStrategies[name])
        ) {
            promises.push(Api.addOrUpdateMatchingStrategy(name, strategies[name]));
        }
    }

    for (const name in originalStrategies) {
        if (strategies[name] === undefined) {
            promises.push(Api.deleteMatchingStrategy(name));
        }
    }

    return promises;
}

function setStatus(status) {
    return {
        type: SET_STATUS,
        status,
    };
}

function setSaveStatus(saveStatus) {
    return {
        type: SET_SAVE_STATUS,
        saveStatus,
    };
}

function setSchema(schema) {
    return {
        type: SET_SCHEMA,
        schema,
    };
}

function setAllStrategies(allStrategies) {
    return {
        type: SET_ALL_STRATEGIES,
        allStrategies,
    };
}

function setDefaultStrategy(profileType, defaultStrategy) {
    return {
        type: SET_DEFAULT_STRATEGY,
        profileType,
        defaultStrategy,
    };
}
