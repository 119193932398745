import Validator from "~/config/validation/validators/Validator";

class AllowsNullValidator extends Validator {
    constructor(baseValidator) {
        super(baseValidator.description, baseValidator.defaultValue);
        this.baseValidator = baseValidator;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === null || (value === undefined && this.defaultValue === null)) {
            return null;
        } else {
            return this.baseValidator.validate(value, path, rootValue);
        }
    }
}

Object.defineProperty(Validator.prototype, "allowsNull", {
    get: function() {
        return new AllowsNullValidator(this);
    },
});
