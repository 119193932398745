import uuid from "uuid/v4";

/**
 * Returns a cache container with a `get()` function. The returned promise is guaranteed to resolve to the latest requested
 * data, even if a new request is made in the middle of a running request.
 *
 * @param getKey
 * @param getPromise
 * @return {{get: (function(number, ...[*]): Promise)}}
 */
export function createCachedPromiseContainer(getKey, getPromise) {
    /**
     * @type {Object.<any,CacheEntry>}
     */
    const cache = {}

    return {
        /**
         * Returns a Promise that is guaranteed to resolve to the latest value. Returns a cached promise if maximumAge is not exceeded.
         *
         * @param {number} maximumAge maximum age of the last request (in regards to request start time) in seconds
         * @param {...*} params these parameters are passed to the `getKey()` and `getPromise()` functions used to create the container
         * @return {Promise}
         */
        get: function(maximumAge, ...params) {
            const key = getKey(...params);
            let cacheEntry = cache[key];

            if (cacheEntry !== undefined && !cacheEntry.isExpired(maximumAge)) {
                return cacheEntry.wrapperPromise;
            } else if (cacheEntry === undefined) {
                cacheEntry = new CacheEntry();
                cache[key] = cacheEntry;
            }

            const promise = getPromise(...params);
            cacheEntry.accept(promise, uuid());
            return cacheEntry.wrapperPromise;
        }
    }
}

class CacheEntry {
    createNewWrapper = true;
    wrapperPromise;
    wrapperResolve;
    wrapperReject;

    promise;
    uuid;
    startedAt;

    accept(promise, uuid) {
        if (this.createNewWrapper) {
            this.createNewWrapper = false;
            this.wrapperPromise = new Promise((resolve, reject) => {
                this.wrapperResolve = resolve;
                this.wrapperReject = reject;
            });
        }

        this.promise = promise;
        this.uuid = uuid;
        this.startedAt = new Date();

        promise.then(value => {
            if (this.uuid === uuid) {
                this.createNewWrapper = true;
                this.wrapperResolve(value);
            }
        }).catch(exception => {
            if (this.uuid === uuid) {
                this.createNewWrapper = true;
                this.wrapperReject(exception);
            }
        })
    }

    isExpired(maximumAge) {
        const now = new Date();
        return now - this.startedAt >= maximumAge;
    }
}