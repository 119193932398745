import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {messageExists} from "~/util/ui-translations";

class FallbackFormattedMessageRaw extends React.PureComponent {
    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.string).isRequired,
        intl: PropTypes.object.isRequired,
        // Rest of passed props are forwarded to FormattedMessage
    };

    render() {
        const {intl, ids, ...forwardedProps} = this.props;

        for (const id of ids) {
            if (messageExists(intl, id)) {
                return <FormattedMessage {...forwardedProps} id={id} />;
            }
        }

        return forwardedProps.defaultMessage || ids[0];
    }
}

const FallbackFormattedMessage = injectIntl(FallbackFormattedMessageRaw, {forwardRef: true});
FormattedMessage.Fallback = FallbackFormattedMessage;
export default FallbackFormattedMessage;
