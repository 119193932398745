import React from "react";
import c from "classnames";
import {TreePickerModelPropType, ValuePropType} from "../prop-types";
import PropTypes from "prop-types";
import {createTreeSelectors} from "../selectors";
import Value from "~/components/TreePicker/Input/Value";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TreePicker, {getDefaultExpanded} from "../TreePicker";

const EMPTY_VALUE = new Map();

/**
 * An input-field like wrapper for the TreePicker. Displays a summary of the value. When clicked, opens a dialog with the full
 * TreePicker inside.
 */
export default class Input extends React.PureComponent {
    static propTypes = {
        model: TreePickerModelPropType.isRequired,
        value: ValuePropType.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.selectors = createTreeSelectors(this);
        const treeModel = this.selectors.convertToTreeModelSelector();

        this.state = {
            editedValue: undefined,
            expanded: getDefaultExpanded(treeModel),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.model !== this.props.model) {
            const treeModel = this.selectors.convertToTreeModelSelector();
            this.setState({expanded: getDefaultExpanded(treeModel)});
        }
    }

    render() {
        const {editedValue, expanded} = this.state;
        const treeModel = this.selectors.convertToTreeModelSelector();

        return (
            <div className={styles.input}>
                <div className={styles.valueContainer} onClick={this.handleEditClick}>
                    {this.renderValues()}
                </div>
                <Modal show={editedValue !== undefined} onHide={this.handleHideEditor} scrollable={true}>
                    <Modal.Body>
                        <TreePicker
                            model={treeModel}
                            value={editedValue || EMPTY_VALUE}
                            expanded={expanded}
                            onChange={this.handleChange}
                            onExpandedChange={this.handleExpandedChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.handleHideEditor}
                        >
                            <FormattedMessage id="treePickerInput.cancel" />
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.handleSaveChanges}
                        >
                            <FormattedMessage id="treePickerInput.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    renderValues() {
        const treeModel = this.selectors.convertToTreeModelSelector();
        const valueAsMap = this.selectors.valueAsMapSelector();
        const renderedNodes = [];
        renderNodes(renderedNodes, valueAsMap, treeModel);

        if (renderedNodes.length > 0) {
            return renderedNodes;
        } else {
            return this.renderDummyValue();
        }
    }

    renderDummyValue() {
        return (
            <div className={c(styles.value, styles.placeholder)}>
                <span><FormattedMessage id="treePickerInput.empty" /></span>
            </div>
        );
    }

    handleEditClick = () => {
        const valueAsMap = this.selectors.valueAsMapSelector();
        this.setState({editedValue: new Map(valueAsMap)});
    };

    handleHideEditor = () => {
        this.setState({editedValue: undefined});
    };

    handleSaveChanges = () => {
        const {value, onChange} = this.props;
        const {editedValue} = this.state;
        this.setState({editedValue: undefined});

        if (Array.isArray(value)) {
            onChange([...editedValue.keys()]);
        } else {
            onChange(editedValue);
        }
    };

    handleChange = editedValue => {
        this.setState({editedValue});
    };

    handleExpandedChange = expanded => {
        this.setState({expanded});
    }
}

function renderNodes(renderedNodes, valueAsMap, nodes) {
    const additionalNodesToRender = [];

    for (const node of nodes) {
        if (valueAsMap.has(node.value)) {
            renderedNodes.push(<Value key={node.id} node={node} />);
        } else {
            additionalNodesToRender.push(...node.children);
        }
    }

    if (additionalNodesToRender.length > 0) {
        renderNodes(renderedNodes, valueAsMap, additionalNodesToRender);
    }
}
