import {removeUndefinedValues} from "~/util/misc";
import {convertMatchRequestToApi} from "~/util/convert-to-api";
import CandidateMatch from "~/entities/CandidateMatch";
import JobMatch from "~/entities/JobMatch";

export default function addMatchingCalls(Client) {
    Client.prototype.matchProfileToCandidatesV1 = function({
        candidateIndex,
        index,
        matchRequest,
        filterContext,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        conversionPreProcessor,
        _source,
        forcedFilters,
        aspectsSupportedForSearch,
    }) {
        const theIndex = index || candidateIndex;

        return this.post(
            `/hrmatching/match2candidates/${theIndex}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(
                    matchRequest,
                    filterContext || {},
                    theIndex,
                    language,
                    sortModeGroup,
                    forcedFilters,
                    aspectsSupportedForSearch
                ),
                from,
                size,
                _source,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(x =>
                convertCandidateApiMatch(conversionPreProcessor, x)
            );

            return {matches, metadata};
        });
    };

    Client.prototype.downloadProfileToCandidates = function({
        candidateIndex,
        matchRequest,
        filterContext,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        exportType,
        exportSize,
        forcedFilters,
        aspectsSupportedForSearch,
    }) {
        return this.post(
            `/hrmatching/match2candidates/${candidateIndex}/${exportType}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(
                    matchRequest,
                    filterContext || {},
                    candidateIndex,
                    language,
                    sortModeGroup,
                    forcedFilters,
                    aspectsSupportedForSearch
                ),
                from: 0,
                size: exportSize,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            }),
            {},
            {},
            {
                dontDecode: true,
                responseType: "arraybuffer",
            }
        );
    };

    Client.prototype.matchProfileToJobsV1 = function({
        jobIndex,
        index,
        matchRequest,
        filterContext,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        conversionPreProcessor,
        _source,
        forcedFilters,
        aspectsSupportedForSearch,
    }) {
        const theIndex = index || jobIndex;

        return this.post(
            `/hrmatching/match2jobs/${theIndex}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(
                    matchRequest,
                    filterContext || {},
                    theIndex,
                    language,
                    sortModeGroup,
                    forcedFilters,
                    aspectsSupportedForSearch
                ),
                from,
                size,
                _source,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(x =>
                convertJobApiMatch(conversionPreProcessor, x)
            );

            return {matches, metadata};
        });
    };

    Client.prototype.matchDetailsForJobToCandidate = function(
        sourceIndex,
        sourceId,
        targetIndex,
        targetId,
        language, // TODO: Currently ignored
        matchingStrategyName, // TODO: Currently ignored
    ) {
        return this.post("/hrmatching/match2candidates/details", {
            sourceIndex,
            sourceId,
            targetIndex,
            targetId,
        }).then(({response}) =>
            response.matches.map(match => convertCandidateApiMatch(undefined, match))
        );
    };
}

export function convertCandidateApiMatch(preprocessor, match) {
    if (preprocessor && match.document) {
        match.document = preprocessor(match.document);
    }

    return new CandidateMatch(match);
}

export function convertJobApiMatch(preprocessor, match) {
    if (preprocessor && match.document) {
        match.document = preprocessor(match.document);
    }

    return new JobMatch(match);
}
