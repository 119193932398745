import moment from "moment";
import config from "~/config";
import {isString} from "~/util/misc";

const MOMENT_TO_UTS35_REPLACEMENTS = {
    YYYY: "yyyy",
    DD: "dd",
};

const MOMENT_TO_UTS35_REGEX = new RegExp(Object.keys(MOMENT_TO_UTS35_REPLACEMENTS).join("|"), "g");

export function formatIfDatetime(input) {
    if (!isString(input)) {
        return input;
    }

    let result = formatIfMatches(
        input,
        [
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DDTHH:mm:ss",
            "YYYY-MM-DDTHH:mm:ssZ",
            "YYYY-MM-DDTHH:mm:ss.SSS",
            "YYYY-MM-DDTHH:mm:ss.SSSZ",
        ],
        config("ui.dateTimeFormat")
    );

    if (result.formatted) {
        return result.value;
    }

    result = formatIfMatches(input, ["YYYY-MM-DD"], config("ui.dateFormat"));

    if (result.formatted) {
        return result.value;
    }

    result = formatIfMatches(input, ["HH:mm:ss"], config("ui.timeFormat"));
    return result.value;
}

function formatIfMatches(input, inputFormats, outputFormat) {
    const mom = moment(input, inputFormats, true);

    if (mom.isValid()) {
        return {formatted: true, value: mom.format(outputFormat)};
    } else {
        return {formatted: false, value: input};
    }
}

export function momentFormatToUTS35(format) {
    // TODO: Full support, we only support specific cases right now

    return format.replace(MOMENT_TO_UTS35_REGEX, function(matched) {
        return MOMENT_TO_UTS35_REPLACEMENTS[matched];
    });
}
