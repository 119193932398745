import React from "react";
import PropTypes from "prop-types";
import {createSelector} from "reselect";
import PredicateEditorPropTypes from "../prop-types";
import PROPERTY_TYPES from "../property-types";
import Select from "~/components/Select";
import styles from "./styles.module.scss";
import RemoveButton from "~/components/PredicateEditor/RemoveButton";
import {compareOptionsByLabel} from "~/components/PredicateEditor/AddGroupOrRule/AddGroupOrRule";

export default class Rule extends React.PureComponent {
    static propTypes = {
        rule: PredicateEditorPropTypes.rule.isRequired,
        propertyDefinitions: PredicateEditorPropTypes.propertyDefinitions.isRequired,
        overlappingRules: PropTypes.arrayOf(PredicateEditorPropTypes.rule).isRequired,
        activeFilters: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.propertyOptionsSelector = createPropertyOptionsSelector();
    }

    render() {
        return (
            <div className={styles.rule}>
                {this.renderProperty()}
                {this.renderValue()}
                <RemoveButton onClick={this.handleRemove} />
            </div>
        );
    }

    renderProperty() {
        const {rule, propertyDefinitions} = this.props;
        const options = this.propertyOptionsSelector(propertyDefinitions);

        return (
            <Select
                selected={rule.property}
                onChange={this.handlePropertyChange}
                options={options}
                variant="extra-small"
                className={styles.propertySelect}
            />
        );
    }

    renderValue() {
        const {rule, propertyDefinitions, overlappingRules, activeFilters} = this.props;
        const propertyDefintion = propertyDefinitions[rule.property];
        const Property = PROPERTY_TYPES[propertyDefintion.type];

        return (
            <Property
                data={rule.data}
                propertyDefinition={propertyDefintion}
                overlappingData={overlappingRules.map(x => x.data)}
                activeFilters={activeFilters}
                onChange={this.handleDataChange}
            />
        );
    }

    handlePropertyChange = option => {
        const {rule, propertyDefinitions, onChange} = this.props;
        const nextProperty = option.value;
        let nextData = rule.data;

        if (
            propertyDefinitions[nextProperty].type !== propertyDefinitions[rule.property].type ||
            propertyDefinitions[rule.property].type === "enum"
        ) {
            // TODO: Check if some types have compatible data, in which case we wouldn't need to reset
            const propertyDefinition = propertyDefinitions[nextProperty];
            const PropertyType = PROPERTY_TYPES[propertyDefinition.type];
            nextData = PropertyType.defaultData(propertyDefinition);
        }

        const nextRule = {...rule, property: nextProperty, data: nextData};
        onChange(nextRule);
    };

    handleDataChange = nextData => {
        const {rule, onChange} = this.props;
        const nextRule = {...rule, data: nextData};
        onChange(nextRule);
    };

    handleRemove = () => {
        const {rule, onRemove} = this.props;
        onRemove(rule);
    };
}

function createPropertyOptionsSelector() {
    return createSelector(
        propertyDefinitions => propertyDefinitions,
        propertyDefinitions => {
            return Object.keys(propertyDefinitions).map(name => ({
                value: name,
                label: propertyDefinitions[name].label,
            })).sort(compareOptionsByLabel);
        }
    );
}
