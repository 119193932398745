import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import escapeStringRegexp from "escape-string-regexp";
import Select from "~/components/Select";
import FormControl from "react-bootstrap/FormControl";
import styles from "./styles.module.scss";

const COMPARISONS = {
    startsWith: {
        label: {id: "predicateEditor.startsWith"},
        hasInput: true,
        toFilter: (property, input) => {
            return {regexp: {[property]: toStartsWithRegex(input)}};
        },
    },
    contains: {
        label: {id: "predicateEditor.contains"},
        hasInput: true,
        toFilter: (property, input) => {
            return {regexp: {[property]: toContainsRegex(input)}};
        },
    },
    doesntContain: {
        label: {id: "predicateEditor.doesntContain"},
        hasInput: true,
        toFilter: (property, input) => {
            return {
                bool: {
                    must_not: [{regexp: {[property]: toContainsRegex(input)}}],
                },
            };
        },
    },
    equals: {
        label: {id: "predicateEditor.equals"},
        hasInput: true,
        toFilter: (property, input) => {
            return {regexp: {[property]: toEqualsRegex(input)}};
        },
    },
    doesntEqual: {
        label: {id: "predicateEditor.doesntEqual"},
        hasInput: true,
        toFilter: (property, input) => {
            return {
                bool: {
                    must_not: [{regexp: {[property]: toEqualsRegex(input)}}],
                },
            };
        },
    },
    isEmpty: {
        label: {id: "predicateEditor.isEmpty"},
        hasInput: false,
        toFilter: property => {
            return {
                bool: {
                    should: [
                        {bool: {must_not: {exists: {field: property}}}},
                        {term: {[property]: ""}},
                    ],
                },
            };
        },
    },
    isNotEmpty: {
        label: {id: "predicateEditor.isNotEmpty"},
        hasInput: false,
        toFilter: property => {
            return {
                bool: {
                    must: [{exists: {field: property}}],
                    must_not: [{term: {[property]: ""}}],
                },
            };
        },
    },
};

export default class KeywordProperty extends React.PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            comparison: PropTypes.oneOf(Object.keys(COMPARISONS)),
            input: PropTypes.string.isRequired,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultData() {
        return {
            comparison: "contains",
            input: "",
        };
    }

    static toFilter(property, data) {
        const comparisonInfo = COMPARISONS[data.comparison];

        if (data.input !== "" || !comparisonInfo.hasInput) {
            return comparisonInfo.toFilter(property, data.input);
        } else {
            return undefined;
        }
    }

    render() {
        const {data} = this.props;
        const comparisonInfo = COMPARISONS[data.comparison];

        return (
            <React.Fragment>
                <Select
                    className={c(styles.givePriority, styles.comparisonSelect)}
                    selected={data.comparison}
                    onChange={this.handleComparisonChange}
                    options={COMPARISON_OPTIONS}
                    variant="extra-small"
                />
                {comparisonInfo.hasInput && (
                    <FormControl
                        className={c(styles.givePriority, styles.input)}
                        value={data.input}
                        onChange={this.handleInputChange}
                        size="sm"
                    />
                )}
            </React.Fragment>
        );
    }

    handleComparisonChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, comparison: option.value};
        onChange(nextData);
    };

    handleInputChange = e => {
        const {data, onChange} = this.props;
        const nextData = {...data, input: e.target.value};
        onChange(nextData);
    };
}

const COMPARISON_OPTIONS = Object.keys(COMPARISONS).map(key => ({
    label: COMPARISONS[key].label,
    value: key,
}));

function toCaseInsensitive(input) {
    return [...input]
        .map(char => {
            const lowercase = char.toLowerCase();
            const uppercase = char.toUpperCase();

            if (lowercase !== uppercase) {
                return `[${lowercase}${uppercase}]`;
            } else {
                return escapeStringRegexp(char);
            }
        })
        .join("");
}

function toStartsWithRegex(input) {
    const caseInsensitiveInput = toCaseInsensitive(input);
    return `${caseInsensitiveInput}.*`;
}

function toContainsRegex(input) {
    const caseInsensitiveInput = toCaseInsensitive(input);
    return `.*${caseInsensitiveInput}.*`;
}

function toEqualsRegex(input) {
    const caseInsensitiveInput = toCaseInsensitive(input);
    return `${caseInsensitiveInput}`;
}
