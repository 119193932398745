import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import uuid from "uuid/v4";
import PredicateEditorPropTypes from "../prop-types";
import {isGroup} from "../util";
import Rule from "../Rule";
import Select from "~/components/Select";
import AddGroupOrRule from "../AddGroupOrRule";
import styles from "./styles.module.scss";
import PROPERTY_TYPES from "../property-types";
import RemoveButton from "../RemoveButton";
import {FormattedMessage} from "react-intl";

export default class Group extends React.PureComponent {
    static propTypes = {
        group: PredicateEditorPropTypes.group.isRequired,
        depth: PropTypes.number.isRequired,
        maximumDepth: PropTypes.number.isRequired,
        propertyDefinitions: PredicateEditorPropTypes.propertyDefinitions.isRequired,
        isRoot: PropTypes.bool.isRequired,
        activeFilters: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isRoot: false,
        onRemove: () => {},
    };

    render() {
        const {isRoot} = this.props;

        return (
            <div className={c(styles.group, {[styles.root]: isRoot})}>
                {this.renderCombinator()}
                {this.renderRules()}
            </div>
        );
    }

    renderCombinator() {
        const {group, isRoot} = this.props;

        return (
            <div className={styles.combinator}>
                <Select
                    selected={group.combinator}
                    onChange={this.handleCombinatorChange}
                    options={COMBINATOR_OPTIONS}
                    variant="extra-small"
                    className={styles.select}
                />
                <div>
                    <FormattedMessage id="predicateEditor.groupDescription" />:
                </div>
                {!isRoot && (
                    <RemoveButton className={styles.remove} onClick={this.handleRemoveSelf} />
                )}
            </div>
        );
    }

    renderRules() {
        const {group, depth, maximumDepth, propertyDefinitions} = this.props;
        const rulesInGroup = group.rules.filter(r => !isGroup(r));

        return (
            <div className={styles.rules}>
                {group.rules.map(rule => this.renderRule(rule, rulesInGroup))}
                <AddGroupOrRule
                    canAddGroup={depth < maximumDepth}
                    propertyDefinitions={propertyDefinitions}
                    onAddGroup={this.handleAddGroup}
                    onAddRule={this.handleAddRule}
                />
            </div>
        );
    }

    renderRule = (rule, rulesInGroup) => {
        const {depth, maximumDepth, propertyDefinitions, activeFilters} = this.props;

        if (isGroup(rule)) {
            return (
                <Group
                    key={rule.key}
                    group={rule}
                    depth={depth + 1}
                    maximumDepth={maximumDepth}
                    propertyDefinitions={propertyDefinitions}
                    activeFilters={activeFilters}
                    onChange={this.handleRuleChange}
                    onRemove={this.handleRemoveRule}
                />
            );
        } else if (propertyDefinitions[rule.property] === undefined) {
            console.warn("Invalid rule in predicate!", rule.property);
            // Old rule, could come from localStorage
            return null;
        } else {
            return (
                <Rule
                    key={rule.key}
                    rule={rule}
                    propertyDefinitions={propertyDefinitions}
                    overlappingRules={getOverlappingRules(rule, rulesInGroup)}
                    activeFilters={activeFilters}
                    onChange={this.handleRuleChange}
                    onRemove={this.handleRemoveRule}
                />
            );
        }
    };

    handleCombinatorChange = option => {
        const {group, onChange} = this.props;
        const nextGroup = {...group, combinator: option.value};
        onChange(nextGroup);
    };

    handleRemoveSelf = () => {
        const {group, onRemove} = this.props;
        onRemove(group);
    };

    handleRuleChange = changedRule => {
        const {group, onChange} = this.props;
        const nextGroup = {...group};

        nextGroup.rules = group.rules.map(rule => {
            return rule.key === changedRule.key ? changedRule : rule;
        });

        onChange(nextGroup);
    };

    handleAddGroup = combinator => {
        const {group, onChange} = this.props;
        const nextGroup = {...group, rules: [...group.rules, {combinator, rules: [], key: uuid()}]};
        onChange(nextGroup);
    };

    handleAddRule = property => {
        const {group, propertyDefinitions, onChange} = this.props;
        const propertyDefintion = propertyDefinitions[property];
        const PropertyType = PROPERTY_TYPES[propertyDefintion.type];
        const nextGroup = {
            ...group,
            rules: [
                ...group.rules,
                {property, data: PropertyType.defaultData(propertyDefintion), key: uuid()},
            ],
        };
        onChange(nextGroup);
    };

    handleRemoveRule = rule => {
        const {group, onChange} = this.props;
        const nextGroup = {...group, rules: group.rules.filter(r => r.key !== rule.key)};
        onChange(nextGroup);
    };
}

function getOverlappingRules(rule, rulesInGroup) {
    return rulesInGroup.filter(r => r !== rule && r.property === rule.property);
}

const COMBINATOR_OPTIONS = [
    {label: {id: "predicateEditor.all"}, value: "and"},
    {label: {id: "predicateEditor.any"}, value: "or"},
];
