import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Value from "./Value";
import styles from "./styles.module.scss";

export default class ObjectValue extends React.PureComponent {
    static propTypes = {
        value: PropTypes.any,
    };

    render() {
        const {value} = this.props;

        return (
            <Table bordered={true} className={styles.objectValue} size="sm">
                <tbody>
                    {Object.keys(value).map(key => (
                        <tr key={key}>
                            <td className={styles.label}>{key}</td>
                            <td style={{width: "100%"}}>
                                <Value value={value[key]} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}
