import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class InstanceOfValidator extends Validator {
    constructor(description, objectType) {
        super(description, undefined);
        this.objectType = objectType;
    }
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (value instanceof this.objectType) {
            return value;
        } else {
            throw new ValidationError(path, `Expected an instance of ${this.objectType}, but got instance of ${betterTypeOf(value)}`);
        }
    }
}
