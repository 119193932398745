import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Tabs} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import styles from "./styles.module.scss";
import {I18N} from "~/util/localization";
import {FormattedMessage} from "react-intl";
import BasicSpinner from "~/components/BasicPageLayout/BasicSpinner";
import Api from "~/api";
import ProcessButton from "~/components/ProcessButton";
import ErrorMessage from "~/entities/ErrorMessage";

export default class SendEmailDialog extends React.PureComponent {
    static propTypes = {
        jobIds: PropTypes.array.isRequired,
        candidateIds: PropTypes.array.isRequired,
        getTemplatesEndpoint: PropTypes.string.isRequired,
        sendEmailsEndpoint: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isSendingEmails: false,
            activeTemplateId: "__dummy__",
            templates: [
                {
                    id: "__dummy__",
                    name: {
                        UNIVERSAL: "Dummy",
                    },
                    content: {
                        DUT: {
                            title: "",
                            body: "",
                        },
                    },
                    placeholders: [],
                },
            ],
            activeContentKey: "DUT",
            content: {
                DUT: {
                    title: "",
                    body: "",
                },
            },
        };

        this.unmounted = false;
    }

    componentDidMount() {
        Api.get(this.props.getTemplatesEndpoint).then(({response}) => {
            if (this.unmounted) return;

            const templatesWithId = [];
            let firstId = undefined;
            let content = this.state.content;
            let activeContentKey = "DUT";

            for (const template of response.templates) {
                const id = uuid();

                if (firstId === undefined) {
                    firstId = id;
                    content = template.content;
                    const contentKeys = Object.keys(content);

                    if (!contentKeys.includes("DUT")) {
                        activeContentKey = contentKeys.length > 0 ? contentKeys[0] : "DUT";
                    }
                }

                templatesWithId.push({...template, id});
            }

            this.setState({
                isLoading: false,
                activeTemplateId: firstId,
                templates: templatesWithId,
                activeContentKey,
                content,
            });
        });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const {isLoading, isSendingEmails} = this.state;

        return (
            <Modal show={true} size="lg" onHide={this.handleCancel}>
                <Modal.Header closeButton={!isSendingEmails}>
                    <Modal.Title>
                        <FormattedMessage id="sendEmail.dialogTitle" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.handleCancel}
                        disabled={isSendingEmails}
                    >
                        <FormattedMessage id="action.cancel" />
                    </Button>
                    <ProcessButton
                        processing={isSendingEmails}
                        label={<FormattedMessage id="action.send" />}
                        variant="success"
                        onClick={this.handleSend}
                        disabled={isLoading}
                    />
                </Modal.Footer>
            </Modal>
        );
    }

    renderBody() {
        const {isLoading, templates, content, activeContentKey} = this.state;

        return (
            <div className="form-horizontal">
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="sendEmail.template" />
                    </Form.Label>
                    <Form.Control as="select" onChange={this.handleTemplateChange}>
                        {templates.map(this.renderOption)}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        <FormattedMessage id="sendEmail.templateWarning" />
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="sendEmail.emailContent" />
                    </Form.Label>
                    <div className={styles.tabs}>
                        <Tabs
                            activeKey={activeContentKey}
                            onSelect={this.handleActiveContentKeyChange}
                            id="uncontrolled-tab-example"
                        >
                            {Object.entries(content)
                                .sort()
                                .map(([language, value]) => {
                                    return this.renderTab(language, value);
                                })}
                        </Tabs>
                    </div>
                </Form.Group>
                {isLoading && (
                    <div className={styles.loadingOverlay}>
                        <BasicSpinner />
                    </div>
                )}
            </div>
        );
    }

    renderOption = template => {
        return (
            <option key={template.id} value={template.id}>
                {template.name.UNIVERSAL}
            </option>
        );
    };

    renderTab(language, content) {
        return (
            <Tab
                key={language}
                eventKey={language}
                title={I18N.intl.formatMessage({id: `language.${language}`})}
            >
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="sendEmail.subject" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Normal text"
                        value={content.title}
                        onChange={this.handleTitleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="sendEmail.body" />
                    </Form.Label>
                    <Form.Control
                        placeholder="Normal text"
                        as="textarea"
                        rows={10}
                        value={content.body}
                        onChange={this.handleBodyChange}
                    />
                </Form.Group>
            </Tab>
        );
    }

    handleTemplateChange = e => {
        const templateId = e.target.value;
        const template = this.findTemplate(templateId);

        if (template !== undefined) {
            this.setState({
                activeContentKey: "DUT",
                content: template.content,
            });
        }
    };

    findTemplate(id) {
        for (const template of this.state.templates) {
            if (template.id === id) {
                return template;
            }
        }

        return undefined;
    }

    handleActiveContentKeyChange = activeContentKey => {
        this.setState({activeContentKey});
    };

    handleTitleChange = e => {
        const title = e.target.value;

        this.setState(state => {
            return {
                content: {
                    ...state.content,
                    [state.activeContentKey]: {
                        ...state.content[state.activeContentKey],
                        title,
                    },
                },
            };
        });
    };

    handleBodyChange = e => {
        const body = e.target.value;

        this.setState(state => {
            return {
                content: {
                    ...state.content,
                    [state.activeContentKey]: {
                        ...state.content[state.activeContentKey],
                        body,
                    },
                },
            };
        });
    };

    handleCancel = () => {
        this.props.onClose();
    };

    handleSend = () => {
        const {jobIds, candidateIds, sendEmailsEndpoint, onClose} = this.props;
        const {content} = this.state;

        this.setState({isSendingEmails: true});

        Api.post(sendEmailsEndpoint, {jobIds, candidateIds, content})
            .then(() => {
                this.setState({isSendingEmails: false});
                onClose();
            })
            .catch(e => {
                ErrorMessage.trigger(
                    "error.title.cantSendEmails",
                    "error.message.cantSendEmails",
                    e.response || e.message,
                    ErrorMessage.CLOSABLE_SOMETIMES
                );

                this.setState({isSendingEmails: false});
            });
    };
}
