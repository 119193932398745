import PropTypes from "prop-types";

export default PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({
        function: PropTypes.func.isRequired,
        arguments: PropTypes.object,
    })
]);
