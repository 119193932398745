import React from "react";
import c from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import BasicPageLayout from "~/components/BasicPageLayout";
import {Status} from "~/reducers/matching-strategy";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Select from "~/components/Select";
import styles from "./styles.module.scss";
import OverviewTable from "./OverviewTable";
import DetailsTable from "~/pages/MatchingTest/DetailsTable";
import {percentageString} from "~/util/misc";
import ProfileType from "~/enums/ProfileType";
import ProcessButton from "~/components/ProcessButton";
import MatchingStrategySelect from "~/containers/components/MatchingStrategySelect";
import Form from "react-bootstrap/Form";

class MatchingTest extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        status: PropTypes.string.isRequired,
        isMatching: PropTypes.bool.isRequired,
        selection: PropTypes.object.isRequired,
        globalStats: PropTypes.object.isRequired,
        selectedStrategy: PropTypes.string,
        strategyOptions: PropTypes.array.isRequired,
        selectedProfile: PropTypes.string,
        allProfiles: PropTypes.object.isRequired,
        orderedProfiles: PropTypes.array.isRequired,
        feedbackKeysOptions: PropTypes.array.isRequired,
        feedbackToTypesOptions: PropTypes.array.isRequired,
        initialize: PropTypes.func.isRequired,
        selectFeedbackFromType: PropTypes.func.isRequired,
        selectFeedbackToType: PropTypes.func.isRequired,
        selectFeedbackKey: PropTypes.func.isRequired,
        selectMatchingStrategy: PropTypes.func.isRequired,
        selectProfile: PropTypes.func.isRequired,
        updateFeedbackDecision: PropTypes.func.isRequired,
        match: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {status, initialize} = this.props;
        if (status === Status.UNINITIALIZED) initialize();
    }

    render() {
        return <BasicPageLayout>{this.renderPage()}</BasicPageLayout>;
    }

    renderPage() {
        const {
            intl,
            status,
            isMatching,
            selectedProfile,
            orderedProfiles,
            selectProfile,
            selection,
            globalStats,
            feedbackKeysOptions,
        } = this.props;

        if (status !== Status.INITIALIZED) return null;

        return (
            <Container className={styles.page}>
                <div className={c(styles.container, "pt-4", "pb-4")}>
                    <div className="d-flex align-items-end">
                        <Form.Group className="mb-0">
                            <Form.Label className="text-muted d-block mb-1">
                                <FormattedMessage id="matchingTest.selection.feedbackFromType" />
                            </Form.Label>
                            <Select
                                className={styles.select}
                                selected={selection.feedbackFromType}
                                options={ProfileType.selectOptions(intl)}
                                onChange={this.handleSelectfeedbackFromType}
                            />
                        </Form.Group>
                        <Form.Group className="ml-3 mb-0">
                            <Form.Label className="text-muted d-block mb-1">
                                <FormattedMessage id="matchingTest.selection.feedbackToType" />
                            </Form.Label>
                            <Select
                                className={styles.select}
                                selected={selection.feedbackToType}
                                options={ProfileType.selectOptions(intl)}
                                onChange={this.handleSelectfeedbackToType}
                            />
                        </Form.Group>
                        <Form.Group className="ml-3 mb-0">
                            <Form.Label className="text-muted d-block mb-1">
                                <FormattedMessage id="matchingTest.selection.feedbackKey" />
                            </Form.Label>
                            <Select
                                className={styles.select}
                                selected={selection.feedbackKey}
                                options={feedbackKeysOptions}
                                onChange={this.handleSelectFeedbackKey}
                            />
                        </Form.Group>
                        <Form.Group className="ml-3 mb-0">
                            <Form.Label className="text-muted d-block mb-1">
                                <FormattedMessage id="matchingTest.selection.matchingStrategy" />
                            </Form.Label>
                            <MatchingStrategySelect
                                className={styles.select}
                                selected={selection.matchingStrategy}
                                allowEmpty={true}
                                onChange={this.handleSelectMatchingStrategy}
                            />
                        </Form.Group>
                        <ProcessButton
                            label={<FormattedMessage id="matchingTest.startTest" />}
                            processingLabel={<FormattedMessage id="matchingTest.testing" />}
                            processing={isMatching}
                            variant="success"
                            className="ml-3"
                            onClick={this.handleMatchClick}
                        />
                    </div>
                    <div className="mt-4 d-flex align-items-end">
                        <div>
                            <h3>
                                <FormattedMessage id="matchingTest.resultsTitle" />
                            </h3>
                            <p className="text-muted">
                                <em>
                                    <FormattedMessage id="matchingTest.resultsDescription" />
                                </em>
                            </p>
                        </div>
                        <div className="ml-auto mb-3">
                            <table className="text-muted text-right">
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormattedMessage id="matchingTest.generalPrecision" />:
                                        </td>
                                        <td>
                                            {globalStats.precision !== undefined
                                                ? percentageString(globalStats.precision)
                                                : "—"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage id="matchingTest.generalRecall" />:
                                        </td>
                                        <td>
                                            {globalStats.recall !== undefined
                                                ? percentageString(globalStats.recall)
                                                : "—"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={styles.overviewTable}>
                        <OverviewTable
                            selectedProfile={selectedProfile}
                            orderedProfiles={orderedProfiles}
                            onSelect={selectProfile}
                        />
                    </div>
                    {this.renderDetailsTable()}
                </div>
            </Container>
        );
    }

    renderDetailsTable() {
        const {selectedProfile, allProfiles} = this.props;

        if (!selectedProfile) {
            return null;
        }

        return (
            <React.Fragment>
                <h4 className="mt-3">
                    <FormattedMessage id="matchingTest.detailsTitle" />
                </h4>
                <div className={styles.detailsTable}>
                    <DetailsTable
                        profile={allProfiles[selectedProfile]}
                        onDecisionChange={this.handleDecisionChange}
                    />
                </div>
            </React.Fragment>
        );
    }

    handleSelectfeedbackFromType = option => {
        this.props.selectFeedbackFromType(option.value);
    };

    handleSelectfeedbackToType = option => {
        this.props.selectFeedbackToType(option.value);
    };

    handleSelectFeedbackKey = option => {
        this.props.selectFeedbackKey(option.value);
    };

    handleSelectMatchingStrategy = matchingStrategy => {
        this.props.selectMatchingStrategy(matchingStrategy);
    };

    handleDecisionChange = (feedbackId, decision) => {
        const {selectedProfile, updateFeedbackDecision} = this.props;
        updateFeedbackDecision(selectedProfile, feedbackId, decision);
    };

    handleMatchClick = () => {
        this.props.match();
    };
}

export default injectIntl(MatchingTest, {forwardRef: true});
