import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {percentageString} from "~/util/misc";
import styles from "./styles.module.scss";
import {getProfileDisplayId, getProfileLabel} from "../util";

export default class Row extends React.PureComponent {
    static propTypes = {
        value: PropTypes.object.isRequired,
        selected: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {value, selected} = this.props;

        return (
            <tr className={c({[styles.selected]: selected})} onClick={this.handleClick}>
                <td>
                    <div className="d-flex align-items-center">
                        <div>
                            <div>{getProfileLabel(value)}</div>
                            <small className="text-muted d-block text-monospace">
                                {getProfileDisplayId(value)}
                            </small>
                        </div>
                    </div>
                </td>
                {this.renderStats()}
            </tr>
        );
    }

    renderStats() {
        const {stats} = this.props.value;

        if (stats !== undefined) {
            return (
                <React.Fragment>
                    <td>{stats.hits}</td>
                    <td>{stats.falsePositives}</td>
                    <td>{stats.missed}</td>
                    <td className={colorClassFor(stats.precision)}>{percentageString(stats.precision)}</td>
                    <td className={colorClassFor(stats.recall)}>{percentageString(stats.recall)}</td>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <td>—</td>
                    <td>—</td>
                    <td>—</td>
                    <td>—</td>
                    <td>—</td>
                </React.Fragment>
            );
        }
    }

    handleClick = () => {
        const {value, onClick} = this.props;
        onClick(value.id);
    };
}

const RANGE_COLOR_CLASSES = [
    {from: 0.85, className: "text-success"},
    {from: 0.75, className: "text-warning"},
    {from: undefined, className: "text-danger"},
];

function colorClassFor(value) {
    for (const range of RANGE_COLOR_CLASSES) {
        if (value >= range.from || range.from === undefined) {
            return range.className;
        }
    }

    return undefined;
}
