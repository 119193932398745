import React from "react";
import PropTypes from "prop-types";
import PredicateEditorPropTypes from "./prop-types";
import Group from "./Group";
import {removeInvalidRules} from "~/components/PredicateEditor/util";

export default class PredicateEditor extends React.PureComponent {
    static propTypes = {
        value: PredicateEditorPropTypes.group.isRequired,
        maximumDepth: PropTypes.number.isRequired,
        propertyDefinitions: PredicateEditorPropTypes.propertyDefinitions.isRequired,
        activeFilters: PropTypes.object,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        maximumDepth: 1,
    };

    render() {
        const {value, maximumDepth, propertyDefinitions, activeFilters, onChange} = this.props;
        const cleanedPropertyDefintions = removeInvalidAndDisabledPropertyDefinitions(propertyDefinitions);

        return (
            <Group
                group={removeInvalidRules(value, cleanedPropertyDefintions)}
                depth={1}
                maximumDepth={maximumDepth}
                propertyDefinitions={cleanedPropertyDefintions}
                isRoot={true}
                activeFilters={activeFilters}
                onChange={onChange}
            />
        );
    }
}

function removeInvalidAndDisabledPropertyDefinitions(propertyDefinitions) {
    const result = {...propertyDefinitions};

    for (const key in propertyDefinitions) {
        if (propertyDefinitions[key].label === undefined) {
            console.error(`Property defintion ${key} is invalid, removed`);
            delete result[key];
        } else if (propertyDefinitions[key].disabled === true) {
            delete result[key];
        }
    }

    return result;
}
