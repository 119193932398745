import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import MatchList from "~/components/MatchList";
import styles from "./job-match-list.module.scss";
import MatchActions from "./MatchActions";
import Button from "react-bootstrap/Button";
import {getJobFunctionLabel} from "~/components/CandidateMatchList/CandidateMatchList";
import config from "~/config";
import PhoneNumber from "~/components/PhoneNumber";

export default class JobMatchList extends React.PureComponent {
    static propTypes = {
        ...MatchList.propTypes,
        indexName: PropTypes.string.isRequired,
        extraPropertyPhoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
        disableWhatsappButton: PropTypes.bool.isRequired,
        onMatch: PropTypes.func,
        onVisitExternal: PropTypes.func,

        // Disable some things from MatchList we provide ourselves
        renderDetailsColumnHeader: PropTypes.any,
        renderDetailsColumn: PropTypes.any,
        renderMatchActions: PropTypes.any,
        extraColumnPropertyRoot: PropTypes.any,
    };

    static defaultProps = {
        allowSelection: false,
    };

    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
    }

    scrollToTop() {
        this.mainRef.current.scrollToTop();
    }

    render() {
        const {
            indexName,
            extraPropertyPhoneNumbers,
            onMatch,
            onVisitExternal,
            ...matchingListProperties
        } = this.props;

        return (
            <MatchList
                ref={this.mainRef}
                {...matchingListProperties}
                renderDetailsColumnHeader={renderDetailsColumnHeader}
                renderDetailsColumn={renderDetailsColumn}
                renderMatchActions={this.renderMatchActions}
            />
        );
    }

    renderMatchActions = match => {
        const {indexName, onMatch, onVisitExternal} = this.props;

        return (
            <React.Fragment>
                {!!onMatch && (
                    <MatchActions indexName={indexName} match={match} onMatch={onMatch} />
                )}
                {this.renderPhoneButtons(match)}
                {!!onVisitExternal && (
                    <Button
                        size="sm"
                        variant="info"
                        onClick={this.handleVisitExternal.bind(this, match)}
                        className="ml-1"
                    >
                        <i className="fas fa-external-link-alt" />
                    </Button>
                )}
            </React.Fragment>
        );
    };

    renderPhoneButtons(match) {
        const {extraPropertyPhoneNumbers, disableWhatsappButton} = this.props;

        if (!extraPropertyPhoneNumbers || !config("ui.phoneButtonsInResultList")) {
            return;
        }

        const extraProperties = match.document.extraProperties;
        let phoneNumber = undefined;

        for (const property of extraPropertyPhoneNumbers) {
            if (extraProperties[property]) {
                phoneNumber = extraProperties[property];
                break;
            }
        }

        if (!phoneNumber) {
            return;
        }

        return <PhoneNumber value={phoneNumber} onlyButtons={true} disableWhatsappButton={disableWhatsappButton} />;
    }

    handleVisitExternal = (match, e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onVisitExternal(match.id);
    };
}

function renderDetailsColumnHeader() {
    return (
        <div>
            <FormattedMessage id="column.jobTitleFunction" />
        </div>
    );
}

function renderDetailsColumn(match) {
    const jobFunction = getJobFunctionLabel(match.job.matchProfile.aspects.jobFunctions);

    const shouldShowTitle = match.job.title;
    const shouldShowFunction = jobFunction && (!shouldShowTitle || !config("ui.onlyShowJobTitleInMatches"));

    return (
        <React.Fragment>
            {shouldShowTitle && (
                <div className={styles.ellipsis} title={match.job.title}>
                    {match.job.title}
                </div>
            )}
            {shouldShowFunction && (
                <div className={styles.ellipsis} title={jobFunction}>
                    {jobFunction}
                </div>
            )}
            {!shouldShowTitle && !shouldShowFunction && <div>—</div>}
        </React.Fragment>
    );
}

export function getNameForJobMatch(match) {
    return match.job.title || getJobFunctionLabel(match.job.matchProfile.aspects.jobFunctions);
}
