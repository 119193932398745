import Validators from "~/config/validation/validators/Validators";
import IndexDefinition from "~/config/definitions/misc/IndexDefinition";

export default Validators.shape({
    description: "Configuration for a matching page",
    shape: {
        enabled: Validators.boolean({
            description: "If true, this matching page is enabled",
            defaultValue: false,
        }),
        keywordSearch: Validators.boolean({
            description:
                "Displays the keyword search bar above the profile editor, and the Match button moves to the keyword search bar",
            defaultValue: false,
        }),
        keywordSuggestions: Validators.boolean({
            description:
                "Enables keyword suggestions (that is, value suggestions for certain aspects based on existing values)",
            defaultValue: false,
        }),
        indices: Validators.arrayOf({
            description: "The indices to match against.",
            items: IndexDefinition,
        }),
        placeholderTabs: Validators.arrayOf({
            description: "Placeholder tabs",
            items: Validators.shape({
                description: "Placeholder tab",
                shape: {
                    name: Validators.string({
                        description: "name of the placeholder",
                    }).allowsUndefined,
                    label: Validators.string({
                        description: "label of the placeholder",
                    }).allowsUndefined,
                    content: Validators.string({
                        description: "content of the placeholder",
                    }).allowsUndefined,
                },
            }),
        }),
    },
});
