import React from "react";
import PropTypes from "prop-types";

export default class ValidatedInput extends React.PureComponent {
    static propTypes = {
        value: PropTypes.any,
        defaultValue: PropTypes.any,
        valueToInput: PropTypes.func.isRequired,
        inputToValue: PropTypes.func.isRequired,
        isValidAsInput: PropTypes.func.isRequired,
        isValidForConversion: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onReturnPress: PropTypes.func,
        // NOTE: all normal <input/> props are also accepted
    };

    constructor(props) {
        super(props);

        this.state = {
            lastPropValue: undefined,
            input: "",
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.lastPropValue !== props.value) {
            return {
                lastPropValue: props.value,
                input: props.valueToInput(props.value),
            };
        } else {
            return null;
        }
    }

    render() {
        const {input} = this.state;

        return (
            <input
                {...toInputProps(this.props)}
                value={input}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onKeyUp={this.handleKeyUp}
            />
        );
    }

    handleChange = e => {
        const {isValidAsInput, isValidForConversion, inputToValue} = this.props;
        const input = e.target.value;

        if (isValidAsInput(input)) {
            this.setState({input});

            if (isValidForConversion(input)) {
                this.props.onChange(inputToValue(input));
            }
        } else {
            // Maintain caret position (caret still moves back on delete and
            // forward on insert, even if the delete or insert isn't accepted)
            // TODO: Needs work to feel more natural
            const caret = e.target.selectionStart;
            const element = e.target;

            window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
            });
        }
    };

    handleBlur = () => {
        const {value, defaultValue, isValidForConversion, valueToInput, onBlur} = this.props;
        const {input} = this.state;

        if (!isValidForConversion(input)) {
            const usedValue = value !== undefined && value !== null ? value : defaultValue;

            this.setState({
                lastPropValue: usedValue,
                input: valueToInput(usedValue),
            });

            this.props.onChange(usedValue);
        }

        if (onBlur) {
            onBlur();
        }
    };

    handleKeyUp = e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            const {onReturnPress} = this.props;
            if (onReturnPress) onReturnPress();
        }
    }
}

function toInputProps(props) {
    const inputProps = {...props};
    delete inputProps.defaultValue;
    delete inputProps.valueToInput;
    delete inputProps.inputToValue;
    delete inputProps.isValidAsInput;
    delete inputProps.isValidForConversion;
    delete inputProps.onChange;
    delete inputProps.onReturnPress;
    return inputProps;
}
