import React from "react";
import PropTypes from "prop-types";
import ProfileType from "~/enums/ProfileType";
import CandidateMatchList from "~/components/CandidateMatchList";
import JobMatchList from "~/components/JobMatchList";
import {hasEnabledFilters, isFunction} from "~/util/misc";
import styles from "./styles.module.scss";
import {createSelectors} from "~/components/MatchingPage/IndexResultsTab/selectors";
import MatchResultsPaginationBar from "~/components/MatchResultsPaginationBar";
import {FormattedMessage} from "react-intl";
import {getDynamicProperty, isGreenMatch} from "~/util/match";
import {getNameForJobMatch} from "~/components/JobMatchList/JobMatchList";
import MatchDetailsDialog from "~/components/MatchDetailsDialog";
import MatchComparisonDialog from "~/components/MatchComparisonDialog";
import SelectAllState from "~/enums/SelectAllState";
import config from "~/config";
import AdditionalActionButton from "./AdditionalActionButton";
import SendEmailDialog from "~/components/SendEmailDialog";

export default class IndexResultsTab extends React.PureComponent {
    static propTypes = {
        index: PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            createNoteCallback: PropTypes.func,
        }).isRequired,
        results: PropTypes.object,
        matchSourceEntity: PropTypes.object,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        onSelectionStatusChange: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onMatchToOthers: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            processingSelection: false, // TODO: This will need to go into global state
            selectingAll: false, // TODO: Might need to put into global state
            showSendEmails: false,
            displayedMatches: [],
        };

        this.selectors = createSelectors();
        this.matchListRef = React.createRef();
        this.unmounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.results.displayedPage !== this.props.results.displayedPage) {
            this.matchListRef.current.scrollToTop();
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const {index, results, onMatchToOthers} = this.props;

        const MatchListComponent = this.forResultType({
            [ProfileType.CANDIDATE]: () => CandidateMatchList,
            [ProfileType.JOB]: () => JobMatchList,
        });

        return (
            <div className={styles.resultsWrapper}>
                <MatchListComponent
                    indexName={index.name}
                    indexConfig={index}
                    ref={this.matchListRef}
                    className={styles.matchList}
                    matchInformationGetters={index.matchInformationGetters}
                    openExternalExistingSelection={index.openExternalExistingSelection}
                    allowSelection={index.allowSelection || index.allowComparison}
                    extraColumns={index.extraColumns}
                    hideDetailsColumn={index.hideDetailsColumn}
                    extraPropertyPhoneNumbers={index.extraPropertyPhoneNumbers}
                    disableWhatsappButton={
                        index.disableWhatsappButton || config("ui.disableWhatsappButton", false)
                    }
                    matches={this.selectors.displayedMatches(results)}
                    annotations={results.annotations}
                    selection={results.selection}
                    existingSelection={results.backendSelection.data}
                    selectAllState={this.getSelectAllState()}
                    isGreenMatch={this.isGreenMatch}
                    noResultsLabel={
                        !hasEnabledFilters(results.lastRequest.filters)
                            ? "match.noResults"
                            : "match.noResultsFiltersHint"
                    }
                    onView={this.handleView}
                    onMatch={index.showMatchButton ? onMatchToOthers : undefined}
                    onVisitExternal={index.handleVisitExternal}
                    onSelectionChange={this.handleSelectionChange}
                    onSelectAllChange={this.handleSelectAllChange}
                />
                {this.renderPaginationBar()}
                {this.renderDetailsDialog()}
                {this.renderComparisonDialog()}
                {this.renderSendEmailDialog()}
            </div>
        );
    }

    getSelectAllState() {
        const {selectingAll} = this.state;

        if (selectingAll) {
            return SelectAllState.SELECTING;
        }

        const {index, results} = this.props;

        if (index.selectAllLimit <= 0) {
            return undefined;
        }

        const allSelected =
            results.count !== undefined &&
            results.count > 0 &&
            (results.selection.length === results.count ||
                results.selection.length >= index.selectAllLimit);

        return allSelected ? SelectAllState.SELECTED_ALL : SelectAllState.SELECTED_NONE;
    }

    renderPaginationBar() {
        const {index, results, matchSourceEntity} = this.props;

        return (
            <MatchResultsPaginationBar
                page={results.displayedPage}
                pageSize={index.pageSize}
                totalItems={results.count || 0}
                selection={results.selection}
                indexConfig={index}
                backendSelection={results.backendSelection.data}
                makingSelection={results.backendSelection.isMakingSelection}
                selectionStatuses={index.selectionStatuses}
                selectionStatus={results.selectionStatus}
                disableMatchStatusSelection={index.disableMatchStatusSelection}
                renderAdditionalButtons={this.renderAdditionalButtons}
                canSelect={index.allowSelection}
                canSendEmails={Boolean(matchSourceEntity && index.sendEmails)}
                canCompare={index.allowComparison}
                canDownload={index.allowDownload}
                selectLabels={this.forResultType({
                    [ProfileType.CANDIDATE]: [`selectLabel.candidates.${index.name}`, "selectLabel.candidates"],
                    [ProfileType.JOB]: [`selectLabel.jobs.${index.name}`, "selectLabel.jobs"],
                })}
                compareLabel={this.forResultType({
                    [ProfileType.CANDIDATE]: "compareLabel.candidates",
                    [ProfileType.JOB]: "compareLabel.jobs",
                })}
                onSelectionStatusChange={this.handleSelectionStatusChange}
                onPageChange={this.handlePageChange}
                onDownload={this.handleDownload}
                onSelect={this.handleSelect}
                onSendEmails={this.handleOpenSendEmails}
                onCompare={this.handleCompare}
            />
        );
    }

    renderAdditionalButtons = () => {
        const {results, index, makeSelection, matchSourceEntity} = this.props;

        const actions = {
            makeSelection,
        };

        return index.additionalActionButtons
            .filter(definition => !definition.requiresMatchSourceEntity || matchSourceEntity)
            .map((definition, i) => (
                <AdditionalActionButton
                    key={i}
                    definition={definition}
                    index={index}
                    results={results}
                    actions={actions}
                />
            ));
    };

    renderDetailsDialog() {
        const {index, results, matchSourceEntity} = this.props;
        const {displayedMatches} = this.state;

        if (displayedMatches.length !== 1) {
            return null;
        }

        const getCandidateReverseLabel = () =>
            getDynamicProperty(
                results.matches[displayedMatches[0]].document,
                index.detailsTitleProperty
            ) || "—";

        const labels = {
            forwardLabel: <FormattedMessage id="matchQuality.searchProfile" />,
            forwardSubLabel: undefined,
            reverseLabel: this.forResultType({
                [ProfileType.CANDIDATE]: getCandidateReverseLabel,
                [ProfileType.JOB]: () => getNameForJobMatch(results.matches[displayedMatches[0]]),
            }),
            title: this.forResultType({
                [ProfileType.CANDIDATE]: () => (
                    <FormattedMessage
                        id="detailsModal.title"
                        values={{
                            subject: getCandidateReverseLabel(),
                        }}
                    />
                ),
                [ProfileType.JOB]: () => (
                    <FormattedMessage
                        id="detailsModal.title"
                        values={{subject: getNameForJobMatch(results.matches[displayedMatches[0]])}}
                    />
                ),
            }),
        };

        if (matchSourceEntity) {
            labels.forwardLabel = matchSourceEntity.displayName;
            labels.forwardSubLabel = <FormattedMessage id="matchQuality.searchProfile" />;
        }

        const navigationIds = this.getDetailsNavigationIds();
        const existingSelection = results.backendSelection.data;
        const match = results.matches[displayedMatches[0]];

        return (
            <MatchDetailsDialog
                scoreType={index.scoreType}
                match={match}
                externalTextDisplayUrlProperty={index.externalTextDisplayUrlProperty}
                extraPropertiesOrganization={index.extraProperties}
                extraPropertyPhoneNumbers={index.extraPropertyPhoneNumbers}
                disableWhatsappButton={
                    index.disableWhatsappButton || config("ui.disableWhatsappButton", false)
                }
                title={labels.title}
                forwardLabel={labels.forwardLabel}
                forwardSubLabel={labels.forwardSubLabel}
                reverseLabel={labels.reverseLabel}
                noteTypes={index.noteTypes}
                defaultNoteType={index.defaultNoteType}
                externalDetailsTabUrlFetcher={index.externalDetailsTabUrlFetcher}
                externalDetailsTabDefaultActive={index.externalDetailsTabDefaultActive}
                isNextDisabled={navigationIds.nextId === undefined}
                isPreviousDisabled={navigationIds.previousId === undefined}
                isMakeSelectionDisabled={existingSelection.isSelected(match.id)}
                isMakingSelection={results.backendSelection.isMakingSelection}
                onHide={this.handleHideMatchDetailsDialogs}
                onCreateNote={index.createNoteCallback ? this.handleCreateNote : undefined}
                onVisitExternal={index.handleVisitExternal}
                onNext={this.handleNextDetails}
                onPrevious={this.handlePreviousDetails}
                onMakeSelection={
                    index.allowSelection ? this.handleMakeSelectionFromDetails : undefined
                }
                resultType={this.props.index.resultType}
            />
        );
    }

    renderComparisonDialog = () => {
        const {results, index} = this.props;
        const {displayedMatches} = this.state;

        if (displayedMatches.length < 2) {
            return null;
        }

        const titleLabel = this.forResultType({
            [ProfileType.CANDIDATE]: "compareLabel.candidates",
            [ProfileType.JOB]: "compareLabel.jobs",
        });

        const reverseLabel = this.forResultType({
            [ProfileType.CANDIDATE]: "reverse.candidateToProfile",
            [ProfileType.JOB]: "reverse.jobToProfile",
        });

        const reverseHelp = this.forResultType({
            [ProfileType.CANDIDATE]: "reverse.candidateToProfileHelp",
            [ProfileType.JOB]: "reverse.jobToProfileHelp",
        });

        return (
            <MatchComparisonDialog
                indexConfig={index}
                indexState={results}
                matches={displayedMatches.map(id => results.matches[id])}
                detailsTitleProperty={index.detailsTitleProperty}
                comparisonSubtitleProperty={index.comparisonSubtitleProperty}
                comparisonExtraProperties={index.comparisonExtraProperties}
                titleLabel={titleLabel}
                reverseLabel={reverseLabel}
                reverseHelp={reverseHelp}
                onHide={this.handleHideMatchDetailsDialogs}
                onSelectionChange={this.handleSelectionChange}
                resultType={this.props.index.resultType}
            />
        );
    };

    renderSendEmailDialog() {
        const {index, results, matchSourceEntity} = this.props;
        const {showSendEmails} = this.state;

        if (
            !showSendEmails ||
            !index.sendEmails ||
            !matchSourceEntity ||
            results.selection.length === 0
        ) {
            return null;
        }

        let jobIds;
        let candidateIds;

        if (index.resultType === ProfileType.CANDIDATE) {
            jobIds = [matchSourceEntity.id];
            candidateIds = results.selection;
        } else if (index.resultType === ProfileType.JOB) {
            jobIds = results.selection;
            candidateIds = [matchSourceEntity.id];
        } else {
            return null;
        }

        return (
            <SendEmailDialog
                jobIds={jobIds}
                candidateIds={candidateIds}
                getTemplatesEndpoint={index.sendEmails.getTemplatesEndpoint}
                sendEmailsEndpoint={index.sendEmails.sendEmailsEndpoint}
                onClose={this.handleCloseSendEmails}
            />
        );
    }

    isGreenMatch = match => {
        return isGreenMatch(match, this.props.index.lastRequest);
    };

    forResultType = values => {
        const value = values[this.props.index.resultType];
        return isFunction(value) ? value() : value;
    };

    handleOpenSendEmails = () => {
        this.setState({showSendEmails: true});
    };

    handleCloseSendEmails = () => {
        this.setState({showSendEmails: false});
    };

    handleView = id => {
        this.setState({displayedMatches: [id]});
    };

    handleCompare = () => {
        const {selection, matches} = this.props.results;

        this.setState({
            displayedMatches: selection
                .map(id => matches[id])
                .filter(m => m)
                .sort((a, b) => b.score - a.score)
                .map(match => match.id),
        });
    };

    handleSelectionChange = selection => {
        const {index, onSelectionChange} = this.props;
        onSelectionChange(index, selection);
    };

    handleSelectAllChange = checkedOrAmount => {
        const {selectingAll} = this.state;

        if (selectingAll) {
            return SelectAllState.SELECTING;
        }

        const {index, results, onSelectionChange, selectAllFromApi} = this.props;
        let amount;

        if (checkedOrAmount === true) {
            amount = index.selectAllLimit;
        } else if (checkedOrAmount === false) {
            amount = 0;
        } else {
            amount = checkedOrAmount;
        }

        if (amount > 0 && results.count !== undefined && results.count > 0) {
            this.setState({selectingAll: true});
            selectAllFromApi(index, amount)
                .then(() => this.clearSelectingAll())
                .catch(e => {
                    this.clearSelectingAll();
                    throw e;
                });
        } else {
            onSelectionChange(index, []);
        }
    };

    clearSelectingAll() {
        if (!this.unmounted) {
            this.setState({selectingAll: false});
        }
    }

    handlePageChange = page => {
        const {requestPage, index} = this.props;
        requestPage(index, page);
    };

    handleDownload = () => {
        const {index, onDownload} = this.props;
        return onDownload(index);
    };

    handleSelect = () => {
        const {index, results, makeSelection} = this.props;
        makeSelection(index, results.selection);
    };

    getDetailsNavigationIds() {
        const currentId = this.state.displayedMatches[0];

        if (!currentId) {
            return {nextId: undefined, previousId: undefined};
        }

        const matches = this.selectors.displayedMatches(this.props.results);
        const currentIdIndex = matches.findIndex(match => match.id === currentId);

        if (currentIdIndex < 0) {
            return {nextId: undefined, previousId: undefined};
        }

        return {
            previousId: currentIdIndex >= 1 ? matches[currentIdIndex - 1].id : undefined,
            nextId:
                currentIdIndex < matches.length - 1 ? matches[currentIdIndex + 1].id : undefined,
        };
    }

    handleNextDetails = () => {
        const {nextId} = this.getDetailsNavigationIds();

        if (nextId !== undefined) {
            this.setState({displayedMatches: [nextId]});
        }
    };

    handlePreviousDetails = () => {
        const {previousId} = this.getDetailsNavigationIds();

        if (previousId !== undefined) {
            this.setState({displayedMatches: [previousId]});
        }
    };

    handleMakeSelectionFromDetails = id => {
        const {index, makeSelection} = this.props;
        makeSelection(index, [id]);
    };

    handleHideMatchDetailsDialogs = () => {
        this.setState({displayedMatches: []});
    };

    handleSelectionStatusChange = status => {
        const {index, onSelectionStatusChange} = this.props;
        onSelectionStatusChange(index, status);
    };

    handleCreateNote = (...args) => {
        const {index} = this.props;
        return index.createNoteCallback(...args);
    };
}
