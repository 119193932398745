import React from "react";
import PropTypes from "prop-types";
import NumberInput from "~/components/NumberInput";
import c from "classnames";
import styles from "./styles.module.scss";

export default class MinScore extends React.PureComponent {
    static propTypes = {
        index: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {state} = this.props;

        return (
            <div className={styles.minScore}>
                Minimum score:
                <NumberInput
                    className={c("form-control", "form-control-sm", "ml-2", styles.numberInput)}
                    value={state.nextRequest.minScore}
                    onChange={this.handleChange}
                />
            </div>
        );
    }

    handleChange = nextValue => {
        const {onChange, index} = this.props;
        onChange(index, nextValue);
    };
}
