import {createIntl, createIntlCache} from "react-intl";
import config from "~/config";

export const I18N = {
    intl: undefined,
};

export function initializeLocalization() {
    const locale = config("ui.locale");
    const messages = config(`i18n.messages.${locale}`);

    if (!Intl.PluralRules) {
        require("@formatjs/intl-pluralrules/polyfill");
        require("@formatjs/intl-pluralrules/dist/locale-data/nl");
        require("@formatjs/intl-pluralrules/dist/locale-data/en");
    }

    const cache = createIntlCache();
    I18N.intl = createIntl({locale, messages, defaultLocale: "en"}, cache);
    return I18N.intl;
}
