import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import ObjectValue from "./ObjectValue";
import ArrayValue from "./ArrayValue";
import {isString} from "~/util/misc";
import {formatIfDatetime} from "~/util/date-time-formatting";

export default class Value extends React.PureComponent {
    static propTypes = {
        value: PropTypes.any,
    };

    render() {
        const {value} = this.props;

        if (value === true || value === false) {
            return this.renderAsBoolean();
        } else if (isString(value)) {
            return this.renderAsString();
        } else if (Array.isArray(value)) {
            return this.renderAsArray();
        } else if (value instanceof Object) {
            return this.renderAsObject();
        } else {
            return this.renderAsJson();
        }
    }

    renderAsBoolean() {
        if (this.props.value) {
            return (
                <div>
                    <i className="fas fa-check"/> <FormattedMessage id="boolean.true"/>
                </div>
            );
        } else {
            return (
                <div>
                    <i className="fas fa-times"/> <FormattedMessage id="boolean.false"/>
                </div>
            );
        }
    }

    renderAsString() {
        return <div>{formatIfDatetime(this.props.value)}</div>;
    }

    renderAsArray() {
        return <ArrayValue value={this.props.value}/>;
    }

    renderAsObject() {
        return <ObjectValue value={this.props.value}/>;
    }

    renderAsJson() {
        return <div>{JSON.stringify(this.props.value)}</div>;
    }
}
