import React from "react";
import {fromString} from "html-to-text";
import MatchQualityTable from "~/components/MatchDetailsDialog/MatchQualityTable";
import styles from "~/components/MatchDetailsDialog/styles.module.scss";
import config from "~/config";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {reorganizeObject} from "~/util/object-reorganize";
import Table from "react-bootstrap/Table";
import PhoneNumber from "~/components/PhoneNumber";
import DOMPurify from "dompurify";
import {isPlainObject, isString} from "~/util/misc";
import DynamicProperty from "~/prop-types/dynamic-property";
import Button from "react-bootstrap/Button";
import {getTranslatedValue} from "~/util/ui-translations";
import {getDynamicProperty} from "~/util/match";
import {formatIfDatetime} from "~/util/date-time-formatting";
import ScoreType from "~/enums/ScoreType";

export default class MatchDetailsOverview extends React.PureComponent {
    static propTypes = {
        scoreType: ScoreType.propType,
        match: PropTypes.object.isRequired,
        externalTextDisplayUrlProperty: DynamicProperty,
        extraPropertiesOrganization: PropTypes.object.isRequired,
        extraPropertyPhoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
        disableWhatsappButton: PropTypes.bool.isRequired,
        forwardLabel: PropTypes.node.isRequired,
        forwardSubLabel: PropTypes.node,
        reverseLabel: PropTypes.node.isRequired,
        hideForwardColumn: PropTypes.bool.isRequired,
        externalDetailsTabUrlFetcher: PropTypes.func,
        externalDetailsTabDefaultActive: PropTypes.bool.isRequired,
        resultType: PropTypes.string.isRequired,
    };

    render() {
        return (
            <div>
                {this.renderDetailsTable()}
                {this.renderProfileDisplay()}
            </div>
        );
    }

    renderDetailsTable() {
        const {
            scoreType,
            match,
            forwardLabel,
            forwardSubLabel,
            reverseLabel,
            hideForwardColumn,
            resultType,
        } = this.props;

        return (
            <MatchQualityTable
                scoreType={scoreType}
                aspectDetails={match.aspectDetails}
                details={match.details}
                otherAspects={match.convertedAspects}
                forwardLabel={forwardLabel}
                forwardSubLabel={forwardSubLabel}
                reverseLabel={reverseLabel}
                hideForwardColumn={hideForwardColumn}
                className={styles.qualityTable}
                resultType={resultType}
            />
        );
    }

    renderProfileDisplay() {
        const {match} = this.props;
        const sections = config("ui.profileDisplay");

        return sections.map(section => {
            switch (section) {
                case "text":
                    return this.renderText(match); // TODO: Find a better way to handle this

                case "extraProperties":
                    return this.renderExtraProperties(match.document.extraProperties);

                case "educations":
                    return this.renderEducations(match.deprecatedEntity.educations);

                case "workExperiences":
                    return this.renderWorkExperiences(match.deprecatedEntity.workExperiences);

                default:
                    return null;
            }
        });
    }

    renderText(match, ref) {
        const text = match.detailsText;

        if (!text) {
            return null;
        }

        let displayed;

        if (!text.value) {
            displayed = <FormattedMessage id="detailsModal.noText" />;
        } else if (containsHtml(text.value)) {
            displayed = <div dangerouslySetInnerHTML={{__html: sanitize(text.value)}} />;
        } else {
            displayed = <div className={styles.jobDescription}>{text.value}</div>;
        }

        const {externalTextDisplayUrlProperty} = this.props;
        const externalUrl = getDynamicProperty(match.document, externalTextDisplayUrlProperty);

        return (
            <div key="text" ref={ref} className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id={text.label} />
                    {externalUrl !== undefined && (
                        <a
                            href={externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-3"
                        >
                            <Button variant="secondary" size="sm">
                                <FormattedMessage id="action.open" />
                            </Button>
                        </a>
                    )}
                </h5>
                <div>{displayed}</div>
            </div>
        );
    }

    renderExtraProperties(extraProperties) {
        if (!extraProperties) {
            return null;
        }

        const {
            extraPropertiesOrganization,
            extraPropertyPhoneNumbers,
            disableWhatsappButton,
        } = this.props;
        const displayedExtraProperties = reorganizeObject(
            extraProperties,
            extraPropertiesOrganization
        );

        return (
            <div key="extraProperties" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.extraProperties" />
                </h5>
                <Table size="sm" borderless={true} className={styles.extraPropertiesTable}>
                    <tbody>
                        {Object.keys(displayedExtraProperties).map(property => (
                            <tr key={property}>
                                <td className={styles.propertyColumn}>
                                    <FormattedMessage.Quiet
                                        id={`extraProperties.${property}`}
                                        defaultMessage={property}
                                    />
                                </td>
                                <td className={styles.valueColumn}>
                                    {renderPropertyValue(
                                        property,
                                        displayedExtraProperties[property],
                                        `extraProperties.${property}`,
                                        {
                                            isPhoneNumber: extraPropertyPhoneNumbers.includes(
                                                property
                                            ),
                                            disableWhatsappButton,
                                        }
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderEducations(educations) {
        if (!educations || !educations.length) {
            return null;
        }

        return (
            <div key="educations" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.educations" />
                </h5>
                <Table size="sm" bordered={true}>
                    <tbody>
                        {educations.map((education, index) => (
                            <tr key={index}>
                                <td>
                                    <div className={styles.simpleDetailList}>
                                        {this.renderSimpleDetail(
                                            "education.dateFrom",
                                            education.dateFrom
                                        )}
                                        {this.renderSimpleDetail(
                                            "education.dateTo",
                                            education.dateTo
                                        )}
                                    </div>
                                    <div className={styles.simpleDetailList}>
                                        {this.renderSimpleDetail(
                                            "education.field",
                                            education.field
                                        )}
                                        {this.renderSimpleDetail(
                                            "education.degree",
                                            education.degree
                                        )}
                                        {this.renderSimpleDetail(
                                            "education.institute",
                                            education.institute
                                        )}
                                    </div>
                                    {education.info && <div>{education.info}</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderWorkExperiences(workExperiences) {
        if (!workExperiences || !workExperiences.length) {
            return null;
        }

        return (
            <div key="workExperiences" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.workExperiences" />
                </h5>
                <Table bordered={true}>
                    <tbody>
                        {workExperiences.map((workExperience, index) => (
                            <tr key={index}>
                                <td>
                                    <div className={styles.simpleDetailList}>
                                        {this.renderSimpleDetail(
                                            "workExperience.dateFrom",
                                            workExperience.dateFrom
                                        )}
                                        {this.renderSimpleDetail(
                                            "workExperience.dateTo",
                                            workExperience.dateTo
                                        )}
                                        {this.renderSimpleDetail(
                                            "workExperience.company",
                                            workExperience.company
                                        )}
                                    </div>
                                    {this.renderSimpleDetail(
                                        "workExperience.title",
                                        workExperience.title
                                    )}
                                    {workExperience.text && <div>{workExperience.text}</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderSimpleDetail(label, value) {
        return (
            value && (
                <div className={styles.simpleDetail}>
                    <div className={styles.simpleDetailTitle}>
                        <FormattedMessage id={label} />
                    </div>
                    <div className={styles.simpleDetailValue}>{value}</div>
                </div>
            )
        );
    }
}

function renderPropertyValue(key, value, path, options) {
    const {isPhoneNumber} = options;

    if (Array.isArray(value)) {
        if (config("ui.temporaryCarerixNotesFilter", false) && key === "toDos") {
            value = value.filter(x => x.subject && !x.subject.startsWith("[SYS]"));
        }

        return value.map((value, index) =>
            renderPropertyValue(`${key}.${index}`, value, path, options)
        );
    } else if (isPlainObject(value)) {
        return renderObjectPropertyValue(key, value, path, options);
    } else if (value === true) {
        return (
            <React.Fragment key={key}>
                <i className="fas fa-check" /> <FormattedMessage id="boolean.true" />
            </React.Fragment>
        );
    } else if (value === false) {
        return (
            <React.Fragment key={key}>
                <i className="fas fa-times" /> <FormattedMessage id="boolean.false" />
            </React.Fragment>
        );
    } else if (isPhoneNumber) {
        return (
            <PhoneNumber
                key={key}
                value={value}
                disableWhatsappButton={options.disableWhatsappButton}
            />
        );
    } else if (isString(value) && config("ui.extraPropertiesTextMode") === "strip-html") {
        const strippedValue = fromString(value, {wordWrap: false});
        return <div key={key}>{getTranslatedValue(path, formatIfDatetime(strippedValue))}</div>;
    } else {
        return <div key={key}>{getTranslatedValue(path, formatIfDatetime(value))}</div>;
    }
}

function renderObjectPropertyValue(baseKey, value, path, options) {
    return (
        <Table key={baseKey} bordered={true} className={styles.objectTable}>
            <tbody>
                {Object.keys(value).map(key => (
                    <tr key={key}>
                        <td className={styles.propertyColumn}>
                            <FormattedMessage.Quiet id={`${path}.${key}`} defaultMessage={key} />
                        </td>
                        <td style={{width: "100%"}}>
                            {renderPropertyValue(
                                `${baseKey}.${key}`,
                                value[key],
                                `${path}.${key}`,
                                options
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

const CONTAINS_HTML_REGEX = /<\/?[a-z][\s\S]*>/i;

function containsHtml(text) {
    return CONTAINS_HTML_REGEX.test(text);
}

function sanitize(html) {
    return DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ["b", "strong", "i", "em", "ul", "ol", "li", "div", "p"],
    });
}
