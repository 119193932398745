import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import ReactResizeDetector from "react-resize-detector";
import debounce from "lodash.debounce";
import Api from "~/api";
import ProfilePropType from "~/prop-types/match-profile";
import config from "~/config";
import ValuesAspectEditor from "~/components/ValuesAspectEditor";
import EnumValuesAspectEditor from "~/components/EnumValuesAspectEditor";
import LocationsAspectEditor from "~/components/LocationsAspectEditor";
import RangeAspectEditor from "~/components/RangeAspectEditor";
import NumberAspectEditor from "~/components/NumberAspectEditor";
import {isEmptyObject} from "~/util/misc";
import styles from "./match-profile-editor.module.scss";
import EnumTreeValuesAspectEditor from "~/components/EnumTreeValuesAspectEditor";
import {I18N} from "~/util/localization";

export default class MatchProfileEditor extends React.PureComponent {
    static propTypes = {
        profile: ProfilePropType.isRequired,
        indexName: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        isHorizontal: PropTypes.bool.isRequired,
        showSuggestions: PropTypes.bool.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isHorizontal: false,
        showSuggestions: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            suggestions: {},
            columns: 1,
        };

        this.lastSuggestionKeywords = [];
        this.aspectEditorRefs = {};

        this.fetchSuggestions = debounce(this.fetchSuggestions, 1000);
        this.suggestionsCall = null;
    }

    componentDidMount() {
        this.updateSuggestions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateSuggestions();
    }

    updateSuggestions() {
        const {profile, showSuggestions} = this.props;
        const keywords = [];

        if (!showSuggestions) {
            return;
        }

        for (const aspect in profile.aspects) {
            if (!config(`aspects.${aspect}.suggestionInput`, false)) {
                continue;
            }

            if (Array.isArray(profile.aspects[aspect].value)) {
                for (const value of profile.aspects[aspect].value) {
                    if (value.label !== undefined) {
                        keywords.push(value.label);
                    }
                }
            }
        }

        keywords.sort();

        if (JSON.stringify(keywords) !== JSON.stringify(this.lastSuggestionKeywords)) {
            if (keywords.length > 0) {
                this.lastSuggestionKeywords = keywords;
                this.fetchSuggestions(keywords);
            } else if (!isEmptyObject(this.state.suggestions)) {
                this.setState({suggestions: {}});
            }
        }
    }

    fetchSuggestions(keywords) {
        const {indexName, language} = this.props;

        if (this.suggestionsCall !== null) {
            this.suggestionsCall.abort();
        }

        this.suggestionsCall = Api.keywordSuggestions(indexName, keywords, language);

        this.suggestionsCall
            .then(({response}) => {
                if (!response) return;

                const suggestions = {jobFunctions: [], hardSkills: []};

                for (const suggestion of response) {
                    switch (suggestion.queryItemType) {
                        case "FUNCTION":
                            suggestions.jobFunctions.push(suggestion.keyword);
                            break;

                        case "HARD_SKILL":
                            suggestions.hardSkills.push(suggestion.keyword);
                            break;

                        default:
                            break;
                    }
                }

                this.setState({suggestions});
            })
            .catch(error => {
                console.error("Couldn't get suggestions", error);
            });
    }

    reset() {
        for (const name in this.aspectEditorRefs) {
            if (this.aspectEditorRefs[name].current && this.aspectEditorRefs[name].current.reset) {
                this.aspectEditorRefs[name].current.reset();
            }
        }
    }

    getAspectEditorRef(name) {
        if (this.aspectEditorRefs[name] === undefined) {
            this.aspectEditorRefs[name] = React.createRef();
        }

        return this.aspectEditorRefs[name];
    }

    shouldForceAspectRequiredCheckbox() {
        const {profile} = this.props;

        for (const aspect in profile.aspects) {
            if (profile.aspects[aspect].required !== config(`aspects.${aspect}`).required) {
                return true;
            }
        }

        return false;
    }

    render() {
        const {className, isHorizontal} = this.props;
        const {columns} = this.state;

        return (
            <div
                className={c(className, {[styles.horizontal]: isHorizontal}, `columns-${columns}`)}
            >
                {this.renderAspects()}
                <ReactResizeDetector handleWidth onResize={this.handleResize} />
            </div>
        );
    }

    renderAspects() {
        const {profile} = this.props;

        return config("ui.aspectOrder")
            .filter(name => profile.aspects[name] !== undefined)
            .map(name => this.renderAspect(name, profile.aspects[name]));
    }

    renderAspect(name, aspect) {
        switch (aspect.type) {
            case "concepts":
                return this.renderAspectEditor(name, aspect, ValuesAspectEditor);

            case "keywords":
                return this.renderKeywordAspectEditor(name, aspect);

            case "keywords-tree":
                return this.renderAspectEditor(name, aspect, EnumTreeValuesAspectEditor);

            case "locations":
                return this.renderAspectEditor(name, aspect, LocationsAspectEditor);

            case "range":
                return this.renderAspectEditor(name, aspect, RangeAspectEditor);

            case "integer":
            case "float":
                return this.renderAspectEditor(name, aspect, NumberAspectEditor);

            default:
                return null;
        }
    }

    renderAspectEditor(name, aspect, Editor) {
        const {language} = this.props;
        const {suggestions} = this.state;

        return (
            <div className={styles.editor} key={name}>
                <Editor
                    title={I18N.intl.formatMessage({id: `aspect.${name}`})}
                    name={name}
                    aspect={aspect}
                    aspectConfig={config(`aspects.${name}`)}
                    suggestions={suggestions[name]}
                    isEditing={true}
                    language={language}
                    forceAspectRequiredCheckbox={this.shouldForceAspectRequiredCheckbox()}
                    onChange={this.handleAspectChange}
                    onRemoveSuggestion={this.handleRemoveSuggestion}
                    ref={this.getAspectEditorRef(name)}
                />
            </div>
        );
    }

    renderKeywordAspectEditor(name, aspect) {
        const enumValues = config(`enums.${name}`, undefined); // Old way of supplying values, deprecated
        const aspectConfig = config(`aspects.${name}`);

        // If we don't know about the enum, fall back to text input
        if (enumValues === undefined && aspectConfig.values === undefined) {
            return this.renderAspectEditor(name, aspect, ValuesAspectEditor);
        }

        return (
            <div className={styles.editor} key={name}>
                <EnumValuesAspectEditor
                    title={I18N.intl.formatMessage({id: `aspect.${name}`})}
                    name={name}
                    aspect={aspect}
                    aspectConfig={aspectConfig}
                    enumValues={enumValues}
                    isEditing={true}
                    forceAspectRequiredCheckbox={this.shouldForceAspectRequiredCheckbox()}
                    onChange={this.handleAspectChange}
                    ref={this.getAspectEditorRef(name)}
                />
            </div>
        );
    }

    handleAspectChange = (name, nextValue) => {
        const {profile, onChange} = this.props;

        onChange({
            ...profile,
            aspects: {
                ...profile.aspects,
                [name]: nextValue,
            },
        });
    };

    handleRemoveSuggestion = (name, suggestion) => {
        this.setState(state => ({
            suggestions: {
                ...state.suggestions,
                [name]: state.suggestions[name].filter(s => s !== suggestion),
            },
        }));
    };

    handleResize = width => {
        if (width === 0) return;
        const columns = Math.min(Math.max(Math.floor(width / 400), 1), 6);
        this.setState(state => {
            if (state.columns !== columns) {
                return {columns};
            } else {
                return null;
            }
        });
    };
}
