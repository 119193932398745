import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class OneOfValidator extends Validator {
    constructor(description, defaultValue, validValues) {
        super(description, defaultValue);
        this.validValues = validValues;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (this.validValues.includes(value)) {
            return value;
        } else {
            throw new ValidationError(
                path,
                `Expected one of ${this.validValues.join(", ")}; but got ${betterTypeOf(value)}`
            );
        }
    }
}
