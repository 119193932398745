import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "~/entities/ErrorMessage";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import config from "~/config";
import Form from "react-bootstrap/Form";

export default class ErrorMessages extends React.PureComponent {
    static propTypes = {
        pendingErrorMessages: PropTypes.arrayOf(ErrorMessage.propType).isRequired,
        closeFirstErrorMessage: PropTypes.func.isRequired,
    };

    render() {
        const {pendingErrorMessages} = this.props;

        if (pendingErrorMessages.length === 0) {
            return null;
        }

        const errorMessage = pendingErrorMessages[0];

        // TODO: Implement closable sometimes

        return (
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id={errorMessage.title} values={errorMessage.values} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id={errorMessage.message} values={errorMessage.values} />
                    {errorMessage.detailedMessage && (
                        <div className="mt-3">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                readOnly={true}
                                value={errorMessage.detailedMessage}
                            />
                        </div>
                    )}
                </Modal.Body>
                {canCloseErrorMessage(errorMessage) && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        );
    }

    handleClose = () => {
        this.props.closeFirstErrorMessage();
    };
}

function canCloseErrorMessage(errorMessage) {
    return (
        errorMessage.closable === ErrorMessage.CLOSABLE_ALWAYS ||
        (errorMessage.closable === ErrorMessage.CLOSABLE_SOMETIMES && !config("ui.hideMenu"))
    );
}
