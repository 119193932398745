import Validators from "~/config/validation/validators/Validators";
import TranslatableLabelDefinition from "~/config/definitions/shared/TranslatableLabelDefinition";
import FilterValuesDefinition from "~/config/definitions/shared/FilterValuesDefinition";

export default Validators.objectOf({
    description: "Custom filter settings, by index.",
    values: Validators.objectOf({
        description: "Custom filters, by field.",
        values: Validators.shape({
            description: "Custom filter configuration.",
            shape: {
                label: TranslatableLabelDefinition,
                type: Validators.oneOf({
                    description: "Filter type.",
                    validValues: [
                        "boolean",
                        "keyword",
                        "number",
                        "age",
                        "enum",
                        "date",
                        "fullText",
                        "rawFilter",
                    ],
                }),
                fields: Validators.arrayOf({
                    description: "Fields to use instead of the key in the parent object.",
                    items: Validators.string({
                        description: "ElasticSearch field path.",
                    }),
                }).allowsUndefined,
                values: FilterValuesDefinition.allowsUndefined,
                forcedStative: Validators.oneOf({
                    description: "A forced stative, used by date filters only.",
                    validValues: ["-", "+"],
                }).allowsUndefined,
                defaultData: Validators.oneOfType({
                    description:
                        "Default filter data to use instead of the type's standard default data. Currently only supported by boolean filters.",
                    validTypes: [
                        Validators.boolean({
                            description: "Default data used by boolean filters.",
                        }),
                    ],
                }).allowsUndefined,
                alternateTrueValue: Validators.any({
                    description:
                        'Used by the boolean to replace the concept of "true". If set, true means a term filter on this value, and false a must_not version of that filter.',
                }).allowsUndefined,
                contextIdSet: Validators.string({
                    description:
                        'Used by the boolean to replace the concept of "true". If set, true means a terms filter that checks that the ID is part of this set from the context, and false a must_not version of that filter',
                }).allowsUndefined,
                requiresAsyncSelection: Validators.boolean({
                    description:
                        "If true, this filter request asynchronous selection data. Careful, this will delay the search request until async data is available.",
                }).allowsUndefined,
            },
        }),
    }),
});
