import Validators from "~/config/validation/validators/Validators";

const getEndPointDefinition = Validators.oneOfType({
    description: "get endpoint",
    validTypes: [
        Validators.string({
            description: "get endpoint",
        }),
        Validators.objectOf({
            description: "multiple get endpoints",
            values: Validators.string({
                description: "one of get endpoints",
            })
        })
    ]
})

export default Validators.shape({
    description: "Configuration for shape with get and set with default value undefined",
    shape: {
        get: getEndPointDefinition.allowsUndefined,
        set: Validators.string({
            description: "set endpoint",
        }).allowsUndefined,
        getAsync: getEndPointDefinition.allowsUndefined
    },
});