import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";
import CreateNoteDialog from "~/components/CreateNoteDialog/CreateNoteDialog";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import MatchDetailsOverview from "~/components/MatchDetailsOverview";
import config from "~/config";
import ProcessButton from "~/components/ProcessButton";
import DynamicProperty from "~/prop-types/dynamic-property";
import ScoreType from "~/enums/ScoreType";
import TranslatableLabel from "~/prop-types/translatable-label";

export default class MatchDetailsDialog extends React.PureComponent {
    static propTypes = {
        scoreType: ScoreType.propType,
        match: PropTypes.object.isRequired,
        externalTextDisplayUrlProperty: DynamicProperty,
        extraPropertiesOrganization: PropTypes.object.isRequired,
        extraPropertyPhoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
        disableWhatsappButton: PropTypes.bool.isRequired,
        title: PropTypes.node.isRequired,
        forwardLabel: PropTypes.node.isRequired,
        forwardSubLabel: PropTypes.node,
        reverseLabel: PropTypes.node.isRequired,
        hideForwardColumn: PropTypes.bool.isRequired,
        isNextDisabled: PropTypes.bool.isRequired,
        isPreviousDisabled: PropTypes.bool.isRequired,
        isMakeSelectionDisabled: PropTypes.bool.isRequired,
        isMakingSelection: PropTypes.bool.isRequired,
        noteTypes: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: TranslatableLabel.isRequired,
            })
        ),
        externalDetailsTabUrlFetcher: PropTypes.func,
        externalDetailsTabDefaultActive: PropTypes.bool.isRequired,
        defaultNoteType: PropTypes.any,
        onHide: PropTypes.func,
        onMakeSelection: PropTypes.func,
        onCreateNote: PropTypes.func,
        onVisitExternal: PropTypes.func,
        onPrevious: PropTypes.func,
        onNext: PropTypes.func,
        resultType: PropTypes.string.isRequired,
    };

    static defaultProps = {
        forwardLabel: "",
        hideForwardColumn: false,
        extraPropertyPhoneNumbers: [],
        externalDetailsTabDefaultActive: false,
        isNextDisabled: false,
        isPreviousDisabled: false,
        isMakeSelectionDisabled: false,
        isMakingSelection: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: props.externalDetailsTabDefaultActive ? "externalDetails" : "matchDetails",
            externalDetailsUrl: undefined,
            showingNoteDialog: false,
            submittingNote: false,
            wasNoteError: false,
        };

        this.unmounted = false;
        this.externalDetailsUrlPromise = undefined;
        this.modalBodyRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.activeTab === "externalDetails") {
            this.fetchExternalDetailsUrl();
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.activeTab === "externalDetails" &&
            (prevState.activeTab !== "externalDetails" ||
                prevProps.match.id !== this.props.match.id)
        ) {
            this.fetchExternalDetailsUrl();
        }
    }

    render() {
        const {
            isMakeSelectionDisabled,
            isMakingSelection,
            onHide,
            onCreateNote,
            onVisitExternal,
            onMakeSelection,
        } = this.props;

        return (
            <Modal
                size="lg"
                show={true}
                centered
                onHide={onHide}
                scrollable={true}
                dialogClassName={styles.dialog}
            >
                <Modal.Header closeButton={true} className={styles.modalHeader}>
                    {this.renderNavigation()}
                    {this.renderTabs()}
                    {onMakeSelection !== undefined && (
                        <ProcessButton
                            label={<FormattedMessage id="action.makeSelection" />}
                            size="sm"
                            onClick={this.handleMatch}
                            disabled={isMakeSelectionDisabled}
                            className={styles.addNote}
                            processing={isMakingSelection}
                        />
                    )}
                    {onVisitExternal !== undefined && (
                        <Button
                            size="sm"
                            variant="info"
                            onClick={this.handleVisitExternal}
                            className={styles.addNote}
                        >
                            <i className="fas fa-external-link-alt" />
                        </Button>
                    )}
                    {onCreateNote !== undefined && !config("ui.disableNotes") && (
                        <Button
                            size="sm"
                            onClick={this.handleOpenNoteDialog}
                            className={styles.addNote}
                        >
                            <FormattedMessage id="detailsModal.addNote" />
                        </Button>
                    )}
                </Modal.Header>
                {this.renderActiveTab()}
            </Modal>
        );
    }

    renderNavigation() {
        const {isNextDisabled, isPreviousDisabled, onNext, onPrevious} = this.props;

        if (!onNext && !onPrevious) {
            return null;
        }

        return (
            <div className={styles.navigation}>
                {onPrevious && (
                    <Button
                        size="sm"
                        variant="light"
                        onClick={this.handlePrevious}
                        disabled={isPreviousDisabled}
                    >
                        <i className="fas fa-angle-left" />
                    </Button>
                )}
                {onNext && (
                    <Button
                        size="sm"
                        variant="light"
                        onClick={this.handleNext}
                        disabled={isNextDisabled}
                    >
                        <i className="fas fa-angle-right" />
                    </Button>
                )}
            </div>
        );
    }

    renderTabs() {
        const {externalDetailsTabUrlFetcher, title} = this.props;
        const {activeTab} = this.state;

        return (
            <Nav id="external-details-tabs" variant="tabs" className={styles.navBar}>
                <Nav.Item>
                    <div className={styles.navTitle}>{title}</div>
                </Nav.Item>
                <Nav.Item onClick={this.handleMatchDetailsTabClick}>
                    <Nav.Link className={c({active: activeTab === "matchDetails"})}>
                        <FormattedMessage id="detailsModal.tabs.matchDetails" />
                    </Nav.Link>
                </Nav.Item>
                {externalDetailsTabUrlFetcher && (
                    <Nav.Item onClick={this.handleExternalDetailsTabClick}>
                        <Nav.Link className={c({active: activeTab === "externalDetails"})}>
                            <FormattedMessage id="detailsModal.tabs.externalDetails" />
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
        );
    }

    renderActiveTab() {
        const {activeTab} = this.state;

        return (
            <React.Fragment>
                {activeTab === "matchDetails" && (
                    <Modal.Body ref={this.modalBodyRef}>{this.renderMatchDetails()}</Modal.Body>
                )}
                <Modal.Body
                    className={c(styles.externalContentBody, {
                        [styles.softHidden]: activeTab !== "externalDetails",
                    })}
                >
                    {this.renderExternalDetails()}
                </Modal.Body>
            </React.Fragment>
        );
    }

    renderMatchDetails() {
        const {
            scoreType,
            match,
            externalTextDisplayUrlProperty,
            extraPropertiesOrganization,
            extraPropertyPhoneNumbers,
            disableWhatsappButton,
            forwardLabel,
            forwardSubLabel,
            reverseLabel,
            hideForwardColumn,
            externalDetailsTabUrlFetcher,
            externalDetailsTabDefaultActive,
            resultType,
        } = this.props;

        return (
            <React.Fragment>
                <MatchDetailsOverview
                    scoreType={scoreType}
                    match={match}
                    externalTextDisplayUrlProperty={externalTextDisplayUrlProperty}
                    extraPropertiesOrganization={extraPropertiesOrganization}
                    extraPropertyPhoneNumbers={extraPropertyPhoneNumbers}
                    disableWhatsappButton={disableWhatsappButton}
                    forwardLabel={forwardLabel}
                    forwardSubLabel={forwardSubLabel}
                    reverseLabel={reverseLabel}
                    hideForwardColumn={hideForwardColumn}
                    externalDetailsTabUrlFetcher={externalDetailsTabUrlFetcher}
                    externalDetailsTabDefaultActive={externalDetailsTabDefaultActive}
                    resultType={resultType}
                />
                {this.renderCreateNoteDialog()}
            </React.Fragment>
        );
    }

    renderExternalDetails() {
        const {externalDetailsUrl} = this.state;

        return (
            <div className={c(styles.externalFrameContainer)}>
                {externalDetailsUrl && (
                    <iframe src={externalDetailsUrl} className={styles.externalFrame} />
                )}
            </div>
        );
    }

    fetchExternalDetailsUrl() {
        const {externalDetailsTabUrlFetcher, match} = this.props;

        const promise = externalDetailsTabUrlFetcher(match.id);
        this.externalDetailsUrlPromise = promise;

        promise.then(url => {
            if (!this.unmounted && this.externalDetailsUrlPromise === promise) {
                this.setState({externalDetailsUrl: url});
            }
        });

        this.setState({externalDetailsUrl: undefined});
    }

    renderCreateNoteDialog() {
        const {match, noteTypes, defaultNoteType} = this.props;
        const {showingNoteDialog, submittingNote, wasNoteError} = this.state;

        if (!showingNoteDialog) return;

        return (
            <CreateNoteDialog
                subjectId={match.id}
                subjectName="Name"
                noteTypes={noteTypes}
                defaultNoteType={defaultNoteType}
                submitting={submittingNote}
                wasError={wasNoteError}
                onSubmit={this.handleSubmitNote}
                onCancel={this.handleCancelNote}
            />
        );
    }

    handleMatchDetailsTabClick = () => {
        this.setState({activeTab: "matchDetails"});
    };

    handleExternalDetailsTabClick = () => {
        this.setState({activeTab: "externalDetails"});
    };

    handleOpenNoteDialog = () => {
        this.setState({showingNoteDialog: true});
    };

    handleVisitExternal = () => {
        const {match, onVisitExternal} = this.props;
        onVisitExternal(match.id);
    };

    handleCancelNote = () => {
        this.setState({showingNoteDialog: false});
    };

    handleSubmitNote = (...args) => {
        const {onCreateNote} = this.props;

        this.setState({submittingNote: true});

        onCreateNote(...args)
            .then(() => {
                this.setState({submittingNote: false, showingNoteDialog: false});
            })
            .catch(e => {
                this.setState({submittingNote: false, wasNoteError: true});
                console.error(e);
            });
    };

    handlePrevious = () => {
        const {onPrevious} = this.props;
        this.scrollModalBodyToTop();
        onPrevious();
    };

    handleNext = () => {
        const {onNext} = this.props;
        this.scrollModalBodyToTop();
        onNext();
    };

    scrollModalBodyToTop() {
        if (this.modalBodyRef.current) {
            this.modalBodyRef.current.scrollTop = 0;
        }
    }

    handleMatch = () => {
        const {match, onMakeSelection} = this.props;
        onMakeSelection(match.id);
    };
}
