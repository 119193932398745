import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import config from "~/config";
import AspectPropType, {AspectConfig} from "~/prop-types/aspect";
import Weight from "~/components/Weight";
import styles from "./styles.module.scss";
import Checkbox from "~/components/Checkbox";
import {loadEnumValues} from "~/util/filter-values";
import TreePicker from "~/components/TreePicker";

export default class EnumTreeValuesAspectEditor extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        aspectConfig: AspectConfig.isRequired,
        isEditing: PropTypes.bool.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            rawValues: undefined,
            treeModel: [],
        }

        this.unmounted = false;
    }

    componentDidMount() {
        this.updateTreeModel();
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateTreeModel();
    }

    updateTreeModel(prevProps) {
        const {aspectConfig} = this.props;

        if (prevProps === undefined || prevProps.aspectConfig.values !== aspectConfig.values) {
            this.setState({rawValues: aspectConfig.values},() => {
                loadEnumValues(aspectConfig.values).then(treeModel => {
                    if (this.unmounted) {
                        return;
                    }

                    if (this.state.rawValues === aspectConfig.values) {
                        this.setState({treeModel});
                    }
                });
            });
        }
    }

    reset() {
        //
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                {this.renderValues()}
            </Card>
        );
    }

    renderHeader() {
        const {title, aspect, isEditing, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        disabled={!isEditing}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={isEditing}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderValues() {
        const {treeModel} = this.state;
        const {aspect} = this.props;

        const treeValue = aspect.value.map(v => v.label);

        return (
            <TreePicker.Input
                model={treeModel}
                value={treeValue}
                onChange={this.handleValueChange}
            />
        );
    }

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };

    handleValueChange = nextTreeValue => {
        const {name, aspect, onChange} = this.props;

        const nextValue = nextTreeValue.map(label => ({
            id: label,
            weight: config("ui.weight.default"),
            required: false,
            label
        }));

        onChange(name, {
            ...aspect,
            value: nextValue,
        });
    };
}
