import config from "~/config";

export function rescaleWeightIfEnabled(weight, aspectWeightStats) {
    if (config("ui.weight.rescale", false)) {
        return rescaleWeight(weight, aspectWeightStats);
    } else {
        return weight;
    }
}

export function rescaleWeight(weight, aspectWeightStats) {
    let normalizedWeight = weight / aspectWeightStats.sum;
    let uiMax = config("ui.weight.max");

    // Make sure highest is on 85% of the scale, for more intuitive user acceptance
    let correction = 0.85 * normalizedWeight * aspectWeightStats.sum / aspectWeightStats.max;

    return correction * uiMax;
}