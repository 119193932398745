import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import styles from "./styles.module.scss";
import {isString} from "~/util/misc";
import TranslatableLabel from "~/components/TranslatableLabel";

export default class PlaceholderTab extends React.PureComponent {
    static propTypes = {
        tab: PropTypes.object.isRequired,
    };

    render() {
        const {tab} = this.props;

        if (isString(tab.content)) {
            const html = DOMPurify.sanitize(tab.content);
            return (
                <div className={styles.container}>
                    <div dangerouslySetInnerHTML={{__html: html}} />
                </div>
            );
        } else if (React.isValidElement(tab.content)) {
            return <div className={styles.container}>{tab.content}</div>;
        }

        return (
            <div className={styles.container}>
                <TranslatableLabel value={tab.label} />
            </div>
        );
    }
}
