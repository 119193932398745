import Validators from "~/config/validation/validators/Validators";
import {AVAILABLE_LOCALES} from "~/config/base-config";
import ValidationError from "~/config/validation/ValidationError";

export default Validators.objectOf({
    description: "Configuration for i18n",
    values: Validators.objectOf({
        description: "language and messages",
        values: Validators.object({
            description: "property and message",
            defaultValue: {},
        }),
    }).custom((value, path) => {
        for (const key in value){
            if (AVAILABLE_LOCALES[key] !== undefined) {
                throw new ValidationError(path, `invalid language key: ${key}`);
            }
        }
        return value;
    }),
});
