import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";

export default class PoweredByLogo extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    }

    render() {
        const {className} = this.props;

        return (
            <div className={c(styles.container, className)}>
                <span>powered by</span>
                <img className={styles.logo} src="thematchbox.png" alt="theMatchBox"/>
            </div>
        );
    }
}