import PropTypes from "prop-types";
import TranslatableLabelPropType from "~/prop-types/translatable-label";

const rule = PropTypes.shape({
    property: PropTypes.string.isRequired,
    data: PropTypes.any,
});

const groupShape = {combinator: PropTypes.oneOf(["and", "or"]).isRequired};
const group = PropTypes.shape(groupShape);
groupShape.rules = PropTypes.arrayOf(PropTypes.oneOfType([rule, group])).isRequired;

const propertyDefinition = PropTypes.shape({
    label: TranslatableLabelPropType.isRequired,
    type: PropTypes.oneOf(["boolean", "keyword", "number", "age", "enum", "date", "fullText", "rawFilter"]),
    fields: PropTypes.arrayOf(PropTypes.string), // If present, these are used instead of the property key. If more than one field is given, the value any matching field passes the filter. TODO: Currently only supported by EnumProperty
    forcedStative: PropTypes.oneOf(["-", "+"]), // Forces the stative of the date filter
    defaultData: PropTypes.any, // Only supported by BooleanProperty at this time
    alternateTrueValue: PropTypes.any, // Used by the boolean to replace the concept of "true". If set, true means a term filter on this value, and false a must_not version of that filter
    contextIdSet: PropTypes.string, // Used by the boolean to replace the concept of "true". If set, true means a terms filter that checks that the ID is part of this set from the context, and false a must_not version of that filter
    requiresAsyncSelection: PropTypes.bool, // If true, this filter request asynchronous selection data. Careful, this will delay the search request until async data is available
});

const propertyDefinitions = PropTypes.objectOf(propertyDefinition);

export default {
    rule,
    group,
    propertyDefinition,
    propertyDefinitions,
};
