import Validators from "~/config/validation/validators/Validators";

export default Validators.shape({
    description: "Fields for fields in aspects", //todo
    shape: {
        customPath: Validators.string({
            description: "custom path",
            defaultValue:undefined
        }).allowsUndefined,
        type: Validators.oneOf({
            defaultValue: "concepts",
            description:
                "One of: `concepts`, `keywords`, `keywords-tree`, `locations`, `range`, `float`, `integer`.",
            validValues: [
                `concepts`,
                `keywords`,
                `keywords-tree`,
                `locations`,
                `range`,
                `float`,
                `integer`,
            ],
        }),
        weight: Validators.integer({
            description: "Weight of the aspect",
            defaultValue: 100,
        }),
        required: Validators.boolean({
            description: "Required state of the aspect.",
            defaultValue: false,
        }),
        overrideApiRequired: Validators.boolean({
            description:
                "Whether the incoming required state from the API should be ignored in favor of the configured state.",
            defaultValue: false,
        }),
        value: Validators.arrayOf({
            description: "Value of the aspect.",
            defaultValue: [],
            items: Validators.string({
                description: "Value of the aspect.",
            }).allowsUndefined,
        }).allowsNull,
        values: Validators.oneOfType({
            description: "Like custom filter values, currently only supported by `keywords-tree` type (and needs to be tree data).",
            validTypes: [
                Validators.string({
                    description:
                        "Like custom filter values, currently only supported by `keywords-tree` type (and needs to be tree data).",
                }).allowsUndefined,
                Validators.shape({
                    description: "values object shape",
                    shape:{
                        field: Validators.string({
                            description: "field of extraProperties (only in accent-jobs?)",
                        }),
                        endpoint: Validators.string({
                            description: "last part of endpoint to call for value",
                        })
                    }
                })
            ]
        }).allowsUndefined,
        autocomplete: Validators.arrayOf({
            description:
                "If provided, autocomplete is enabled for this aspect. Array of `be.thematchbox.api.ontology.ConceptType` values.",
            items: Validators.string({
                description:
                    "If provided, autocomplete is enabled for this aspect. Array of `be.thematchbox.api.ontology.ConceptType` values.",
            }).allowsUndefined,
        }).allowsUndefined,
        suggestionInput: Validators.boolean({
            description:
                "Whether this aspect's value is used as input for the aspect value suggestions API call.",
            defaultValue: false,
        }),
        allowMultipleLocations: Validators.boolean({
            description:
                "Used for location aspects. If true, multiple locations can be entered in the UI.",
            defaultValue: false,
        }),
    },
});
