import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "~/components/Select";
import {FormattedMessage} from "react-intl";
import ProcessButton from "~/components/ProcessButton";
import TranslatableLabel from "~/prop-types/translatable-label";

export default class CreateNoteDialog extends React.PureComponent {
    static propTypes = {
        subjectId: PropTypes.string.isRequired,
        subjectName: PropTypes.string.isRequired,
        noteTypes: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: TranslatableLabel.isRequired,
            })
        ),
        defaultNoteType: PropTypes.any,
        submitting: PropTypes.bool.isRequired,
        wasError: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        submitting: false,
        wasError: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            noteType: props.defaultNoteType || null,
            noteSubject: "",
            noteContent: "",
        };
    }

    render() {
        const {submitting, wasError, onCancel} = this.props;
        const {noteSubject, noteContent} = this.state;

        return (
            <Modal
                onHide={onCancel}
                show={true}
                centered
                className={styles.modal}
                backdropClassName={styles.backdrop}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title className={styles.ellipsis}>
                        <FormattedMessage id="createNote.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderNoteTypeSelect()}
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="createNote.subject" />
                        </Form.Label>
                        <Form.Control value={noteSubject} onChange={this.handleNoteSubjectChange} />
                    </Form.Group>
                    <Form.Control
                        as="textarea"
                        rows={8}
                        value={noteContent}
                        onChange={this.handleNoteContentChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {wasError && (
                        <div className="text-danger">
                            <FormattedMessage id="createNote.error" />
                        </div>
                    )}
                    <Button variant="secondary" onClick={onCancel}>
                        <FormattedMessage id="action.cancel" />
                    </Button>
                    <ProcessButton
                        label={<FormattedMessage id="createNote.submit" />}
                        processing={submitting}
                        variant="success"
                        onClick={this.handleSubmit}
                        disabled={!this.canSubmit()}
                    />
                </Modal.Footer>
            </Modal>
        );
    }

    renderNoteTypeSelect() {
        const {noteTypes} = this.props;
        const {noteType} = this.state;
        if (!noteTypes) return null;

        const options = [
            {value: null, label: {id: "createNote.selectType"}, disabled: true},
            ...noteTypes,
        ];

        return (
            <React.Fragment>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="createNote.type" />
                    </Form.Label>
                    <Select
                        selected={noteType}
                        options={options}
                        onChange={this.handleNoteTypeChange}
                    />
                </Form.Group>
            </React.Fragment>
        );
    }

    canSubmit() {
        const {submitting} = this.props;
        const {noteSubject} = this.state;
        return !submitting && !!noteSubject;
    }

    handleNoteSubjectChange = e => {
        this.setState({noteSubject: e.target.value});
    };

    handleNoteContentChange = e => {
        this.setState({noteContent: e.target.value});
    };

    handleSubmit = () => {
        const {subjectId, onSubmit} = this.props;
        const {noteType, noteSubject, noteContent} = this.state;
        onSubmit(subjectId, {type: noteType, subject: noteSubject, body: noteContent});
    };

    handleNoteTypeChange = option => {
        this.setState({noteType: option.value});
    };
}
