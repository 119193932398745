import objectPath from "object-path";
import ProfileType from "~/enums/ProfileType";
import Api from "~/api";
import uuid from "uuid/v4";
import * as immutable from "object-path-immutable";
import FetchState from "~/enums/FetchState";
import ContentDisposition from "content-disposition";
import config from "~/config";
import MatchSourceEntity from "~/entities/MatchSourceEntity";
import {fetchBackendSelection} from "~/actions/util/backend-selection";
import BackendSelection from "~/util/BackendSelection";
import IndexDocumentType from "~/enums/IndexDocumentType";
import {triggerErrorMessage} from "~/actions/ui";
import ErrorMessage from "~/entities/ErrorMessage";
import {predicateRequiresAsyncSelection} from "~/components/PredicateEditor/util";
import ApiCallError from "~/api/ApiCallError";

export const CREATE_CONTEXT = "matching/CREATE_CONTEXT";
export const NEW_MATCH_STARTED = "matching/NEW_MATCH_STARTED";
export const PAGE_REQUESTED = "matching/PAGE_REQUESTED";
export const PAGE_RECEIVED = "matching/PAGE_RECEIVED";
export const RESULT_ANNOTATIONS_RECEIVED = "matching/RESULT_ANNOTATIONS_RECEIVED";
export const PAGE_REQUEST_ERROR = "matching/PAGE_REQUEST_ERROR";
export const BACKEND_SELECTION_REQUESTED = "matching/BACKEND_SELECTION_REQUESTED";
export const BACKEND_SELECTION_RECEIVED = "matching/BACKEND_SELECTION_RECEIVED";
export const BACKEND_SELECTION_REQUEST_ERROR = "matching/BACKEND_SELECTION_REQUEST_ERROR";
export const SET_QUERY = "matching/SET_QUERY";
export const SET_NEXT_REQUEST = "matching/SET_NEXT_REQUEST";
export const SET_LAST_REQUEST = "matching/SET_LAST_REQUEST";
export const CLEAR_NEXT_REQUEST = "matching/CLEAR_NEXT_REQUEST";
export const RESET_NEXT_REQUEST = "matching/RESET_NEXT_REQUEST";
export const SET_SELECTION = "matching/SET_SELECTION";
export const SET_DISPLAYED_PAGE = "matching/SET_DISPLAYED_PAGE";
export const SET_MATCH_SOURCE_ENTITY = "matching/SET_MATCH_SOURCE_ENTITY";
export const MATCH_SOURCE_ENTITY_LOADED = "matching/MATCH_SOURCE_ENTITY_LOADED";
export const SET_INDEX_NEXT_REQUEST = "matching/SET_INDEX_NEXT_REQUEST";
export const SET_INDEX_LAST_REQUEST = "matching/SET_INDEX_LAST_REQUEST";
export const SET_SORT_MODE = "matching/SET_SORT_MODE";
export const SET_SELECTION_STATUS = "matching/SET_SELECTION_STATUS";
export const UPDATE_UI_STATE = "matching/UPDATE_UI_STATE";
export const REPLACE_MATCH = "matching/REPLACE_MATCH";
export const SET_SEARCH_STRATEGY_NAME = "matching/SET_SEARCH_STRATEGY_NAME";

const BACKEND_SELECTION_SPACING_MILLISECONDS = 5 * 60 * 1000;

export function createMatchingContext(id, configurationFn) {
    return (dispatch, getState) => {
        const configuration = configurationFn(getState());

        dispatch({
            type: CREATE_CONTEXT,
            id,
            configurationFn,
            configuration,
        });
    };
}

export function actionsForMatchingContext(contextId) {
    const actions = {
        newMatch,
        updateMatchForIndex,
        requestPage,
        makeSelection,
        setMatchSourceEntity,
        setQuery,
        setNextRequest,
        setIndexNextRequest,
        clearNextRequest,
        resetNextRequest,
        setSelection,
        downloadMatchResults,
        setSortMode,
        setSelectionStatus,
        updateUiState,
        selectAllFromApi,
        setSearchStrategyName,
    };

    return Object.keys(actions).reduce((mappedActions, key) => {
        mappedActions[key] = actions[key].bind(undefined, contextId);
        return mappedActions;
    }, {});
}

export function replaceMatch(contextId, indexName, matchId, data) {
    return {
        type: REPLACE_MATCH,
        contextId,
        indexName,
        matchId,
        data,
    };
}

function newMatch(contextId, dontSaveRequest) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());
        const promises = [];

        dispatch(newMatchStarted(contextId));

        for (let i = 0; i < configuration.indices.length; i++) {
            const indexConfig = configuration.indices[i];
            if (indexConfig.disabled) continue;

            const indexState = matchingContext.results[indexConfig.name];

            promises.push(
                startMatchForIndex(
                    dispatch,
                    getState,
                    configuration,
                    contextId,
                    matchingContext,
                    indexConfig,
                    indexState,
                    matchingContext.nextRequest,
                    dontSaveRequest
                )
            );
        }

        dispatch(setLastRequest(contextId, matchingContext.nextRequest, dontSaveRequest));
        return Promise.all(promises);
    };
}

function updateMatchForIndex(contextId, indexConfig) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const indexState = matchingContext.results[indexConfig.name];
        const configuration = matchingContext.configurationFn(getState());

        dispatch(newMatchStarted(contextId, indexConfig));

        return startMatchForIndex(
            dispatch,
            getState,
            configuration,
            contextId,
            matchingContext,
            indexConfig,
            indexState,
            matchingContext.lastRequest,
            false
        );
    };
}

function startMatchForIndex(
    dispatch,
    getState,
    configuration,
    contextId,
    matchingContext,
    indexConfig,
    indexState,
    contextNextRequest,
    dontSaveRequest
) {
    const promiseId = uuid();
    dispatch(pageRequested(contextId, indexConfig, 0, promiseId, undefined));

    return requestBackendSelectionIfNeeded(
        configuration,
        dispatch,
        contextId,
        indexConfig,
        indexState,
        matchingContext
    ).then(backendSelection => {
        const currentlyActivePromiseId = immutable.get(getState(), [
            "matching",
            "contexts",
            contextId,
            "results",
            indexConfig.name,
            "pages",
            0,
            "promiseId",
        ]);

        if (currentlyActivePromiseId !== promiseId) {
            return Promise.resolve();
        }

        const finalIndexNextRequest = {...indexState.nextRequest};

        if (config("matching.excludeExistingMatches")) {
            finalIndexNextRequest.excludedIds = [
                ...finalIndexNextRequest.excludedIds,
                ...backendSelection.getExcludedIds(indexConfig.resultType),
            ];
        }

        const matchRequest = {
            ...contextNextRequest,
            ...finalIndexNextRequest,
        };

        const promise = apiCallPromiseForIndex(getState(), contextId, indexConfig, {
            matchRequest,
            from: 0,
            size: indexConfig.pageSize,
        })
            .then(response => {
                const {matches, ids, count} = convertMatchResponse(response);
                dispatch(pageReceived(contextId, indexConfig, 0, promiseId, matches, ids, count));
                requestAnnotationsIfNeeded(dispatch, contextId, indexConfig, 0, promiseId, ids);
            })
            .catch(() => {
                dispatch(pageRequestError(contextId, indexConfig, 0, promiseId));
            });

        dispatch(
            setIndexLastRequest(contextId, indexConfig, finalIndexNextRequest, dontSaveRequest)
        );
        dispatch(pageRequested(contextId, indexConfig, 0, promiseId, promise));
        return promise;
    });
}

function requestPage(contextId, index, pageNumber) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const indexResults = objectPath.get(matchingContext, ["results", index.name]);
        const page = objectPath.get(indexResults, ["pages", pageNumber], undefined);

        if (page !== undefined) {
            if (page.state === FetchState.FETCHED) {
                dispatch(setDisplayedPage(contextId, index, pageNumber));
                return page.promise;
            } else if (page.state === FetchState.FETCHING) {
                return page.promise;
            }
        }

        const promiseId = uuid();
        const promise = apiCallPromiseForIndex(getState(), contextId, index, {
            from: pageNumber * index.pageSize,
            size: index.pageSize,
        })
            .then(response => {
                const {matches, ids, count} = convertMatchResponse(response);
                dispatch(
                    pageReceived(contextId, index, pageNumber, promiseId, matches, ids, count)
                );
                requestAnnotationsIfNeeded(dispatch, contextId, index, pageNumber, promiseId, ids);
            })
            .catch(() => {
                dispatch(pageRequestError(contextId, index, pageNumber, promiseId));
            });

        dispatch(pageRequested(contextId, index, pageNumber, promiseId, promise));
        return promise;
    };
}

function makeSelection(contextId, index, selection, selectionStatus = undefined) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        if (!settingSelectionPossible(index, matchingContext)) {
            return;
        }

        const indexResults = matchingContext.results[index.name];
        const backendSelection = indexResults.backendSelection.data;
        const filteredSelection = backendSelection.removeExistingSelectionFrom(selection);

        if (filteredSelection.length > 0) {
            const promise = Api.makeSelection(
                index.selectionEndpoints.set,
                matchingContext.matchSourceEntity.id,
                filteredSelection,
                selectionStatus !== undefined ? selectionStatus : indexResults.selectionStatus
            )
                .then(({response}) => {
                    if (response.hasErrors) {
                        console.error(response);
                        throw new Error("Problem setting selection:" + response.message);
                    }

                    setTimeout(() => {
                        if (config("ui.runAfterSelection", undefined) !== undefined) {
                            config("ui.runAfterSelection", undefined)();
                        }
                    }, 1);

                    if (!gettingSelectionPossible(index, matchingContext)) {
                        return Promise.resolve(BackendSelection.empty());
                    }

                    return fetchBackendSelection(
                        configuration,
                        index,
                        matchingContext.matchSourceEntity.id,
                        0
                    );
                })
                .then(({backendSelection, backendSelectionAsyncPromise}) => {
                    let alreadyReportedError = false;

                    if (!backendSelection.success) {
                        dispatch(
                            triggerErrorMessage(
                                new ErrorMessage(
                                    "error.title.getSelectionError",
                                    "error.message.getSelectionError",
                                    backendSelection.remoteErrorMessage
                                )
                            )
                        );
                        alreadyReportedError = true;
                    }

                    backendSelection = backendSelection.addToExistingSelection(
                        index.resultType,
                        filteredSelection
                    );
                    dispatch(backendSelectionReceived(contextId, index, backendSelection));
                    dispatch(setSelection(contextId, index, []));

                    backendSelectionAsyncPromise.then(backendSelection => {
                        if (!backendSelection.success && !alreadyReportedError) {
                            dispatch(
                                triggerErrorMessage(
                                    new ErrorMessage(
                                        "error.title.getAsyncSelectionError",
                                        "error.message.getAsyncSelectionError",
                                        backendSelection.remoteErrorMessage
                                    )
                                )
                            );
                        }

                        backendSelection = backendSelection.addToExistingSelection(
                            index.resultType,
                            filteredSelection
                        );
                        dispatch(backendSelectionReceived(contextId, index, backendSelection));
                    });
                })
                .catch(error => {
                    if (error instanceof ApiCallError && error.request.status === 502) {
                        dispatch(
                            triggerErrorMessage(
                                new ErrorMessage(
                                    "error.title.selectionStillOngoing",
                                    "error.message.selectionStillOngoing",
                                    backendSelection.remoteErrorMessage
                                )
                            )
                        );
                    } else {
                        dispatch(backendSelectionRequestError(contextId, index));
                        console.error(error);
                    }
                });

            dispatch(backendSelectionRequested(contextId, index, true));
            return promise;
        } else {
            dispatch(setSelection(contextId, index, []));
            return Promise.resolve();
        }
    };
}

function requestBackendSelectionIfNeeded(
    configuration,
    dispatch,
    contextId,
    indexConfig,
    indexState,
    matchingContext
) {
    if (!gettingSelectionPossible(indexConfig, matchingContext)) {
        return Promise.resolve(BackendSelection.empty());
    }

    const propertyDefinitions = config(`customFilters.${indexConfig.name}`, undefined);

    const requiresAsyncSelection = predicateRequiresAsyncSelection(
        indexState.nextRequest.customFiltersPredicate,
        propertyDefinitions,
        getFilterContext(indexState)
    );

    backendSelectionRequested(contextId, indexConfig, false);

    return fetchBackendSelection(
        configuration,
        indexConfig,
        matchingContext.matchSourceEntity.id,
        BACKEND_SELECTION_SPACING_MILLISECONDS
    ).then(({backendSelection, backendSelectionAsyncPromise}) => {
        let alreadyReportedError = false;

        if (!backendSelection.success) {
            dispatch(
                triggerErrorMessage(
                    new ErrorMessage(
                        "error.title.getSelectionError",
                        "error.message.getSelectionError",
                        backendSelection.remoteErrorMessage
                    )
                )
            );
            alreadyReportedError = true;
        }

        dispatch(backendSelectionReceived(contextId, indexConfig, backendSelection));

        backendSelectionAsyncPromise.then(backendSelection => {
            if (!backendSelection.success && !alreadyReportedError) {
                dispatch(
                    triggerErrorMessage(
                        new ErrorMessage(
                            "error.title.getAsyncSelectionError",
                            "error.message.getAsyncSelectionError",
                            backendSelection.remoteErrorMessage
                        )
                    )
                );
            }

            dispatch(backendSelectionReceived(contextId, indexConfig, backendSelection));
        });

        if (!requiresAsyncSelection) {
            return backendSelection;
        } else {
            return backendSelectionAsyncPromise;
        }
    });
}

function requestAnnotationsIfNeeded(dispatch, contextId, index, pageNumber, promiseId, ids) {
    if (index.resultAnnotationEndpoint === undefined) {
        return;
    }

    Api.post(index.resultAnnotationEndpoint, {ids})
        .then(({response}) => {
            dispatch(
                resultAnnotationsReceived(
                    contextId,
                    index,
                    pageNumber,
                    promiseId,
                    response.annotationsById
                )
            );
        })
        .catch(error => {
            console.error("Couldn't get result annotations", error);
        });
}

function gettingSelectionPossible(index, matchingContext) {
    return (
        index.allowSelection &&
        index.selectionEndpoints &&
        index.selectionEndpoints.get &&
        matchingContext.matchSourceEntity &&
        matchingContext.matchSourceEntity.hasLoaded
    );
}

function settingSelectionPossible(index, matchingContext) {
    return (
        index.allowSelection &&
        index.selectionEndpoints &&
        index.selectionEndpoints.set &&
        matchingContext.matchSourceEntity &&
        matchingContext.matchSourceEntity.hasLoaded
    );
}

function newMatchStarted(contextId, singleIndex) {
    return {
        type: NEW_MATCH_STARTED,
        contextId,
        singleIndex,
    };
}

function setLastRequest(contextId, lastRequest, dontSaveRequest) {
    return {
        type: SET_LAST_REQUEST,
        contextId,
        lastRequest,
        dontSaveRequest,
    };
}

function setIndexLastRequest(contextId, index, lastRequest, dontSaveRequest) {
    return {
        type: SET_INDEX_LAST_REQUEST,
        contextId,
        index,
        lastRequest,
        dontSaveRequest,
    };
}

function pageRequested(contextId, index, pageNumber, promiseId, promise) {
    return {
        type: PAGE_REQUESTED,
        contextId,
        index,
        pageNumber,
        promiseId,
        promise,
    };
}

function pageReceived(contextId, index, pageNumber, promiseId, matches, ids, count) {
    return {
        type: PAGE_RECEIVED,
        contextId,
        index,
        pageNumber,
        promiseId,
        matches,
        ids,
        count,
    };
}

function pageRequestError(contextId, index, pageNumber, promiseId) {
    return {
        type: PAGE_REQUEST_ERROR,
        contextId,
        index,
        pageNumber,
        promiseId,
    };
}

function resultAnnotationsReceived(contextId, index, pageNumber, promiseId, annotations) {
    return {
        type: RESULT_ANNOTATIONS_RECEIVED,
        contextId,
        index,
        pageNumber,
        promiseId,
        annotations,
    };
}

function backendSelectionRequested(contextId, index, isMakingSelection) {
    return {
        type: BACKEND_SELECTION_REQUESTED,
        contextId,
        index,
        isMakingSelection,
    };
}

function backendSelectionReceived(contextId, index, data) {
    return {
        type: BACKEND_SELECTION_RECEIVED,
        contextId,
        index,
        data,
    };
}

function backendSelectionRequestError(contextId, index) {
    return {
        type: BACKEND_SELECTION_REQUEST_ERROR,
        contextId,
        index,
    };
}

function setDisplayedPage(contextId, index, pageNumber) {
    return {
        type: SET_DISPLAYED_PAGE,
        contextId,
        index,
        pageNumber,
    };
}

function setMatchSourceEntity(contextId, matchSourceEntity) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        if (!MatchSourceEntity.areSame(matchingContext.matchSourceEntity, matchSourceEntity)) {
            dispatch({
                type: SET_MATCH_SOURCE_ENTITY,
                contextId,
                configuration,
                matchSourceEntity,
            });

            if (matchSourceEntity !== undefined) {
                return matchSourceEntity.documentPromise
                    .then(() => {
                        dispatch({
                            type: MATCH_SOURCE_ENTITY_LOADED,
                            contextId,
                            configuration,
                            matchSourceEntity,
                        });

                        dispatch(newMatch(contextId, true));
                    });
            } else {
                dispatch(clearNextRequest(contextId));
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    };
}

function setQuery(contextId, query) {
    return {
        type: SET_QUERY,
        contextId,
        query,
    };
}

export function setNextRequest(contextId, nextRequest) {
    return {
        type: SET_NEXT_REQUEST,
        contextId,
        nextRequest,
    };
}

function clearNextRequest(contextId, overrides) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        dispatch({
            type: CLEAR_NEXT_REQUEST,
            contextId,
            configuration,
            overrides,
        });
    };
}

function resetNextRequest(contextId) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());
        let reloadPromise;

        if (matchingContext.matchSourceEntity) {
            reloadPromise = matchingContext.matchSourceEntity
                .reload()
                .then(() => {
                    dispatch({
                        type: MATCH_SOURCE_ENTITY_LOADED,
                        contextId,
                        configuration,
                        matchSourceEntity: matchingContext.matchSourceEntity,
                    });
                })
                .catch(error => {
                    console.error("Couldn't reload document", error);
                });
        } else {
            reloadPromise = Promise.resolve();
        }

        return reloadPromise.finally(() => {
            dispatch({
                type: RESET_NEXT_REQUEST,
                contextId,
                configuration,
            });
        });
    };
}

function setSelection(contextId, index, selection) {
    return {
        type: SET_SELECTION,
        contextId,
        index,
        selection,
    };
}

function downloadMatchResults(contextId, indexConfig) {
    return (dispatch, getState) => {
        const globalState = getState();
        const matchingContext = getMatchingContext(globalState, contextId);
        const indexState = matchingContext.results[indexConfig.name];

        return apiCallFunctionForDownload(indexConfig)({
            candidateIndex: indexConfig.name,
            matchRequest: {
                ...matchingContext.lastRequest,
                ...indexState.lastRequest,
            },
            filterContext: getFilterContext(indexState),
            language: globalState.ui.language,
            sortModeGroup: indexConfig.sortModeGroup,
            aspectsSupportedForSearch: indexConfig.aspectsSupportedForSearch,
            scoreType: indexConfig.scoreType,
            expansionType: indexConfig.expansionType,
            exportType: indexConfig.exportType,
            exportSize: indexConfig.exportSize,
        })
            .then(({response, request}) => {
                const details = ContentDisposition.parse(
                    request.getResponseHeader("content-disposition")
                );
                const contentType = request.getResponseHeader("Content-Type");
                const blob = new Blob([response], {type: contentType});
                blob.name = details.parameters.filename;
                const reader = new FileReader();
                reader.onload = e => {
                    const anchor = document.createElement("a");
                    anchor.style.display = "none";
                    anchor.href = e.target.result;
                    anchor.download = blob.name;
                    anchor.click();
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error("Couldn't download results", error);
            });
    };
}

function apiCallFunctionForDownload(index) {
    const apiVersion = config("api.version");

    if (apiVersion.gte("2.2.0") && !index.useOldHrMatchingApi) {
        return Api.downloadProfileToCandidatesV2.bind(Api);
    } else {
        return Api.downloadProfileToCandidates.bind(Api);
    }
}

export function setIndexNextRequest(contextId, index, nextRequest) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        dispatch({
            type: SET_INDEX_NEXT_REQUEST,
            contextId,
            configuration,
            index,
            nextRequest,
        });
    };
}

function setSortMode(contextId, index, sortMode) {
    return {
        type: SET_SORT_MODE,
        contextId,
        index,
        sortMode,
    };
}

function setSelectionStatus(contextId, index, selectionStatus) {
    return {
        type: SET_SELECTION_STATUS,
        contextId,
        index,
        selectionStatus,
    };
}

function updateUiState(contextId, data) {
    return {
        type: UPDATE_UI_STATE,
        contextId,
        data,
    };
}

function selectAllFromApi(contextId, index, amount = undefined) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const indexState = matchingContext.results[index.name];

        if (!indexState) {
            dispatch(setSelection(contextId, index, []));
            return;
        }

        return apiCallPromiseForIndex(getState(), contextId, index, {
            from: 0,
            size:
                amount !== undefined
                    ? Math.min(amount, index.selectAllLimit)
                    : index.selectAllLimit,
            _source: false,
        })
            .then(response => {
                const {ids} = convertMatchResponse(response);
                dispatch(setSelection(contextId, index, ids));
            })
            .catch(e => {
                console.error(e);
                dispatch(setSelection(contextId, index, []));
            });
    };
}

function setSearchStrategyName(contextId, searchStrategyName) {
    return (dispatch, getState) => {
        const matchingContext = getMatchingContext(getState(), contextId);
        const configuration = matchingContext.configurationFn(getState());

        dispatch({
            type: SET_SEARCH_STRATEGY_NAME,
            contextId,
            searchStrategyName,
        });

        for (let i = 0; i < configuration.indices.length; i++) {
            const indexConfig = configuration.indices[i];
            const indexState = matchingContext.results[indexConfig.name];

            // This will cause the matchingStrategyName to be set to the correct value
            // TODO: Better solution?
            dispatch(setIndexNextRequest(contextId, indexConfig, {
                ...indexState.nextRequest,
                matchingStrategyName: undefined,
            }));
        }
    };
}

function apiCallPromiseForIndex(globalState, contextId, indexConfig, overrides) {
    const matchingContext = getMatchingContext(globalState, contextId);
    const indexState = matchingContext.results[indexConfig.name];

    return apiCallFunctionForIndex(indexConfig)({
        index: indexConfig.name,
        matchRequest: {
            ...matchingContext.lastRequest,
            ...indexState.lastRequest,
        },
        filterContext: getFilterContext(indexState),
        from: 0,
        size: 0,
        language: globalState.ui.language,
        sortModeGroup: indexConfig.sortModeGroup,
        scoreType: indexConfig.scoreType,
        expansionType: indexConfig.expansionType,
        conversionPreProcessor: indexConfig.apiConversionPreProcessor,
        forcedFilters: indexConfig.forcedFilters,
        aspectsSupportedForSearch: indexConfig.aspectsSupportedForSearch,
        ...overrides,
    });
}

function apiCallFunctionForIndex(indexConfig) {
    if (indexConfig.indexDocumentType === IndexDocumentType.JOB_PROFILE) {
        return Api.matchProfileToJobsV1.bind(Api);
    } else if (indexConfig.indexDocumentType === IndexDocumentType.CANDIDATE_PROFILE) {
        return Api.matchProfileToCandidatesV1.bind(Api);
    }

    const apiVersion = config("api.version");

    if (apiVersion.gte("2.2.0") && !indexConfig.useOldHrMatchingApi) {
        switch (indexConfig.resultType) {
            case ProfileType.JOB:
                return Api.matchProfileToJobsV2.bind(Api);

            case ProfileType.CANDIDATE:
                return Api.matchProfileToCandidatesV2.bind(Api);

            default:
                throw new Error(`Unsupported result type: ${indexConfig.resultType}`);
        }
    } else {
        switch (indexConfig.resultType) {
            case ProfileType.JOB:
                return Api.matchProfileToJobsV1.bind(Api);

            case ProfileType.CANDIDATE:
                return Api.matchProfileToCandidatesV1.bind(Api);

            default:
                throw new Error(`Unsupported result type: ${indexConfig.resultType}`);
        }
    }
}

function convertMatchResponse({matches, metadata}) {
    const matchesById = {};
    const ids = [];

    for (const match of matches) {
        matchesById[match.id] = match;
        ids.push(match.id);
    }

    return {
        matches: matchesById,
        ids,
        count: metadata.count,
    };
}

function getMatchingContext(state, contextId) {
    const matchingContext = state.matching.contexts[contextId];

    if (matchingContext === undefined) {
        throw new Error(`A matching context with ID ${contextId} doesn't exist.`);
    }

    return matchingContext;
}

function getFilterContext(indexState) {
    return {
        candidatesForJob: Object.keys(indexState.backendSelection.data.candidatesForJob),
        candidatesForCompany: Object.keys(indexState.backendSelection.data.candidatesForCompany),
        jobsForCandidate: Object.keys(indexState.backendSelection.data.jobsForCandidate),
        companiesForCandidate: Object.keys(indexState.backendSelection.data.companiesForCandidate),
    };
}
