import {isPlainObject, removeUndefinedValues} from "~/util/misc";
import {convertMatchRequestToApiV2} from "~/util/convert-to-api";
import JobMatch from "~/entities/JobMatch";
import {convertCandidateFromApi, convertJobFromApi} from "~/util/convert-from-api";
import CandidateMatch from "~/entities/CandidateMatch";

export default function addV2Calls(Client) {
    Client.prototype.matchProfileToCandidatesV2 = function({
        candidateIndex,
        index,
        matchRequest,
        filterContext,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        conversionPreProcessor,
        _source,
        forcedFilters,
        aspectsSupportedForSearch,
    }) {
        const theIndex = index || candidateIndex;

        return this.post(
            `/matching/${theIndex}/match`,
            removeUndefinedValues({
                ...convertMatchRequestToApiV2(
                    matchRequest,
                    filterContext || {},
                    theIndex,
                    language,
                    sortModeGroup,
                    forcedFilters,
                    aspectsSupportedForSearch
                ),
                from,
                size,
                _source,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(x =>
                convertCandidateApiMatchV2(conversionPreProcessor, x)
            );

            return {matches, metadata};
        });
    };

    Client.prototype.matchProfileToJobsV2 = function({
        jobIndex,
        index,
        matchRequest,
        filterContext,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        conversionPreProcessor,
        _source,
        forcedFilters,
        aspectsSupportedForSearch,
    }) {
        const theIndex = index || jobIndex;

        return this.post(
            `/matching/${theIndex}/match`,
            removeUndefinedValues({
                ...convertMatchRequestToApiV2(
                    matchRequest,
                    filterContext || {},
                    theIndex,
                    language,
                    sortModeGroup,
                    forcedFilters,
                    aspectsSupportedForSearch
                ),
                from,
                size,
                _source,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(x =>
                convertJobApiMatchV2(conversionPreProcessor, x)
            );

            return {matches, metadata};
        });
    };

    Client.prototype.fetchJobV2 = function(jobIndex, id, matchingStrategy, language) {
        return this.get(
            `/matching/${jobIndex}/profile/${id}`,
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
                language,
            })
        ).then(({response}) => {
            // TODO
            if (response.jobTitle) {
                response.jobTitle = response.jobTitle.text;
            }

            if (response.jobText) {
                response.jobText = response.jobText.text;
            }
            return convertJobFromApi(response);
        });
    };

    Client.prototype.fetchCandidateV2 = function(candidateIndex, id, matchingStrategy, language) {
        return this.get(
            `/matching/${candidateIndex}/profile/${id}`,
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
                language,
            })
        ).then(({response}) => convertCandidateFromApi(response));
    };

    Client.prototype.matchDetailsForJobToCandidateV2 = function(
        sourceIndex,
        sourceId,
        targetIndex,
        targetId,
        language,
        matchingStrategy,
        conversionPreProcessor
    ) {
        return this.post(
            `/matching/${targetIndex}/matchFrom/${sourceIndex}/profile/${sourceId}`,
            removeUndefinedValues({
                matchFilter: {
                    bool: {
                        must: {
                            ids: {values: [targetId]},
                        },
                    },
                },
                from: 0,
                size: 1,
                userLanguage: language,
                matchingStrategyName: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => {
            return response.matches.map(x => convertCandidateApiMatchV2(conversionPreProcessor, x));
        });
    };

    Client.prototype.downloadProfileToCandidatesV2 = function({
        candidateIndex,
        matchRequest,
        filterContext,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        exportType,
        exportSize,
        forcedFilters,
        aspectsSupportedForSearch,
    }) {
        return this.post(
            `/matching/${candidateIndex}/matchAndExport/${exportType}`,
            removeUndefinedValues({
                ...convertMatchRequestToApiV2(
                    matchRequest,
                    filterContext || {},
                    candidateIndex,
                    language,
                    sortModeGroup,
                    forcedFilters,
                    aspectsSupportedForSearch
                ),
                from: 0,
                size: exportSize,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            }),
            {},
            {},
            {
                dontDecode: true,
                responseType: "arraybuffer",
            }
        );
    };
}

function convertCandidateApiMatchV2(preprocessor, match) {
    if (match.document) {
        if (match.document.cvText) {
            match.document.cvText = match.document.cvText.text;
        }

        match.document.extraProperties = removeAspectTypeParents(match.document.extraProperties);

        if (preprocessor && match.document) {
            match.document = preprocessor(match.document);
        }
    }

    return new CandidateMatch(match);
}

function convertJobApiMatchV2(preprocessor, match) {
    if (match.document) {
        if (match.document.jobTitle) {
            match.document.jobTitle = match.document.jobTitle.text;
        }

        if (match.document.jobText) {
            match.document.jobText = match.document.jobText.text;
        }

        match.document.extraProperties = removeAspectTypeParents(match.document.extraProperties);

        if (preprocessor && match.document) {
            match.document = preprocessor(match.document);
        }
    }

    return new JobMatch(match);
}

function removeAspectTypeParents(input) {
    if (Array.isArray(input)) {
        return input.map(removeAspectTypeParents);
    } else if (!isPlainObject(input)) {
        return input;
    }

    // Return the processed child if this is an aspectType parent
    if (input.value !== undefined && input.aspectType !== undefined) {
        return removeAspectTypeParents(input.value);
    }

    // Handle all child values
    const output = {};

    for (const key in input) {
        output[key] = removeAspectTypeParents(input[key]);
    }

    return output;
}
