import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";

export default class OneOfTypeValidator extends Validator {
    constructor(description, defaultValue, validTypes) {
        super(description, defaultValue);
        this.validTypes = validTypes;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @param rootValue
     * @return {any}
     */
    validate(value, path = undefined, rootValue) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path, rootValue);
        } else if (value === undefined) {
            throw new ValidationError(path, `Expected one of several types, but got undefined`);
        }

        for (const validType of this.validTypes) {
            try {
                return validType.validate(value, path, rootValue);
            } catch (e) {
                if (e instanceof ValidationError) {
                    // Do nothing
                } else {
                    throw e;
                }
            }
        }

        throw new ValidationError(path, `Expected one of several types, but none matched for ${value}`);
    }
}
