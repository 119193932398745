import {combineReducers} from "redux";
import textMining from "./text-mining";
import matching from "./matching";
import matchingStrategy from "./matching-strategy";
import monitoring from "./monitoring";
import ui from "./ui";
import standaloneMatchDetails from "./standalone-match-details";

const rootReducer = combineReducers({
    matching,
    matchingStrategy,
    monitoring,
    standaloneMatchDetails,
    textMining,
    ui,
});

export default rootReducer;