import enMessages from "~/i18n/messages/en.json";
import nlMessages from "~/i18n/messages/nl.json";
import frMessages from "~/i18n/messages/fr.json";
import itMessages from "~/i18n/messages/it.json";
import deMessages from "~/i18n/messages/de.json";
import ScoreType from "~/enums/ScoreType";
import Version from "~/config/Version";

export const AVAILABLE_LOCALES = ["nl", "fr", "en", "it", "de"];

/**
 * This configuration should contain backwards compatible defaults, so that newly added features do not change the UI
 * of existing variants. If you need to test things for development purposes, use the "development" variant.
 *
 * Booleans must be named in such a way that their default value is false.
 */
export default {
    api: {
        // The base URL of the API. Should include the `/api` portion of the URL.
        url: undefined,
        // The default `language` value used when making API calls. This value can be modified in the UI. If undefined, this is set based on the locale.
        defaultLanguage: undefined,
        // Additional headers to send to the API for each call
        additionalHeaders: {},
        // API endpoints that will be called and are allowed to overwrite the final configuration.
        configurationEndpoints: [],
        // API endpoints to get and set selected candidates for jobs, or selected jobs for candidates, in a customer specific system.
        selectionEndpoints: {
            job: {
                get: undefined,
                set: undefined,
            },
            candidate: {
                get: undefined,
                set: undefined,
            },
        },
        // API endpoint to use for exchanging the OTP
        otpExchangeEndpoint: undefined,
        //
        version: Version.of("1.0.0"),
    },
    debug: {
        // Enables debug mode. Deprecated and almost unused.
        enabled: false,
    },
    features: {
        // Provides API auhtentication through Auth0.
        auth0: {
            enabled: false,
            audience: "https://api.thematchbox.be/services",
            // Space-separated.
            scopes: "read:hrmatching read:parsing read:ontology",
            domain: "thematchbox.eu.auth0.com",
            clientId: "QQ1d3UvPH8YcOQCDGFu8gnlreaffpqGp",
        },
        profileToJobs: {
            enabled: false,
            // Displays the keyword search bar above the profile editor, and the Match button moves to the keyword search bar
            keywordSearch: false,
            // Enables keyword suggestions (that is, value suggestions for certain aspects based on existing values).
            keywordSuggestions: false,
            // The indices to match against. See indexDefaults below.
            indices: [],
            // Placeholder tabs
            placeholderTabs: [],
        },
        profileToCandidates: {
            enabled: false,
            // Displays the keyword search bar above the profile editor, and the Match button moves to the keyword search bar
            keywordSearch: false,
            // Enables keyword suggestions (that is, value suggestions for certain aspects based on existing values).
            keywordSuggestions: false,
            // The indices to match against. See indexDefaults below.
            indices: [],
            // Placeholder tabs
            placeholderTabs: [],
        },
        // The job-to-candidates matching UI, which is a process with several steps: providing a job description, job parsing, and finally candidate matching.
        jobToCandidates: {
            enabled: false,
            // Displays the keyword search bar above the profile editor, and the Match button moves to the keyword search bar
            keywordSearch: false,
            // Enables keyword suggestions (that is, value suggestions for certain aspects based on existing values).
            keywordSuggestions: false,
            // The indices to match against. See indexDefaults below.
            indices: [],
            // Placeholder tabs
            placeholderTabs: [],
        },
        textMining: {
            enabled: false,
            // Case insenstive name of the parser class to use.
            parser: undefined,
            // Input type used for parsing. One of: `txt`, `csv`.
            inputType: "csv",
        },
        matchingStrategy: {
            enabled: false,
        },
        monitoring: {
            enabled: false,
        },
        standaloneMatchDetails: {
            indexSettings: {
                //
            }
        }
    },
    aspects: {
        jobFunctions: {
            // One of: `concepts`, `keywords`, `keywords-tree`, `locations`, `range`, `float`, `integer`.
            type: "concepts",
            // Default weight of the aspect.
            weight: 100,
            // Default required state of the aspect.
            required: false,
            // Whether the incoming required state from the API should be ignored in favor of the configured state
            overrideApiRequired: false,
            // Default value of the aspect.
            value: [],
            // Like custom filter values, currently only supported by `keywords-tree` type (and needs to be tree data)
            values: undefined,
            // If provided, autocomplete is enabled for this aspect. Array of `be.thematchbox.api.ontology.ConceptType` values.
            autocomplete: ["AGENT"],
            // Whether this aspect's value is used as input for the aspect value suggestions API call.
            suggestionInput: true,
        },
        hardSkills: {
            type: "concepts",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            autocomplete: ["PROPERTY"],
            suggestionInput: true,
        },
        softSkills: {
            type: "concepts",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            suggestionInput: false,
        },
        jobCategories: {
            type: "keywords",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            suggestionInput: false,
        },
        contractTypes: {
            type: "keywords",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            suggestionInput: false,
        },
        workingHours: {
            type: "keywords",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            suggestionInput: false,
        },
        locations: {
            type: "locations",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            suggestionInput: false,
        },
        wage: {
            type: "range",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: null,
            suggestionInput: false,
        },
        languages: {
            type: "concepts",
            weight: 100,
            required: false,
            overrideApiRequired: false,
            value: [],
            suggestionInput: false,
        },
    },
    ui: {
        // Black/white list of enabled aspects. See the BlackWhiteList class.
        enabledAspects: "jobFunctions,hardSkills,languages,contractTypes,workingHours,locations",
        // Order the aspects are displayed in the match profile editor. **IMPORTANT: ** Even if an aspect is not displayed, its value is still sent to the API as long as its enabled.
        aspectOrder: [
            "jobFunctions",
            "hardSkills",
            "languages",
            "contractTypes",
            "workingHours",
            "locations",
        ],
        // Configuration of how the weight is displayed in the UI. This influences how precisely a user can set the weight.
        weight: {
            // The value used by the UI for a weight of 1 on the API side.
            scale: 100,
            // The minimum weight, as a UI value.
            min: 1,
            // The maximum weight, as a UI value.
            max: 200,
            // The weight value step, as a UI value.
            step: 1,
            // The default weight, as a UI value.
            default: 100,
            // If true, the incoming aspect weights from the API (but not aspect value weights) are overwritten by the default weight.
            overwrite: false,
            // If true, the incoming weights are rescaled depending on the statistics of all aspect weights
            rescale: false,
        },
        // Default URL to navigate to when the `/` URL is loaded. Note: URLs here refer to the routing, which currently uses the hash (#/...).
        defaultRedirect: "/profile-to-candidates",
        // Hides the required checkbox for aspects (but not aspect values).
        hideAspectRequiredCheckbox: false,
        // Locale for the entire UI, one of: `nl`, `en`.
        locale: "nl",
        // Default value for range aspects. Note: should probably be moved to the aspect configuration. IMPORTANT: By default this only affects manually entered locations. To override what we get from the API, see `overrideApiRange`.
        defaultRange: 30,
        // Override the range value from the API
        overrideApiRange: false,
        // Array of section names to be displayed in the result details dialog. Note: this needs a better place.
        profileDisplay: [
            "extraProperties",
            "workExperiences",
            "educations",
            "text",
            "additionalText",
        ],
        // Hides the menu.
        hideMenu: false,
        // Removes the padding around the page (use with hideMenu true only).
        removePagePadding: false,
        // If true, a warning message is displayed saying not all data is indexed yet.
        displayNotFullyIndexed: false,
        // A demo2-specific custom filter for the job listing, do not use for anything else!
        filterJobsDemoMode: false,
        // Display format for date-pickers. Deprecated, use dateFormat instead.
        datePickerFormat: undefined,
        // Maximum depth for custom filters
        customFiltersMaximumDepth: 1,
        // Phone number protocol
        phoneNumberProtocol: "tel",
        // Phone number default country
        phoneNumberDefaultCountry: "BE",
        // Show phone buttons in result list
        phoneButtonsInResultList: false,
        // Disable Whatsapp button (only use "phone" button)
        disableWhatsappButton: false,
        // Displays all functions for jobs/candidates, instead of just one
        displayAllFunctions: false,
        // Disables match status selection, dispate statuses being available
        disableMatchStatusSelection: false,
        // Disable note creation
        disableNotes: false,
        // DO NOT USE, temporary fix for Talentus
        temporaryCarerixNotesFilter: false,
        // If true, only shows the job title in job matches (still shows function if there's no title)
        onlyShowJobTitleInMatches: false,
        // If true, the score bar in the results list is always green
        disableScoreColoring: false,
        // How text in extra properties is displayed. [plain: do nothing, show as received from API] [strip-html]
        extraPropertiesTextMode: "strip-html",
        // Enabled nowrap for result columns
        resultColumnsNoWrap: false,
        // Enables the match tutorial video button (if a video was set for the UI locale)
        matchTutorialVideosEnabled: false,
        // Sets match tutorial videos per language
        matchTutorialVideos: {
            nl: undefined,
            fr: undefined,
            en: undefined,
            it: undefined,
        },
        // If true, instead of a "select all" checkbox, you get a number field to type in the number of results to select
        selectAllCustomizable: false,
        // Some function to run after selection was made for any index
        runAfterSelection: undefined,
        // Format used to display dates in the UI
        dateFormat: "YYYY-MM-DD",
        // Format used to display times in the UI
        timeFormat: "HH:mm:ss",
        // Format used to display dates with times in the UI
        dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
        // Sort the work experiences by dateFrom
        sortWorkExperiences: false,
        // Sort the educations by dateFrom
        sortEducations: false,
    },
    localStorage: {
        // If true, aspect state is stored (per context/MatchSourceEntity)
        storeAspects: false,
        // If true, filter state is stored (per context/MatchSourceEntity)
        storeFilters: false,
        // If true, sort mode state is stored (per context/MatchSourceEntity)
        storeSortMode: false,
        // Stored state is ignored if it's older than this (can be left undefined for infinite storage)
        maxAge: "P30D",
        // If set, a property of the MatchSourceEntity is stored with the state, and is used to validate the stored state (using shallow comparison!)
        versionProperty: undefined,
    },
    matching: {
        // If true, excludes existing matches from results
        excludeExistingMatches: false,
    },
    // An array listing the available languages. This is not meant to be modified. Probably should be moved to a different location and/or implemented differently.
    languages: ["DUT", "FRE", "ENG", "GER"],
    // See development variant for example filter config and documentation.
    filters: {},
    // See development variant for example custom filter config and documentation.
    customFilters: {},
    // See development variant for example sort config and documentation.
    sort: {},
    // Enums in the config are deprecated and will be removed. Do not add more enums.
    enums: {
        rangeUnit: ["KILOMETER", "METER", "MILE"],
        workingHours: ["PART_TIME", "FULL_TIME", "MIXED", "AVAILABILITY", "UNDEFINED"],
        contractTypes: [
            "PERMANENT",
            "PERMANENT_POSSIBLE",
            "TEMPORARY",
            "RESERVE",
            "SELF_EMPLOYED",
            "VOLUNTEER",
            "STUDENT",
            "APPRENTICESHIP",
            "UNDEFINED",
        ],
        apiCallState: {
            idle: "IDLE",
            pending: "PENDING",
        },
    },
    // UI translations.
    i18n: {
        messages: {
            en: enMessages,
            nl: nlMessages,
            fr: frMessages,
            it: itMessages,
            de: deMessages,
        },
        valueTranslations: {
            // Property path as keys, values are objects with property values as keys, and a translation dictionary (like messages above) as values
            // "extraProperties.someKeywordProperty": {
            //     "valueA": {
            //         en: "Value A",
            //         nl: "Waarde A"
            //     }
            // }
        },
    },
};

export const DEFAULT_MATCH_INFORMATION_GETTERS = {
    // Required for companiesForCandidate indication (yellow chain)
    companyId: () => undefined,
};

export function indexDefaults() {
    return {
        // Name of the index.
        name: undefined,
        // Label to display in the UI for the index.
        label: undefined,
        // Whether the index is actually disabled.
        disabled: false,
        // Score type to use for the API call (`be.thematchbox.lucene.queries.ScoreType`).
        scoreType: ScoreType.MUTUAL_FORWARD_SCORE,
        // Expansion type to use for the API call (`be.thematchbox.api.ontology.ExpansionType`).
        expansionType: undefined,
        // Filters to display in the UI. See filters example in development variant.
        filters: [],
        // Name of the group of sort options to display in the UI. See sort example in development variant.
        sortModeGroup: undefined,
        // Allows the user to select interesting results for further use.
        allowSelection: false,
        // Available statuses when making a selection
        selectionStatuses: [],
        // default state of selection status
        selectionStatusDefault: null,
        // Allows the user to compare candidates.
        allowComparison: false,
        // Allows downloading results.
        allowDownload: false,
        // Download export type.
        exportType: "csv",
        // Download export size.
        exportSize: undefined,
        // Show a button for each candidate to match against jobs or other candidates.
        showMatchButton: false,
        // Additional columns to display in the results table.
        extraColumns: [
            /* {
                // Label to display in the table header.
                label: "Naam",
                // Property of the document to display in the result row. Can be a function as well, which will be passed the document.
                property: "extraProperties.fullName",
                // CSS value for the width of the column (optional).
                width: "100rem",
            }, */
        ],
        // The property to display as the title for a candidate's details dialog. Can be a function as well, which will be passed the document.
        detailsTitleProperty: undefined,
        // The property to display above the normal title in the comparison dialog. Can be a function as well, which will be passed the document.
        comparisonSubtitleProperty: undefined,
        // A black/white list of extra properties to display in the result details dialog.
        extraProperties: {},
        // A list of extra properties that are phone numbers, currently doesn't support deep phone numbers
        extraPropertyPhoneNumbers: [],
        // A list of properties that hold an email address, in order of preference. Enables the email button if set.
        emailAddressProperties: [],
        // A list of extra properties to show in the comparison dialog, the root is the match document
        comparisonExtraProperties: undefined,
        // If provided, an "visit external" button is shown. Must be a function that accepts an ID.
        handleVisitExternal: undefined,
        // Default custom filters value. Set to undefined to disable.
        defaultCustomFilters: undefined,
        // If set, the create note dialog is available, and this callback is called with the note details. MUST return a Promise.
        createNoteCallback: undefined,
        // If set, the create note dialog shows a select that allows the user to choose a note type
        noteTypes: undefined,
        // If set, the create note dialog selects this type by default
        defaultNoteType: undefined,
        // Endpoint to fetch the external URL for an entity
        externalDetailsTabUrlFetcher: undefined,
        // Whether the external tab should be displayed by default
        externalDetailsTabDefaultActive: false,
        // Receives the API object (job or candidate) and returns a modified object, which will then be converted to the UI's internal representation.
        // TODO: We might need a better system for this, currently use by the Carerix UI to handle translations of CRDataNodes
        apiConversionPreProcessor: undefined,
        // Getters that return specific information for match that the UI needs
        matchInformationGetters: DEFAULT_MATCH_INFORMATION_GETTERS,
        // Allows selecting all results for a search if greater than 0, up to the specified amount of results. Maximum value is 1000.
        selectAllLimit: 0,
        // A function to open existing selection details externally
        openExternalExistingSelection: undefined,
        // An index can specify selection endpoints itself to override the general settings. Keys: get, getAsync, set
        selectionEndpoints: undefined,
        //
        addToGroup: {
            enabled: false,
            getGroupsEndpoint: undefined,
            addToGroupEndpoint: undefined,
        },
        // An endpoint that provides annotations for the results
        resultAnnotationEndpoint: undefined,
        // Uses a custom matching strategy name when searching this index. Deprecated! Use matchingStrategyNames instead.
        matchingStrategyName: undefined,
        // A list of valid matching strategy names for this index. If more than one is available, a selection UI will be displayed. The first name is the default.
        matchingStrategyNames: [],
        // This overrides matchingStrategyNames
        searchStrategies: [],
        // A value of the IndexDocumentType enum, currently only half supported. DO NOT USE THE PROFILE VALUE, USE ONLY JOB_PROFILE AND CANDIDATE_PROFILE TO FALL BACK TO OLD API CALLS!
        indexDocumentType: undefined,
        // Disables match status selection, dispate statuses being available
        disableMatchStatusSelection: false,
        // Enforce certain filters for any searches to this index. An array to be added to "must".
        forcedFilters: undefined,
        // Enables the "mininum score" UI element in the filters section. WARNING: Currently on works on 2.1 branch, ask Toon if you need this.
        enableMinScore: false,
        // Disable Whatsapp button (only use "phone" button)
        disableWhatsappButton: false,
        // Additional action buttons to show at the bottom. (label / arguments / callback({index, selection, actions, arguments}) )
        additionalActionButtons: [],
        // Property or function that returns a URL to the CV or job text
        externalTextDisplayUrlProperty: undefined,
        // If true, the old /hrmatching API will be used, even if the API version supports the newer API
        useOldHrMatchingApi: false,
        // Temporary fix for the API failing searches due to unknown aspects. If defined, only the aspects named here are sent during search requests
        aspectsSupportedForSearch: undefined,
        // If true, the details column is hidden
        hideDetailsColumn: false,
        // If defined, must be an object with string properties getTemplatesEndpoint and sendEmailsEndpoint. This enables the "send emails" UI.
        sendEmails: undefined,
    };
}
