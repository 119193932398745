import Validators from "~/config/validation/validators/Validators";
import ScoreType from "~/enums/ScoreType";
import ExpansionType from "~/enums/ExpansionType";
import ValidationError from "~/config/validation/ValidationError";
import IndexDocumentType from "~/enums/IndexDocumentType";
import TranslatableLabelDefinition from "~/config/definitions/shared/TranslatableLabelDefinition";
import SelectionEndpointsDefinition from "~/config/definitions/shared/SelectionEndpointsDefinition";

export default Validators.shape({
    description: "Configuration for an index used by a matching page",
    shape: {
        name: Validators.string({
            description: "Name of the index",
        }),
        label: TranslatableLabelDefinition,
        disabled: Validators.boolean({
            description: "Whether the index is actually disabled.",
            defaultValue: false,
        }),
        scoreType: Validators.enum({
            description:
                "Score type to use for the API call (`be.thematchbox.lucene.queries.ScoreType`)",
            enum: ScoreType,
        }).allowsUndefined,
        expansionType: Validators.enum({
            description:
                "Expansion type to use for the API call (`be.thematchbox.api.ontology.ExpansionType`)",
            enum: ExpansionType,
        }).allowsUndefined,
        hideDetailsColumn: Validators.boolean({
            description: "boolean for hiding details column",
            defaultValue: false,
        }),
        filters: Validators.arrayOf({
            description:
                "Filters to display in the UI. See filters example in development variant.",
            items: Validators.string({
                description: "Key of the filters in the filters configuration",
            }).custom((value, path, rootValue) => {
                if (rootValue.filters[value] === undefined) {
                    throw new ValidationError(
                        path,
                        `invalid name for filter: ${value}`,
                        rootValue.filters
                    );
                }
                return value;
            }),
        }).allowsUndefined,
        sortModeGroup: Validators.string({
            description:
                "Name of the group of sort options to display in the UI. See sort example in development variant.",
        }).custom((value, path, rootValue) => {
            if (rootValue.sort[value] === undefined) {
                throw new ValidationError(
                    path,
                    `invalid name of the group of sort options: ${value}`
                );
            }
            return value;
        }).allowsUndefined,
        allowSelection: Validators.boolean({
            description: "Allows the user to select interesting results for further use",
            defaultValue: false,
        }),
        selectionStatuses: Validators.arrayOf({
            description: "Available statuses when making a selection",
            items: Validators.shape({
                description: "A selection status",
                shape: {
                    value: Validators.any({
                        description: "The value for the selection status"
                    }),
                    label: Validators.string({
                        description: "The label of the selection status"
                    }),
                    color: Validators.string({
                        description: "An optional background color for the selection status"
                    }).allowsUndefined
                }
            }),
        }).allowsUndefined,
        selectionStatusDefault: Validators.any({
            description: "Default selection status to show in the UI",
            defaultValue: null,
            // TODO: Custom validation to check if it's a valid selection status (Klaas q: list of valid selection statuses?)
        }).allowsNull,
        allowComparison: Validators.boolean({
            description: "Allows the user to compare candidates",
            defaultValue: false,
        }),
        allowDownload: Validators.boolean({
            description: "Allows downloading results",
            defaultValue: false,
        }),
        exportType: Validators.string({
            description: "Download export type",
            defaultValue: "csv",
        }),
        exportSize: Validators.integer({
            description: "Download export size. If undefined, the server chooses a size.",
            maxValue: undefined,
        }).allowsUndefined,
        showMatchButton: Validators.boolean({
            description:
                "Show a button for each candidate to match against jobs or other candidates",
            defaultValue: false,
        }),
        extraColumns: Validators.arrayOf({
            description: "Additional columns to display in the results table.",
            items: Validators.shape({
                description: "Additional column to display in the results table.",
                shape: {
                    label: TranslatableLabelDefinition,
                    property: Validators.oneOfType({
                        description:
                            "Property of the document to display in the result row. Can be a function as well, which will be passed the document.",
                        validTypes: [
                            Validators.string({
                                description:
                                    "Property of the document to display in the result row.",
                            }).allowsUndefined,
                            Validators.func({
                                description:
                                    "The value can be a function. When called, it  will be passed the document.",
                            }),
                        ],
                    }),
                    width: Validators.string({
                        description: "CSS value for the width of the column (optional).", //integer or custom check for part int en rem?
                    }).allowsUndefined,
                    key: Validators.string({
                        description: "key of extra column",
                    }).allowsUndefined,
                    class: Validators.string({
                        description: "class", //todo
                    }).allowsUndefined,
                },
            }),
        }).allowsUndefined,
        detailsTitleProperty: Validators.oneOfType({
            description: "The property to display as the title for a candidate's details dialog",
            validTypes: [
                Validators.string({
                    description: "The value can be a property path",
                }),
                Validators.func({
                    description:
                        "The value can be a function. When called, it will receive the document of the match as its only argument",
                }),
            ],
        }).allowsUndefined,
        comparisonSubtitleProperty: Validators.oneOfType({
            description: "The property to display above the normal title in the comparison dialog",
            validTypes: [
                Validators.string({
                    description: "The value can be a property path", //todo: klaasq : which property paths?
                }).allowsUndefined,
                Validators.func({
                    description:
                        "The value can be a function. When called, it will receive the document of the match as its only argument",
                }).allowsUndefined,
            ],
        }).allowsUndefined,
        extraProperties: Validators.object({
            description:
                "A black/white list of extra properties to display in the result details dialog.",
        }).allowsUndefined,
        extraPropertyPhoneNumbers: Validators.arrayOf({
            description:
                "A list of extra properties that are phone numbers, currently doesn't support deep phone numbers",
            items: Validators.string({
                description: "Phone number property path (within extraProperties)",
            }).allowsUndefined,
        }).allowsUndefined,
        emailAddressProperties: Validators.arrayOf({
            description:
                "A list of properties that hold an email address, in order of preference. Enables the email button if set.",
            items: Validators.string({
                description: "Email address property path",
            }).allowsUndefined,
        }).allowsUndefined,
        comparisonExtraProperties: Validators.arrayOf({
            description:
                "A list of extra properties to show in the comparison dialog, the root is the match document",
            items: Validators.string({
                description: "extra property",
            }).allowsUndefined,
        }).allowsUndefined,
        handleVisitExternal: Validators.func({
            description:
                'If provided, a "visit external" button is shown. Must be a function that accepts an ID',
        }).allowsUndefined,
        defaultCustomFilters: Validators.shape({
            description: "Key of the filters in the filters configuration",
            shape: {
                combinator: Validators.string({
                    description: "combinator of rules for filter",
                }),
                rules: Validators.arrayOf({
                    description: "rules for filter",
                    items: Validators.shape({
                        description: "shape of rules for filter",
                        shape: {
                            property: Validators.string({
                                description: "property path",
                            }),
                            data: Validators.oneOfType({
                                description: "data for rule",
                                defaultValue: false,
                                validTypes: [
                                    Validators.boolean({
                                        description: "boolean data for rule",
                                        defaultValue: false,
                                    }),
                                    Validators.object({
                                        description: "object data for rule",
                                        defaultValue: {},
                                    }),
                                ],
                            }),
                        },
                    }),
                }),
            },
        }).allowsUndefined,
        createNoteCallback: Validators.func({
            description:
                "If set, the create note dialog is available, and this callback is called with the note details. MUST return a Promise",
        }).allowsUndefined,
        noteTypes: Validators.arrayOf({
            description: "An array of note types",
            items: Validators.shape({
                description: "A note type",
                shape: {
                    value: Validators.any({
                        description: "The value of the note type"
                    }),
                    label: TranslatableLabelDefinition,
                }
            }),
        }).allowsUndefined,
        defaultNoteType: Validators.any({
            description: "If set, the create note dialog selects this type by default",
        }).allowsUndefined,
        externalDetailsTabUrlFetcher: Validators.func({
            description: "Endpoint to fetch the external URL for an entity",
        }).allowsUndefined,
        externalDetailsTabDefaultActive: Validators.boolean({
            description: "Whether the external tab should be displayed by default",
            defaultValue: false,
        }),
        apiConversionPreProcessor: Validators.func({
            description:
                "Receives the API object (job or candidate) and returns a modified object, which will then be converted to the UI's internal representation",
            // TODO: We might need a better system for this, currently use by the Carerix UI to handle translations of CRDataNodes
        }).allowsUndefined,
        matchInformationGetters: Validators.shape({
            description: "Getters that return specific information for match that the UI needs",
            shape: {
                companyId: Validators.func({
                    description: "Required for companiesForCandidate indication (yellow chain)",
                }).allowsUndefined,
            },
        }).allowsUndefined,
        selectAllLimit: Validators.integer({
            description:
                "Allows selecting all results for a search if greater than 0, up to the specified amount of results. Maximum value is 1000.",
            defaultValue: 0,
            maxValue: 1001,
        }),
        openExternalExistingSelection: Validators.func({
            description: "A function to open existing selection details externally",
        }).allowsUndefined,
        selectionEndpoints: SelectionEndpointsDefinition.allowsUndefined,
        addToGroup: Validators.shape({
            description: "", //todo
            shape: {
                enabled: Validators.boolean({
                    description: "", //todo
                    defaultValue: false,
                }),
                getGroupsEndpoint: Validators.string({
                    description: "", //todo
                }).allowsUndefined,
                addToGroupEndpoint: Validators.string({
                    description: "", //todo
                }).allowsUndefined,
            },
        }).allowsUndefined,
        resultAnnotationEndpoint: Validators.string({
            description: "An endpoint that provides annotations for the results",
        }).allowsUndefined,
        matchingStrategyName: Validators.string({
            description:
                "Uses a custom matching strategy name when searching this index. Deprecated! Use matchingStrategyNames instead.",
        }).allowsUndefined,
        matchingStrategyNames: Validators.arrayOf({
            description:
                "A list of valid matching strategy names for this index. If more than one is available, a selection UI will be displayed. The first name is the default.",
            items: Validators.string({
                description: "The name of a matching strategy",
            }),
        }).allowsUndefined,
        // A value of the IndexDocumentType enum, currently only half supported. DO NOT USE THE PROFILE VALUE, USE ONLY JOB_PROFILE AND CANDIDATE_PROFILE TO FALL BACK TO OLD API CALLS!
        indexDocumentType: Validators.enum({
            description:
                "A value of the IndexDocumentType enum, currently only half supported. DO NOT USE THE PROFILE VALUE, USE ONLY JOB_PROFILE AND CANDIDATE_PROFILE TO FALL BACK TO OLD API CALLS!",
            enum: IndexDocumentType,
        }).allowsUndefined,
        disableMatchStatusSelection: Validators.boolean({
            defaultValue: false,
            description: "Disables match status selection, despite statuses being available",
        }),
        forcedFilters: Validators.arrayOf({
            description:
                'Enforce certain filters for any searches to this index. An array to be added to "must".',
            items: Validators.any({
                description: "ElasticSearch filter",
            }),
        }).allowsUndefined,
        enableMinScore: Validators.boolean({
            description:
                'Enables the "mininum score" UI element in the filters section. WARNING: Currently on works on 2.1 branch, ask Toon if you need this.',
            defaultValue: false,
        }),
        disableWhatsappButton: Validators.boolean({
            description: 'Disable Whatsapp button (only use "phone" button)',
            defaultValue: false,
        }),
        additionalActionButtons: Validators.arrayOf({
            description:
                "Additional action buttons to show at the bottom. (label / arguments / callback({index, selection, actions, arguments}) )",
            items: Validators.shape({
                description:
                    "Shape of additional action buttons to show at the bottom. (label / arguments / callback({index, selection, actions, arguments}) )",
                shape: {
                    label: TranslatableLabelDefinition,
                    callback: Validators.func({
                        description: "The function to call",
                    }).allowsUndefined,
                    requiresMatchSourceEntity: Validators.boolean({
                        description: "requiresMatchSourceEntity",
                        defaultValue: false,
                    }),
                    requiresSelection: Validators.boolean({
                        description: "requiresSelection",
                        defaultValue: false,
                    }),
                    arguments: Validators.object({
                        description: "Arguments for the callback function",
                    }).allowsUndefined,
                },
            }),
        }).allowsUndefined,
        externalTextDisplayUrlProperty: Validators.oneOfType({
            description: "Property or function that returns a URL to the CV or job text.",
            validTypes: [
                Validators.string({
                    description: "Property of a URL to the CV or job text.",
                }).allowsUndefined,
                Validators.func({
                    description:
                        "The value can be a function. When called, it returns a URL to the CV or job text.",
                }),
            ],
        }).allowsUndefined,
        useOldHrMatchingApi: Validators.boolean({
            description:
                "If true, the old /hrmatching API will be used, even if the API version supports the newer API.",
            defaultValue: false,
        }),
        aspectsSupportedForSearch: Validators.arrayOf({
            description:
                "Temporary fix for the API failing searches due to unknown aspects. If defined, only the aspects named here are sent during search requests.",
            items: Validators.string({
                description:
                    "If defined, only the aspects named here are sent during search requests.",
            }),
        }).allowsUndefined,
        sendEmails: Validators.shape({
            description: 'If set, the "send emails" UI is enabled.',
            shape: {
                getTemplatesEndpoint: Validators.string({
                    description:
                        "The endpoint that supplies the email templates through a GET call",
                }),
                sendEmailsEndpoint: Validators.string({
                    description: "The endpoint that sends the emails through a POST call",
                }),
            },
        }).allowsUndefined,
    },
});
