import Validators from "~/config/validation/validators/Validators";

export default Validators.shape({
    description: "Configuration for local storage",
    shape: {
        storeAspects: Validators.boolean({
            description: "If true, aspect state is stored (per context/MatchSourceEntity)",
            defaultValue: false,
        }),
        storeFilters: Validators.boolean({
            description: "If true, filter state is stored (per context/MatchSourceEntity)",
            defaultValue: false,
        }),
        storeSortMode: Validators.boolean({
            description: "If true, sort mode state is stored (per context/MatchSourceEntity)",
            defaultValue: false,
        }),
        maxAge: Validators.string({
            description:
                "Stored state is ignored if it's older than this (can be left undefined for infinite storage)",
        }).allowsUndefined,
        versionProperty: Validators.string({
            description:
                "If set, a property of the MatchSourceEntity is stored with the state, and is used to validate the stored state (using shallow comparison!)",
        }).allowsUndefined,
    },
});
