import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import uuid from "uuid/v4";
import config from "~/config";
import AspectPropType, {AspectConfig} from "~/prop-types/aspect";
import ListGroup from "react-bootstrap/ListGroup";
import Weight from "~/components/Weight";
import updateById from "~/util/update-by-id";
import styles from "./styles.module.scss";
import EnumValue from "./EnumValue";
import Checkbox from "~/components/Checkbox";
import {loadEnumValues} from "~/util/filter-values";
import {I18N} from "~/util/localization";

export default class EnumValuesAspectEditor extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        aspectConfig: AspectConfig.isRequired,
        enumValues: PropTypes.array,
        isEditing: PropTypes.bool.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            newValue: createNewValue(),
            rawValues: undefined,
            enumValues: [],
        };

        this.newValueRef = React.createRef();
    }

    componentDidMount() {
        this.updateEnumValues();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateEnumValues(prevProps);
    }

    updateEnumValues(prevProps) {
        const {aspectConfig, name, enumValues} = this.props;

        if (enumValues !== undefined) {
            if (prevProps === undefined || enumValues !== prevProps.enumValues) {
                // Old way of doing things, still supported for backwards compatability
                this.setState({
                    enumValues: enumValues.map(enumValue => enumValueToOption(name, enumValue)),
                });
            }
        } else if (
            prevProps === undefined ||
            prevProps.aspectConfig.values !== aspectConfig.values
        ) {
            this.setState({rawValues: aspectConfig.values}, () => {
                loadEnumValues(aspectConfig.values).then(enumValues => {
                    if (this.unmounted) {
                        return;
                    }

                    if (this.state.rawValues === aspectConfig.values) {
                        this.setState({enumValues});
                    }
                });
            });
        }
    }

    reset() {
        this.setState({newValue: createNewValue()});
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                {this.renderValues()}
            </Card>
        );
    }

    renderHeader() {
        const {title, aspect, isEditing, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        disabled={!isEditing}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={isEditing}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderValues() {
        const {aspect, name, isEditing} = this.props;
        const {enumValues} = this.state;

        const values = aspect.value.map(value => (
            <EnumValue
                key={value.id}
                value={value}
                placeholder={I18N.intl.formatMessage({id: "input.removeValue"})}
                isPlaceholderDisabled={false}
                enumValues={enumValues}
                enumName={name}
                isEditing={isEditing}
                onChange={this.handleValueChange}
                onDelete={this.handleValueDelete}
            />
        ));

        this.renderNewValue(values);

        return <ListGroup variant="flush">{values}</ListGroup>;
    }

    renderNewValue(values) {
        const {name, isEditing} = this.props;
        const {enumValues} = this.state;
        if (!isEditing) return;

        const {newValue} = this.state;

        values.push(
            <EnumValue
                key={newValue.id}
                value={newValue}
                placeholder={I18N.intl.formatMessage({id: "input.addValue"})}
                isPlaceholderDisabled={true}
                enumValues={enumValues}
                enumName={name}
                isEditing={true}
                onChange={this.handleNewValueChange}
                ref={this.newValueRef}
            />
        );
    }

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };

    handleValueChange = (id, value) => {
        const {name, aspect, onChange} = this.props;
        const nextValue = updateById(aspect.value, id, value);
        const nextRequired = value.required ? true : aspect.required;
        onChange(name, {...aspect, required: nextRequired, value: nextValue});
    };

    handleNewValueChange = (id, value) => {
        if (value.label === "") {
            this.setState({newValue: value});
            return;
        }

        const {name, aspect, onChange} = this.props;
        const nextValue = [...aspect.value];
        nextValue.push(value);
        onChange(name, {...aspect, value: nextValue});
        this.setState({newValue: createNewValue()});
    };

    handleValueDelete = id => {
        const {name, aspect, onChange} = this.props;
        const nextValue = aspect.value.filter(value => value.id !== id);
        onChange(name, {...aspect, value: nextValue});
    };
}

function createNewValue() {
    return {
        id: uuid(),
        weight: config("ui.weight.default"),
        required: false,
        label: "",
    };
}

function enumValueToOption(enumName, enumValue) {
    return {value: enumValue, label: I18N.intl.formatMessage({id: `${enumName}.${enumValue}`})};
}
