export function getFields(property, propertyDefinition) {
    return propertyDefinition.fields ? propertyDefinition.fields : [property];
}

export function mapFields(property, propertyDefinition, callback) {
    const fields = getFields(property, propertyDefinition);
    return fields.map(callback);
}

export function toIsEmptyFilter(property, input, propertyDefinition) {
    const fields = getFields(property, propertyDefinition);

    return {
        bool: {
            must_not: fields.map(field => ({exists: {field}})),
        },
    };
}

export function toIsNotEmptyFilter(property, input, propertyDefinition) {
    const fields = getFields(property, propertyDefinition);

    return {
        bool: {
            must: fields.map(field => ({exists: {field}})),
        },
    };
}
