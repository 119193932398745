import queryString from "query-string";
import {setInternalToken} from "~/api/client";

export function grabInternalTokenFromQuery() {
    const parsedQueryString = queryString.parse(window.location.search);
    const internalToken = parsedQueryString.tmb;

    if (internalToken) {
        setInternalToken(internalToken);

        if (process.env.NODE_ENV !== "development") {
            removeQueryParameter(parsedQueryString, "tmb");
        }
    }
}

export function grabOneTimePasswordFromQuery() {
    const parsedQueryString = queryString.parse(window.location.search);
    const otp = parsedQueryString.otp;

    if (otp && process.env.NODE_ENV !== "development") {
        removeQueryParameter(parsedQueryString, "otp");
    }

    return otp;
}

function removeQueryParameter(parsedQueryString, name) {
    const nextParsedQueryString = {...parsedQueryString};
    delete nextParsedQueryString[name];

    const nextQueryString = queryString.stringify(nextParsedQueryString);
    const nextUrl =
        window.location.origin +
        window.location.pathname +
        (nextQueryString ? "?" : "") +
        nextQueryString +
        window.location.hash;
    window.history.replaceState({}, document.title, nextUrl);
}