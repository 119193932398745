import Validators from "~/config/validation/validators/Validators";
import {AVAILABLE_LOCALES} from "~/config/base-config";

export default Validators.oneOfType({
    description: "A label that can optionally be a translation key",
    validTypes: [
        Validators.string({
            description: "Untranslated label value",
        }),
        Validators.shape({
            description: "Translated label using a translation key",
            shape: {
                id: Validators.string({
                    description: "Translation key",
                }),
            },
        }),
        Validators.shape({
            description: "Translated label using specific translations",
            shape: AVAILABLE_LOCALES.reduce((shape, locale) => {
                shape[locale] = Validators.string({description: "Translation"}).allowsUndefined;
                return shape;
            }, {}),
        }),
    ],
});
