import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Select from "~/components/Select";
import styles from "./styles.module.scss";

export default class BooleanProperty extends React.PureComponent {
    static propTypes = {
        data: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    static defaultData(propertyDefinition) {
        if (propertyDefinition.defaultData === true || propertyDefinition.defaultData === false) {
            return propertyDefinition.defaultData;
        } else {
            return true;
        }
    }

    static toFilter(property, data, propertyDefinition, context) {
        if (data !== true && data !== false) {
            return undefined;
        }

        if (propertyDefinition.alternateTrueValue !== undefined) {
            if (data) {
                return {term: {[property]: propertyDefinition.alternateTrueValue}};
            } else {
                return {
                    bool: {
                        must_not: {term: {[property]: propertyDefinition.alternateTrueValue}},
                    },
                };
            }
        } else if (propertyDefinition.contextIdSet !== undefined) {
            const idSet = context[propertyDefinition.contextIdSet];

            if (!Array.isArray(idSet)) {
                console.error("[BooleanProperty] Expected an array of IDs, but got", idSet);
                return undefined;
            }

            if (data) {
                return {terms: {_id: idSet}};
            } else {
                return {
                    bool: {
                        must_not: {terms: {_id: idSet}},
                    },
                };
            }
        } else {
            return {term: {[property]: data}};
        }
    }

    render() {
        const {data} = this.props;

        return (
            <Select
                className={c(styles.givePriority, styles.comparisonSelect)}
                selected={data}
                options={OPTIONS}
                onChange={this.handleSelect}
                variant="extra-small"
            />
        );
    }

    handleSelect = option => {
        this.props.onChange(option.value);
    };
}

const OPTIONS = [
    {label: "—", value: null},
    {label: {id: "predicateEditor.isTrue"}, value: true},
    {label: {id: "predicateEditor.isFalse"}, value: false},
];
