export default class ApiCallError extends Error {
    constructor(request, response, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiCallError);
        }

        this.name = 'ApiCallError';
        this.request = request;
        this.response = response;
    }
}
