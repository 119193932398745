import React from "react";
import PropTypes from "prop-types";
import Foco from "react-foco";
import c from "classnames";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import ListGroup from "react-bootstrap/ListGroup";
import Weight from "~/components/Weight";
import styles from "./styles.module.scss";
import Checkbox from "~/components/Checkbox";
import uuid from "uuid/v4";
import Api from "~/api";

export default class Value extends React.PureComponent {
    static propTypes = {
        value: PropTypes.shape({
            id: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
        placeholder: PropTypes.string.isRequired,
        isEditing: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        autocompleteConceptTypes: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        onFinish: PropTypes.func,
        onDelete: PropTypes.func,
        innerRef: PropTypes.object,
    };

    static defaultProps = {
        placeholder: "",
        autocompleteConceptTypes: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
        };

        this.id = uuid();
        this.autoCompleteCall = null;
        this.labelInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    focus = () => {
        if (this.labelInputRef.current) {
            this.labelInputRef.current.getInstance().focus();
        }
    };

    render() {
        const {value, isEditing} = this.props;

        return (
            <ListGroup.Item className={c(styles.listItem, styles.main)}>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    className={styles.foco}
                    component="div"
                >
                    <Checkbox
                        checked={value.required}
                        disabled={!isEditing}
                        color="purple"
                        onChange={this.handleRequiredChange}
                    />
                    {this.renderLabel()}
                    {this.renderRemoveButton()}
                    <Weight
                        value={value.weight}
                        isEditing={isEditing}
                        className="ml-auto"
                        color="purple"
                        onChange={this.handleWeightChange}
                    />
                </Foco>
            </ListGroup.Item>
        );
    }

    renderLabel() {
        const {isEditing} = this.props;
        return isEditing ? this.renderLabelEdit() : this.renderLabelDisplay();
    }

    renderLabelDisplay() {
        const {value} = this.props;

        return (
            <input
                className={styles.labelInput}
                readOnly={true}
                value={value.label}
                onChange={doNothing}
            />
        );
    }

    renderLabelEdit() {
        const {value, placeholder, autocompleteConceptTypes} = this.props;
        const {isLoading, options} = this.state;

        return (
            <AsyncTypeahead
                id={this.id}
                className={styles.input}
                placeholder={placeholder}
                selected={value.label ? [value.label] : []}
                isLoading={isLoading}
                options={options}
                minLength={autocompleteConceptTypes.length > 0 ? 2 : Number.MAX_SAFE_INTEGER}
                useCache={false}
                onChange={this.handleLabelChange}
                onInputChange={this.handleLabelInputChange}
                onKeyDown={this.handleLabelKeyDown}
                onSearch={this.handleSearch}
                ref={this.labelInputRef}
            />
        );
    }

    renderRemoveButton() {
        if (!this.props.onDelete) {
            return null;
        }

        return (
            <div className={styles.removeButton} onClick={this.handleRemoveClick}>
                <i className="fas fa-times" />
            </div>
        );
    }

    handleSearch = input => {
        const {language, autocompleteConceptTypes} = this.props;

        if (this.autoCompleteCall !== null) {
            this.autoCompleteCall.abort();
        }

        this.setState({isLoading: true});

        this.autoCompleteCall = Api.findPrefixStrings(input, language, autocompleteConceptTypes);

        this.autoCompleteCall
            .then(options => {
                this.setState({options, isLoading: false});
            })
            .catch(error => {
                console.error("Couldn't get autocomplete result", error);
            });
    };

    handleLabelChange = ([value]) => {
        if (value !== undefined) {
            this.handleLabelInputChange(value);
        }
    };

    handleLabelInputChange = input => {
        const {value, onChange, onDelete} = this.props;

        if (input !== "") {
            onChange(value.id, {...value, label: input});
        } else {
            onDelete(value.id);
        }
    };

    handleLabelKeyDown = e => {
        if (e.key === "Enter") {
            setTimeout(() => {
                const {value, onFinish} = this.props;
                if (onFinish) onFinish(value.id);
            }, 1);
        }
    };

    handleRemoveClick = () => {
        const {value, onDelete} = this.props;
        if (onDelete) onDelete(value.id);
    };

    normalizeValue = () => {
        //
    };

    handleWeightChange = nextWeight => {
        const {value, onChange} = this.props;

        onChange(value.id, {
            ...value,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {value, onChange} = this.props;

        onChange(value.id, {
            ...value,
            required: nextRequired,
        });
    };
}

function doNothing() {}
