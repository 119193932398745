import React from "react";
import PropTypes from "prop-types";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import ProcessButton from "~/components/ProcessButton";
import TranslatableLabel from "~/components/TranslatableLabel";

export default class AdditionalActionButton extends React.PureComponent {
    static propTypes = {
        definition: PropTypes.shape({
            label: TranslatableLabelPropType.isRequired,
            callback: PropTypes.func.isRequired,
            arguments: PropTypes.object,
            requiresMatchSourceEntity: PropTypes.bool,
            requiresSelection: PropTypes.bool,
        }),
        index: PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            createNoteCallback: PropTypes.func,
        }).isRequired,
        results: PropTypes.object,
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            processing: false,
        };
    }

    render() {
        const {definition, results} = this.props;
        const {processing} = this.state;
        const selectionWithoutExisting = results.backendSelection.data.removeExistingSelectionFrom(results.selection);

        const disabled = (
            definition.requiresSelection &&
            selectionWithoutExisting.length === 0
        );

        return (
            <ProcessButton
                label={TranslatableLabel.getFinalValue(definition.label, {count: selectionWithoutExisting.length})}
                className="mr-1"
                size="sm"
                processing={processing}
                disabled={disabled}
                onClick={this.handleClick}
            />
        );
    }

    handleClick = () => {
        const {definition, index, results, actions} = this.props;
        const selectionWithoutExisting = results.backendSelection.data.removeExistingSelectionFrom(results.selection);

        this.setState({processing: true});

        const returnValue = definition.callback({
            index,
            selection: selectionWithoutExisting,
            actions,
            arguments: definition.arguments,
        });

        Promise.resolve(returnValue).finally(() => {
            this.setState({processing: false});
        });
    };
}
