import {isString} from "~/util/misc";

export default class Version {
    constructor(major, minor, patch, label) {
        this.major = Number(major);
        this.minor = Number(minor);
        this.patch = Number(patch);
        this.label = String(label);
    }

    static of(input, minor, patch, label) {
        if (input instanceof Version) {
            return new Version(input.major, input.minor, input.patch, input.label);
        } else if (isString(input)) {
            return parseVersion(input);
        } else {
            return new Version(input, minor, patch, label);
        }
    }

    eq(input, minor, patch, label) {
        const other = Version.of(input, minor, patch, label);
        return this.major === other.major && this.minor === other.minor && this.patch === other.patch && this.major === other.major;
    }

    gt(input, minor, patch, label) {
        const other = Version.of(input, minor, patch, label);

        if (this.major > other.major) {
            return true;
        } else if (this.major < other.major) {
            return false;
        } else if (this.minor > other.minor) {
            return true;
        } else if (this.minor < other.minor) {
            return false;
        } else {
            return this.patch > other.patch;
        }
    }

    gte(input, minor, patch, label) {
        return this.eq(input, minor, patch, label) || this.gt(input, minor, patch, label);
    }

    lt(input, minor, patch, label) {
        const other = Version.of(input, minor, patch, label);

        if (this.major < other.major) {
            return true;
        } else if (this.major > other.major) {
            return false;
        } else if (this.minor < other.minor) {
            return true;
        } else if (this.minor > other.minor) {
            return false;
        } else {
            return this.patch < other.patch;
        }
    }

    lte(input, minor, patch, label) {
        return this.eq(input, minor, patch, label) || this.lt(input, minor, patch, label);
    }

    toString() {
        if (!this.label) {
            return `Version[${this.major}.${this.minor}.${this.patch}]`;
        } else {
            return `Version[${this.major}.${this.minor}.${this.patch}-${this.label}]`;
        }
    }
}

const PARSE_REGEX = /^(\d+)(?:\.(\d+))?(?:\.(\d+))?(?:-(\w+))?$/;

function parseVersion(input) {
    const match = PARSE_REGEX.exec(input);

    if (match === null) {
        throw new Error("Invalid Version string: " + input);
    }

    return new Version(match[1], match[2], match[3], match[4]);
}
