import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";

export default class BasicSpinner extends React.PureComponent {
    render() {
        return (
            <div className={styles.wrapper}>
                <div>
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                    <FormattedMessage id="progress.loading" />
                </div>
            </div>
        );
    }
}
