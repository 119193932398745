import Validators from "~/config/validation/validators/Validators";
import TranslatableLabelDefinition from "~/config/definitions/shared/TranslatableLabelDefinition";

export default Validators.oneOfType({
    description: "Provides filter values",
    validTypes: [
        Validators.string({
            description: "Name without extension of a file in ~/data/autocomplete. Deprecated.",
        }),
        Validators.arrayOf({
            description: "Array of hardcoded filter values.",
            items: Validators.shape({
                description: "Filter value.",
                shape: {
                    label: TranslatableLabelDefinition,
                    value: Validators.any({
                        description: "The actual value.",
                    }),
                },
            }),
        }),
        Validators.shape({
            description: "Data for a keyword-tree filter, loaded from the API.",
            shape: {
                endpoint: Validators.string({
                    description: "Endpoint to GET the data from.",
                }),
                valueDefinition: Validators.any({
                    description: "", // TODO: Proper definition
                }),
            },
        }),
        Validators.shape({
            description: "Data for a keyword-tree filter.",
            shape: {
                data: Validators.any({
                    description: "", // TODO: Proper definition
                }),
                valueDefinition: Validators.any({
                    description: "", // TODO: Proper definition
                }),
            },
        }),
        Validators.shape({
            description: "Data loaded from an aggregate search response from the API.",
            shape: {
                endpoint: Validators.string({
                    description: "Search endpoint to use for the aggregate request.",
                }),
                field: Validators.any({
                    description: "The ElasticSearch field to run an aggregate request on.",
                }),
                exclude: Validators.array({
                    description: "Exclude certain values returned by the endpoint.",
                }).allowsUndefined,
            },
        }),
        Validators.shape({
            description: "Data loaded directly from the API.",
            shape: {
                endpoint: Validators.string({
                    description:
                        "Endpoint to make a GET call to. The response is an object that contains an 'options' key, which is an array. Each value of the array is an object with value and label keys.",
                }),
                queryParameters: Validators.object({
                    description: "Query parameters to send to the endpoint.",
                }).allowsUndefined,
            },
        }),
    ],
});
