import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";
import SortSelect from "~/components/SortSelect";
import IndexResultsTab from "~/components/MatchingPage/IndexResultsTab";
import ResultsTabBar from "~/components/MatchingPage/ResultsTabBar";
import {FormattedMessage} from "react-intl";
import PlaceholderTab from "~/components/MatchingPage/PlaceholderTab";
import PoweredByLogo from "~/components/PoweredByLogo";

export default class Results extends React.PureComponent {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        expanded: PropTypes.bool.isRequired,
        activeIndexName: PropTypes.string.isRequired,
        onToggleExpanded: PropTypes.func.isRequired,
        onSortModeChange: PropTypes.func.isRequired,
        updateMatchForIndex: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        onSelectionStatusChange: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onMatchToOthers: PropTypes.func.isRequired,
        onActiveIndexNameChange: PropTypes.func.isRequired,
    };

    render() {
        const {configuration, state, expanded, activeIndexName} = this.props;

        return (
            <div className={c(styles.main, {expanded})}>
                <div className={styles.titleBar}>
                    <div className="ml-3 mr-2">
                        <FormattedMessage id="match.foundIn" />
                    </div>
                    <ResultsTabBar
                        configuration={configuration}
                        state={state}
                        activeIndexName={activeIndexName}
                        onActiveIndexNameChange={this.handleActiveIndexNameChange}
                    />
                    {this.renderSortSelect()}
                    <PoweredByLogo className={styles.poweredBy} />
                </div>
                {this.renderTab()}
            </div>
        );
    }

    renderTab() {
        const {
            configuration,
            state,
            activeIndexName,
            expanded,
            requestPage,
            makeSelection,
            selectAllFromApi,
            onSelectionChange,
            onSelectionStatusChange,
            onDownload,
            onMatchToOthers,
        } = this.props;

        if (!expanded || !activeIndexName) {
            return null;
        }

        const index = configuration.indices.find(x => x.name === activeIndexName);

        if (index) {
            const results = state.results[index.name];

            return (
                <div className={styles.resultsContent}>
                    <IndexResultsTab
                        index={index}
                        resultType={configuration.resultType}
                        results={results}
                        matchSourceEntity={state.matchSourceEntity}
                        requestPage={requestPage}
                        makeSelection={makeSelection}
                        selectAllFromApi={selectAllFromApi}
                        onSelectionChange={onSelectionChange}
                        onSelectionStatusChange={onSelectionStatusChange}
                        onDownload={onDownload}
                        onMatchToOthers={onMatchToOthers}
                    />
                </div>
            );
        }

        const placeholderTab = configuration.placeholderTabs.find(x => x.name === activeIndexName);

        if (placeholderTab) {
            return (
                <div className={styles.resultsContent}>
                    <PlaceholderTab tab={placeholderTab} />
                </div>
            );
        }

        return null;
    }

    renderSortSelect() {
        const {state} = this.props;
        const activeIndex = this.getActiveIndex();
        if (!activeIndex || !activeIndex.sortModeGroup) return;
        const indexState = state.results[activeIndex.name];

        return (
            <React.Fragment>
                <div className={styles.sortSelectLabel}>
                    <FormattedMessage id="match.sortOn" />
                </div>
                <SortSelect
                    className={styles.sortSelect}
                    selected={indexState.nextRequest.sortMode}
                    variant="extra-small"
                    sortModeGroup={activeIndex.sortModeGroup}
                    onChange={this.handleSortModeChange}
                />
            </React.Fragment>
        );
    }

    getActiveIndex() {
        const {configuration, activeIndexName} = this.props;
        return configuration.indices.find(x => x.name === activeIndexName);
    }

    handleSortModeChange = sortMode => {
        const {onSortModeChange, state, updateMatchForIndex} = this.props;
        const activeIndex = this.getActiveIndex();
        onSortModeChange(activeIndex, sortMode);

        const indexState = state.results[activeIndex.name];

        if (indexState.nextRequest.sortMode !== sortMode) {
            updateMatchForIndex(activeIndex);
        }
    };

    handleActiveIndexNameChange = activeIndexName => {
        const {onActiveIndexNameChange} = this.props;
        onActiveIndexNameChange(activeIndexName);
        this.props.onToggleExpanded(true);
    };
}
