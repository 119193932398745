import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "~/components/MatchComparisonTable/styles.module.scss";
import ProgressBar from "~/components/ProgressBar";
import {getDynamicProperty} from "~/util/match";
import Button from "react-bootstrap/Button";
import Checkbox from "~/components/Checkbox";

export default class MatchHeader extends React.PureComponent {
    static propTypes = {
        indexConfig: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        isSelected: PropTypes.bool.isRequired,
        onSelectedChange: PropTypes.func.isRequired,
    };

    render() {
        const {indexConfig, match, isSelected} = this.props;
        const externalTextUrl = getDynamicProperty(match.document, indexConfig.externalTextDisplayUrlProperty);

        return (
            <th className={c(styles.matchColumn, styles.stickyHeader)}>
                <div>
                    <span className={styles.headerSubtitle}>
                        {getDynamicProperty(match.document, indexConfig.comparisonSubtitleProperty)}
                    </span>
                </div>
                <div className={styles.matchHeader}>
                    <Checkbox checked={isSelected} onChange={this.handleSelectedChange}/>
                    <ProgressBar
                        className={c(styles.itemProgress, "mr-2")}
                        variant="success"
                        now={match.score}
                        min={0}
                        max={1}
                    />
                    <span className={styles.matchHeaderTitle}>
                        {getDynamicProperty(match.document, indexConfig.detailsTitleProperty)}
                    </span>
                    {!!indexConfig.externalTextDisplayUrlProperty && (
                        <Button
                            size="sm"
                            variant="secondary"
                            onClick={this.handleVisitExternalText}
                            className="ml-1"
                            disabled={!externalTextUrl}
                        >
                            <i className="far fa-file-alt" />
                        </Button>
                    )}
                    {!!indexConfig.handleVisitExternal && (
                        <Button
                            size="sm"
                            variant="info"
                            onClick={this.handleVisitExternal}
                            className="ml-1"
                        >
                            <i className="fas fa-external-link-alt" />
                        </Button>
                    )}
                </div>
            </th>
        );
    }

    handleVisitExternal = () => {
        const {indexConfig, match} = this.props;
        indexConfig.handleVisitExternal(match.id);
    };

    handleVisitExternalText = () => {
        const {indexConfig, match} = this.props;
        const externalTextUrl = getDynamicProperty(match.document, indexConfig.externalTextDisplayUrlProperty);
        window.open(externalTextUrl, "_blank", "noopener,noreferrer");
    };

    handleSelectedChange = selected => {
        const {match, onSelectedChange} = this.props;
        onSelectedChange(match.id, selected);
    };
}
