import Validators from "~/config/validation/validators/Validators";
import {AVAILABLE_LOCALES} from "~/config/base-config";

export default Validators.shape({
    description: "Configuration for ui",
    shape: {
        enabledAspects: Validators.string({
            description: "Black/white list of enabled aspects. See the BlackWhiteList class.",
        }).allowsUndefined,
        aspectOrder: Validators.arrayOf({
            description:
                "Order the aspects are displayed in the match profile editor. **IMPORTANT: ** Even if an aspect is not displayed, its value is still sent to the API as long as its enabled.",
            items: Validators.string({
                description:
                    "aspect that is displayed in the match profile editor. **IMPORTANT: ** Even if an aspect is not displayed, its value is still sent to the API as long as its enabled.",
            }).allowsUndefined,
        }),
        weight: Validators.shape({
            description:
                "Configuration of how the weight is displayed in the UI. This influences how precisely a user can set the weight.",
            shape: {
                scale: Validators.integer({
                    description: "The value used by the UI for a weight of 1 on the API side.",
                    defaultValue: 100,
                }),
                min: Validators.integer({
                    description: "The minimum weight, as a UI value.",
                    defaultValue: 1,
                }),
                max: Validators.integer({
                    description: "The maximum weight, as a UI value.",
                    defaultValue: 200,
                }),
                step: Validators.integer({
                    description: "The weight value step, as a UI value.",
                    defaultValue: 1,
                }),
                default: Validators.integer({
                    description: "The default weight, as a UI value.",
                    defaultValue: 100,
                }),
                overwrite: Validators.boolean({
                    description:
                        "If true, the incoming aspect weights from the API (but not aspect value weights) are overwritten by the default weight.",
                    defaultValue: false,
                }),
                rescale: Validators.boolean({
                    description:
                        "If true, the incoming weights are rescaled depending on the statistics of all aspect weights.",
                    defaultValue: false,
                }),
            },
        }),
        defaultRedirect: Validators.string({
            description:
                "Default URL to navigate to when the `/` URL is loaded. Note: URLs here refer to the routing, which currently uses the hash (#/...).",
        }),
        hideAspectRequiredCheckbox: Validators.boolean({
            description: "Hides the required checkbox for aspects (but not aspect values).",
            defaultValue: false,
        }),
        locale: Validators.oneOf({
            description: "Locale for the entire UI",
            defaultValue: "en",
            validValues: AVAILABLE_LOCALES,
        }),
        defaultRange: Validators.integer({
            description:
                "Default value for range aspects. Note: should probably be moved to the aspect configuration. IMPORTANT: By default this only affects manually entered locations. To override what we get from the API, see `overrideApiRange`.",
            defaultValue: 30,
        }),
        overrideApiRange: Validators.boolean({
            description: "Override the range value from the API",
            defaultValue: false,
        }),
        profileDisplay: Validators.arrayOf({
            description:
                "Array of section names to be displayed in the result details dialog. Note: this needs a better place.",
            items: Validators.string({
                description: "name to be displayed in the result details dialog.",
            }).allowsUndefined,
        }),
        hideMenu: Validators.boolean({
            description: "Hides the menu.",
            defaultValue: false,
        }),
        removePagePadding: Validators.boolean({
            description: "Removes the padding around the page (use with hideMenu true only).",
            defaultValue: false,
        }),
        displayNotFullyIndexed: Validators.boolean({
            description:
                "If true, a warning message is displayed saying not all data is indexed yet.",
            defaultValue: false,
        }),
        filterJobsDemoMode: Validators.boolean({
            description:
                "A demo2-specific custom filter for the job listing, do not use for anything else!",
            defaultValue: false,
        }),
        datePickerFormat: Validators.string({
            description: "Display format for date-pickers. Deprecated, use dateFormat instead.",
        }).allowsUndefined,
        customFiltersMaximumDepth: Validators.integer({
            defaultValue: 1,
            description: "Maximum depth for custom filters",
        }),
        phoneNumberProtocol: Validators.string({
            defaultValue: "tel",
            description: "Phone number protocol",
        }),
        phoneNumberDefaultCountry: Validators.string({
            defaultValue: "BE",
            description: "Phone number default country",
        }), //one of list?
        phoneButtonsInResultList: Validators.boolean({
            description: "Show phone buttons in result list.",
            defaultValue: false,
        }),
        disableWhatsappButton: Validators.boolean({
            description: "Show phone buttons in result list.",
            defaultValue: false,
        }),
        displayAllFunctions: Validators.boolean({
            description: "Displays all functions for jobs/candidates, instead of just one.",
            defaultValue: false,
        }),
        disableMatchStatusSelection: Validators.boolean({
            description: "Disables match status selection, dispate statuses being available.",
            defaultValue: false,
        }),
        disableNotes: Validators.boolean({
            description: "Disable note creation.",
            defaultValue: false,
        }),
        temporaryCarerixNotesFilter: Validators.boolean({
            description: "DO NOT USE, temporary fix for Talentus.",
            defaultValue: false,
        }),
        onlyShowJobTitleInMatches: Validators.boolean({
            description:
                "If true, only shows the job title in job matches (still shows function if there's no title).",
            defaultValue: false,
        }),
        disableScoreColoring: Validators.boolean({
            description: "If true, the score bar in the results list is always green.",
            defaultValue: false,
        }),
        extraPropertiesTextMode: Validators.string({
            description:
                "How text in extra properties is displayed. [plain: do nothing, show as received from API] [strip-html].",
            defaultValue: "strip-html",
        }),
        resultColumnsNoWrap: Validators.boolean({
            description: "Enabled nowrap for result columns.",
            defaultValue: false,
        }),
        matchTutorialVideosEnabled: Validators.boolean({
            description:
                "Enables the match tutorial video button (if a video was set for the UI locale)",
            defaultValue: false,
        }),
        matchTutorialVideos: Validators.shape({
            description: "Sets match tutorial videos per language.",
            shape: {
                nl: Validators.string({
                    description: "nl match tutorial video.",
                }).allowsUndefined,
                fr: Validators.string({
                    description: "fr match tutorial video.",
                }).allowsUndefined,
                en: Validators.string({
                    description: "en match tutorial video.",
                }).allowsUndefined,
                it: Validators.string({
                    description: "it match tutorial video.",
                }).allowsUndefined,
            },
        }),
        selectAllCustomizable: Validators.boolean({
            description:
                'If true, instead of a "select all" checkbox, you get a number field to type in the number of results to select.',
            defaultValue: false,
        }),
        runAfterSelection: Validators.func({
            description: "Some function to run after selection was made for any index.",
        }).allowsUndefined,
        dateFormat: Validators.string({
            description: "Format used to display dates in the UI",
            defaultValue: "YYYY-MM-DD",
        }),
        timeFormat: Validators.string({
            description: "Format used to display times in the UI",
            defaultValue: "HH:mm:ss",
        }),
        dateTimeFormat: Validators.string({
            description: "Format used to display dates with times in the UI",
            defaultValue: "YYYY-MM-DD HH:mm:ss",
        }),
        sortWorkExperiences: Validators.boolean({
            description: "Sort the work experiences by dateFrom",
            defaultValue: false,
        }),
    },
});
