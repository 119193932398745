import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import {FormattedMessage} from "react-intl";
import MatchComparisonTable from "~/components/MatchComparisonTable";
import styles from "./styles.module.scss";
import DynamicProperty from "~/prop-types/dynamic-property";

export default class MatchComparisonDialog extends React.PureComponent {
    static propTypes = {
        indexConfig: PropTypes.object.isRequired,
        indexState: PropTypes.object.isRequired,
        matches: PropTypes.array, // TODO: More specific
        detailsTitleProperty: DynamicProperty,
        comparisonSubtitleProperty: DynamicProperty,
        comparisonExtraProperties: PropTypes.arrayOf(PropTypes.string),
        titleLabel: PropTypes.string.isRequired,
        reverseLabel: PropTypes.string.isRequired,
        reverseHelp: PropTypes.string.isRequired,
        onHide: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        resultType: PropTypes.string.isRequired,
    };

    render() {
        const {
            indexConfig,
            indexState,
            matches,
            detailsTitleProperty,
            comparisonSubtitleProperty,
            comparisonExtraProperties,
            titleLabel,
            reverseLabel,
            reverseHelp,
            onHide,
            onSelectionChange,
            resultType,
        } = this.props;

        return (
            <Modal
                size="xl"
                show={true}
                centered
                onHide={onHide}
                scrollable={true}
                dialogClassName={styles.dialog}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <FormattedMessage id={titleLabel} values={{count: matches.length}} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.scrollContainer}>
                        <MatchComparisonTable
                            indexConfig={indexConfig}
                            indexState={indexState}
                            matches={matches}
                            detailsTitleProperty={detailsTitleProperty}
                            comparisonSubtitleProperty={comparisonSubtitleProperty}
                            comparisonExtraProperties={comparisonExtraProperties}
                            reverseLabel={reverseLabel}
                            reverseHelp={reverseHelp}
                            onSelectionChange={onSelectionChange}
                            resultType={resultType}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
