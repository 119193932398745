import "~/config/validation/validators/AllowsUndefinedValidator"; // MUST be imported to register the "allowUndefined" property
import "~/config/validation/validators/AllowsNullValidator"; // MUST be imported to register the "allowsNull" property
import "~/config/validation/validators/CustomValidator"; // MUST be imported to register the "custom" property

import ShapeValidator from "~/config/validation/validators/ShapeValidator";
import BooleanValidator from "~/config/validation/validators/BooleanValidator";
import StringValidator from "~/config/validation/validators/StringValidator";
import ArrayOfValidator from "~/config/validation/validators/ArrayOfValidator";
import EnumValidator from "~/config/validation/validators/EnumValidator";
import IntegerValidator from "~/config/validation/validators/IntegerValidator";
import FuncValidator from "~/config/validation/validators/FuncValidator";
import AnyValidator from "~/config/validation/validators/AnyValidator";
import OneOfValidator from "~/config/validation/validators/OneOfValidator";
import OneOfTypeValidator from "~/config/validation/validators/OneOfTypeValidator";
import ArrayValidator from "~/config/validation/validators/ArrayValidator";
import ObjectValidator from "~/config/validation/validators/ObjectValidator";
import InstanceOfValidator from "~/config/validation/validators/InstanceOfValidator";
import ObjectOfValidator from "~/config/validation/validators/ObjectOfValidator";

export default {
    any: ({description, defaultValue}) => new AnyValidator(description, defaultValue),
    array: ({description, defaultValue}) => new ArrayValidator(description, defaultValue),
    arrayOf: ({description, items}) => new ArrayOfValidator(description, items),
    instanceOf: ({description, objectType}) => new InstanceOfValidator(description, objectType),
    boolean: ({description, defaultValue}) => new BooleanValidator(description, defaultValue),
    enum: ({description, defaultValue, enum: enumType}) => new EnumValidator(description, defaultValue, enumType),
    func: ({description, defaultValue}) => new FuncValidator(description, defaultValue),
    integer: ({description, defaultValue, maxValue}) => new IntegerValidator(description, defaultValue, maxValue),
    oneOf: ({description, defaultValue, validValues}) => new OneOfValidator(description, defaultValue, validValues),
    oneOfType: ({description, defaultValue, validTypes}) => new OneOfTypeValidator(description, defaultValue, validTypes),
    shape: ({description, shape}) => new ShapeValidator(description, shape),
    string: ({description, defaultValue}) => new StringValidator(description, defaultValue),
    object: ({description, defaultValue}) => new ObjectValidator(description, defaultValue),
    objectOf: ({description, values}) => new ObjectOfValidator(description, values),
};
