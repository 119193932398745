import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-date-picker";
import c from "classnames";
import moment from "moment";
import Select from "~/components/Select";
import NumberInput from "~/components/NumberInput";
import config from "~/config";
import styles from "./styles.module.scss";
import PredicateEditorPropTypes from "~/components/PredicateEditor/prop-types";
import {FormattedMessage} from "react-intl";
import {momentFormatToUTS35} from "~/util/date-time-formatting";

const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

const COMPARISONS = {
    isBefore: {
        label: {id: "predicateEditor.isBefore"},
        inputType: "date-picker",
        toFilter: (property, data) => {
            return {
                range: {
                    [property]: {
                        lt: moment(data.dateValue).format("YYYY-MM-DD [00:00:00]"),
                        format: DATE_FORMAT,
                    },
                },
            };
        },
    },
    isAfter: {
        label: {id: "predicateEditor.isAfter"},
        inputType: "date-picker",
        toFilter: (property, data) => {
            return {
                range: {
                    [property]: {
                        gt: moment(data.dateValue).format("YYYY-MM-DD [23:59:59]"),
                        format: DATE_FORMAT,
                    },
                },
            };
        },
    },
    isOn: {
        label: {id: "predicateEditor.isOn"},
        inputType: "date-picker",
        toFilter: (property, data) => {
            return {
                range: {
                    [property]: {
                        gte: moment(data.dateValue).format("YYYY-MM-DD [00:00:00]"),
                        lte: moment(data.dateValue).format("YYYY-MM-DD [23:59:59]"),
                        format: DATE_FORMAT,
                    },
                },
            };
        },
    },
    isLessThan: {
        label: {id: "predicateEditor.isLessThan"},
        inputType: "interval",
        toFilter: (property, data) => {
            const comparator = data.stative === "-" ? "gte" : "lte";

            return {
                range: {
                    [property]: {
                        [comparator]: toDateMath(data),
                    },
                },
            };
        },
    },
    isMoreThan: {
        label: {id: "predicateEditor.isMoreThan"},
        inputType: "interval",
        toFilter: (property, data) => {
            const comparator = data.stative === "-" ? "lt" : "gt";

            return {
                range: {
                    [property]: {
                        [comparator]: toDateMath(data),
                    },
                },
            };
        },
    },
    isEmpty: {
        label: {id: "predicateEditor.isEmpty"},
        inputType: "none",
        toFilter: property => ({bool: {must_not: {exists: {field: property}}}}),
    },
    isNotEmpty: {
        label: {id: "predicateEditor.isNotEmpty"},
        inputType: "none",
        toFilter: property => ({bool: {must: {exists: {field: property}}}}),
    },
};

export default class StringProperty extends React.PureComponent {
    static propTypes = {
        propertyDefinition: PredicateEditorPropTypes.propertyDefinition.isRequired,
        data: PropTypes.shape({
            comparison: PropTypes.oneOf(Object.keys(COMPARISONS)),
            dateValue: PropTypes.instanceOf(Date),
            numberValue: PropTypes.number,
            unit: PropTypes.oneOf(["d", "w", "M", "y"]),
            stative: PropTypes.oneOf(["-", "+"]),
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultData() {
        return {
            comparison: "isLessThan",
            dateValue: null,
            numberValue: null,
            unit: "w",
            stative: "-",
        };
    }

    static toFilter(property, data, propertyDefinition) {
        const finalData =
            propertyDefinition.forcedStative !== undefined
                ? {
                      ...data,
                      stative: propertyDefinition.forcedStative,
                  }
                : data;

        const comparisonInfo = COMPARISONS[finalData.comparison];

        if (
            comparisonInfo.inputType === "none" ||
            (comparisonInfo.inputType === "date-picker" &&
                finalData.dateValue !== null &&
                finalData.dateValue !== undefined) ||
            (comparisonInfo.inputType === "interval" &&
                finalData.numberValue !== null &&
                finalData.numberValue !== undefined)
        ) {
            return comparisonInfo.toFilter(property, finalData);
        } else {
            return undefined;
        }
    }

    render() {
        const {data} = this.props;

        return (
            <React.Fragment>
                <Select
                    className={c(styles.givePriority, styles.comparisonSelect)}
                    selected={data.comparison}
                    onChange={this.handleComparisonChange}
                    options={COMPARISON_OPTIONS}
                    variant="extra-small"
                />
                {this.renderInput()}
            </React.Fragment>
        );
    }

    renderInput() {
        const {data} = this.props;
        const comparisonInfo = COMPARISONS[data.comparison];

        if (comparisonInfo.inputType === "date-picker") {
            return this.renderDatePickerInput();
        } else if (comparisonInfo.inputType === "interval") {
            return this.renderIntervalInput();
        }
    }

    renderDatePickerInput() {
        const {data} = this.props;

        return (
            <DatePicker
                className={styles.datePicker}
                value={data.dateValue}
                onChange={this.handleDateValueChange}
                format={config("ui.datePickerFormat", momentFormatToUTS35(config("ui.dateFormat")))}
                showLeadingZeros={true}
                clearIcon={null}
                calendarIcon={<i className="far fa-calendar" />}
                maxDate={MAX_DATE}
            />
        );
    }

    renderIntervalInput() {
        const {data, propertyDefinition} = this.props;

        return (
            <React.Fragment>
                <NumberInput
                    className={c(
                        styles.givePriority,
                        styles.numberInput,
                        "form-control",
                        "form-control-sm"
                    )}
                    value={data.numberValue}
                    onChange={this.handleNumberValueChange}
                />
                <Select
                    className={c(styles.givePriority, styles.unitSelect)}
                    selected={data.unit}
                    onChange={this.handleUnitChange}
                    options={UNIT_OPTIONS}
                    variant="extra-small"
                />
                {propertyDefinition.forcedStative === undefined ? (
                    <Select
                        className={c(styles.givePriority, styles.stativeSelect)}
                        selected={data.stative}
                        onChange={this.handleStativeChange}
                        options={STATIVE_OPTIONS}
                        variant="extra-small"
                    />
                ) : (
                    <span className="ml-2">
                        <FormattedMessage id={STATIVE_LABEL[propertyDefinition.forcedStative]} />
                    </span>
                )}
            </React.Fragment>
        );
    }

    handleComparisonChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, comparison: option.value};
        onChange(nextData);
    };

    handleDateValueChange = dateValue => {
        const {data, onChange} = this.props;
        const nextData = {...data, dateValue};
        onChange(nextData);
    };

    handleNumberValueChange = numberValue => {
        const {data, onChange} = this.props;
        const nextData = {...data, numberValue};
        onChange(nextData);
    };

    handleUnitChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, unit: option.value};
        onChange(nextData);
    };

    handleStativeChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, stative: option.value};
        onChange(nextData);
    };
}

function toDateMath(data) {
    return `now${data.stative}${data.numberValue}${data.unit}`;
}

const COMPARISON_OPTIONS = Object.keys(COMPARISONS).map(key => ({
    label: COMPARISONS[key].label,
    value: key,
}));

const UNIT_OPTIONS = [
    {label: {id: "predicateEditor.days"}, value: "d"},
    {label: {id: "predicateEditor.weeks"}, value: "w"},
    {label: {id: "predicateEditor.months"}, value: "M"},
    {label: {id: "predicateEditor.years"}, value: "y"},
];

const STATIVE_LABEL = {
    "-": "predicateEditor.ago",
    "+": "predicateEditor.inTheFuture",
};

const STATIVE_OPTIONS = [
    {label: {id: STATIVE_LABEL["-"]}, value: "-"},
    {label: {id: STATIVE_LABEL["+"]}, value: "+"},
];

const MAX_DATE = new Date(9999, 12, 31);
