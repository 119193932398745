import FeaturesDefinition from "./features/FeaturesDefinition";
import Validators from "~/config/validation/validators/Validators";
import AspectsDefinition from "~/config/definitions/aspects/AspectsDefinition";
import ApiDefinition from "~/config/definitions/api/ApiDefinition";
import UiDefinition from "~/config/definitions/ui/UiDefinition";
import I18nDefinition from "~/config/definitions/i18n/I18nDefinition";
import LocalStorageDefinition from "~/config/definitions/localStorage/LocalStorageDefinition";
import EnabledDefinition from "~/config/definitions/shared/EnabledFalseDefinition";
import FiltersDefinition from "~/config/definitions/filters/FiltersDefinition";
import SortDefinition from "~/config/definitions/sort/SortDefinition";
import CustomFiltersDefinition from "~/config/definitions/filters/CustomFiltersDefinition";

export default Validators.shape({
    description: "Configuration root object",
    shape: {
        api: ApiDefinition,
        debug: Validators.shape({
            description: "Enables debug mode. Deprecated and almost unused.",
            shape: {
                enabled: EnabledDefinition,
            },
        }),
        features: FeaturesDefinition,
        aspects: AspectsDefinition,
        ui: UiDefinition,
        localStorage: LocalStorageDefinition,
        matching: Validators.shape({
            description: "Matching settings.",
            shape: {
                excludeExistingMatches: Validators.boolean({
                    description: "If true, excludes existing matches from results.",
                    defaultValue: false,
                }),
            },
        }),
        languages: Validators.arrayOf({
            description:
                "An array listing the available languages. This is not meant to be modified. Probably should be moved to a different location and/or implemented differently.",
            items: Validators.string({
                description: "An available language.",
            }).allowsUndefined,
            defaultValue: ["DUT", "FRE", "ENG", "GER"],
        }),
        filters: FiltersDefinition,
        customFilters: CustomFiltersDefinition,
        sort: SortDefinition,
        enums: Validators.object({
            description: "Enumerable values",
            defaultValue: {},
        }),
        i18n: I18nDefinition,
    },
});
