import urlJoin from "url-join";
import buildQuery from "~/util/build-query";
import ApiCallError from "~/api/ApiCallError";

/**
 * Returns an abortable API call, which is a Promise with an extra .abort() function.
 *
 * @param method
 * @param url
 * @param queryParameters
 * @param body
 * @param headers
 * @param tokenPromise
 * @param options
 * @returns {Promise}
 */
export default function call(
    method,
    url,
    queryParameters = {},
    body = null,
    headers = {},
    tokenPromise = Promise.resolve(),
    options = {
        dontDecode: false,
        responseType: undefined,
    }
) {
    const {dontDecode, responseType} = options;
    const request = new XMLHttpRequest();

    // Create a Promise to handle the result
    const promise = new Promise(async (resolve, reject) => {
        // Create request URL
        const queryString = buildQuery(queryParameters, true);
        const finalUrl = urlJoin(url, queryString);
        request.open(method, finalUrl);

        // Set headers
        for (const name in headers) {
            request.setRequestHeader(name, headers[name]);
        }

        // Await token
        const token = await tokenPromise;

        if (token !== undefined) {
            request.setRequestHeader("Authorization", `Bearer ${token}`);
        }

        // Set response type if defined
        if (responseType) {
            request.responseType = responseType;
        }

        function getResponse(request, forgiving) {
            if (
                !dontDecode &&
                (request.responseType === "json" ||
                    request.getResponseHeader("Content-Type") === "application/json")
            ) {
                try {
                    return JSON.parse(request.response);
                } catch (e) {
                    if (forgiving) {
                        return request.response;
                    } else {
                        throw e;
                    }
                }
            } else {
                return request.response;
            }
        }

        // Register event handlers
        request.onabort = () => {
            reject(new ApiCallError(request, getResponse(request, true), "Call failed due to abort"));
        };

        request.onerror = () => {
            reject(new ApiCallError(request, getResponse(request, true), `Call failed due to error (status ${request.status})`));
        };

        request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
                resolve({request, response: getResponse(request, false)});
            } else {
                reject(
                    new ApiCallError(
                        request,
                        getResponse(request, true),
                        `Call failed due to unsuccessful HTTP status ${request.status}`
                    )
                );
            }
        };

        // Execute request
        if (body !== null) {
            if (headers["Content-Type"] === undefined) {
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                request.send(JSON.stringify(body));
            } else {
                request.send(body);
            }
        } else {
            request.send();
        }
    });

    promise.abort = function() {
        request.abort();
    };

    return preserveAbort(promise);
}

// It would be better to rewrite abortableCall() to return both a promise and an abort call, but this means rewriting a
// lot of client code and there is no time right now.
function preserveAbort(promise) {
    const originalThen = promise.then.bind(promise);
    const originalCatch = promise.catch.bind(promise);
    const originalFinally = promise.finally.bind(promise);

    promise.then = function(onFulfilled, onRejected) {
        const resultingPromise = originalThen(onFulfilled, onRejected);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    promise.catch = function(onRejected) {
        const resultingPromise = originalCatch(onRejected);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    promise.finally = function(onFinally) {
        const resultingPromise = originalFinally(onFinally);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    return promise;
}
