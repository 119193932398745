import React from "react";
import PropTypes from "prop-types";

export default class EmailButton extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
    }

    render() {
        const {value} = this.props;

        if (!value) {
            return this.renderEmpty();
        }

        return (
            <a
                href={`mailTo:${value}`}
                title={value}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-secondary ml-1"
                onClick={stopPropagation}
            >
                <i className="fas fa-envelope" />
            </a>
        )
    }

    renderEmpty() {
        return (
            <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-secondary ml-1 disabled"
                onClick={stopPropagation}
            >
                <i className="fas fa-envelope" />
            </a>
        )
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}