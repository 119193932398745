import Match from "./Match";
import {convertCandidateFromApi} from "~/util/convert-from-api";

export default class CandidateMatch extends Match {
    _deprecatedCandidateConverted = false;
    _deprecatedCandidate = undefined;

    /**
     * @deprecated Do not use in new code
     */
    get candidate() {
        if (!this._deprecatedCandidateConverted) {
            this._deprecatedCandidateConverted = true;
            this._deprecatedCandidate = this._data.document !== undefined ? convertCandidateFromApi(this._data.document) : undefined;
        }

        return this._deprecatedCandidate;
    }

    get detailsText() {
        return {
            label: "detailsModal.candidateText",
            value: this._data.document.cvText,
        };
    }

    /**
     * @deprecated Do not use in new code
     */
    get deprecatedEntity() {
        return this.candidate;
    }
}
