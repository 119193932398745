import {createSelector} from "reselect";
import {convertToTreeModel, Node} from "./model";

export function createTreeSelectors(self) {
    const modelSelector = props => props.model;
    const valueSelector = props => props.value;
    const expandedSelector = props => props.expanded;

    const convertToTreeModelSelector = createSelector(
        modelSelector,
        convertToTreeModel
    );

    const valueAsMapSelector = createSelector(
        valueSelector,
        nodeValueArrayToMap,
    );

    const expandedAsMapSelector = createSelector(
        expandedSelector,
        nodeValueArrayToMap,
    );

    return {
        /**
         * @return {Node[]}
         */
        convertToTreeModelSelector: () => convertToTreeModelSelector(self.props),

        /**
         * @return Map
         */
        valueAsMapSelector: () => valueAsMapSelector(self.props),

        /**
         * @return Map
         */
        expandedAsMapSelector: () => expandedAsMapSelector(self.props),
    };
}

function nodeValueArrayToMap(value) {
    if (value === undefined || value === null) {
        return new Map();
    } else if (Array.isArray(value)) {
        return value.reduce((map, v) => map.set(v, true), new Map());
    } else {
        return value;
    }
}

export function createNodeSelectors(self) {
    const nodeSelector = props => props.node;
    const valueSelector = props => props.value;

    const checkedSelector = createSelector(
        nodeSelector,
        valueSelector,
        (node, value) => {
            if (value.has(node.value)) {
                return true;
            }

            for (const child of node.recursiveChildIterator()) {
                if (value.has(child.value)) {
                    return undefined;
                }
            }

            return false;
        }
    );

    return {
        checkedSelector: () => checkedSelector(self.props),
    };
}
