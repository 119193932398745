import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Api from "~/api";
import {createMultiSelectOptionsSelector, valueToMultiSelectValue} from "~/util/filter-values";
import MultiSelect from "react-select";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import ProcessButton from "~/components/ProcessButton";

export default class AddToGroupDialog extends React.PureComponent {
    static propTypes = {
        config: PropTypes.shape({
            getGroupsEndpoint: PropTypes.string.isRequired,
            addToGroupEndpoint: PropTypes.string.isRequired,
        }),
        memberIds: PropTypes.array.isRequired,
        show: PropTypes.bool.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            groups: undefined,
            selectedGroupId: undefined,
            processing: false,
            overallSuccess: undefined,
            errorMessageKeys: undefined,
        };

        this.multiSelectOptionsSelector = createMultiSelectOptionsSelector({
            valueProperty: "id",
            labelMapProperty: "labels",
        });
    }

    componentDidMount() {
        const {config} = this.props;

        Api.get(config.getGroupsEndpoint)
            .then(({response}) => {
                this.setState({groups: response.groups});
            })
            .catch(e => {
                console.error("Couldn't load groups");
                console.error(e);
                this.setState({groups: []});
            });
    }

    render() {
        const {groups, selectedGroupId, processing} = this.state;
        const options = this.multiSelectOptionsSelector(groups);

        return (
            <Modal show={this.props.show}>
                <Modal.Body>
                    <MultiSelect
                        value={valueToMultiSelectValue(selectedGroupId, options)}
                        options={options}
                        onChange={this.handleChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {/*{wasError && (*/}
                    {/*    <div className="text-danger">*/}
                    {/*        <FormattedMessage id="createNote.error" />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <Button variant="secondary" disabled={processing} onClick={this.handleHide}>
                        <FormattedMessage id="action.cancel" />
                    </Button>
                    <ProcessButton
                        variant="success"
                        onClick={this.handleAddClick}
                        processing={processing}
                        disabled={selectedGroupId === undefined}
                        label={<FormattedMessage id="action.add"/>}
                    />
                </Modal.Footer>
            </Modal>
        );
    }

    handleChange = option => {
        this.setState({selectedGroupId: option.value});
    };

    handleAddClick = () => {
        const {config, memberIds} = this.props;
        const {selectedGroupId} = this.state;

        if (!config.addToGroupEndpoint) {
            this.setState({errorMessageKeys: ["addToGroup.notProperlyConfigured"]});
            return;
        }

        this.setState({processing: true});

        Api.post(config.addToGroupEndpoint, {
            groupId: selectedGroupId,
            memberIds,
        })
            .then(({response}) => {
                const errorMessageKeys = [];

                if (!response.success) {
                    errorMessageKeys.push(response.errorMessageKey);
                }

                for (const memberId in response.memberResponses) {
                    const memberResponse = response.memberResponses[memberId];

                    if (!memberResponse.success) {
                        errorMessageKeys.push(response.errorMessageKey);
                    }
                }

                if (errorMessageKeys.length === 0) {
                    this.handleHide();
                } else {
                    this.setState({
                        processing: false,
                        overallSuccess: response.success,
                        errorMessageKeys,
                    });
                }
            })
            .catch(e => {
                console.error(e);

                this.setState({
                    processing: false,
                    errorMessageKeys: [e.message],
                });
            });
    };

    handleHide = () => {
        const {onHide} = this.props;

        this.setState({
            processing: false,
            overallSuccess: undefined,
            errorMessageKeys: undefined,
        });

        onHide();
    };
}
