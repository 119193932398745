import PropTypes from "prop-types";
import {Node} from "./model";

export const NodePropType = PropTypes.instanceOf(Node);

export const TreePickerModelPropType = PropTypes.oneOfType([
    PropTypes.arrayOf(NodePropType),
    PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        valueDefinition: PropTypes.shape({
            idPath: PropTypes.string.isRequired,
            parentIdPath: PropTypes.string.isRequired,
            valuePath: PropTypes.string.isRequired,
            labelPath: PropTypes.string.isRequired,
        }).isRequired,
    }),
    PropTypes.shape({
        endpoint: PropTypes.string.isRequired,
        valueDefinition: PropTypes.shape({
            idPath: PropTypes.string.isRequired,
            parentIdPath: PropTypes.string.isRequired,
            valuePath: PropTypes.string.isRequired,
            labelPath: PropTypes.string.isRequired,
        }).isRequired,
    })
]);

export const ValuePropType = PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(Map),
]);