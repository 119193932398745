import Validators from "~/config/validation/validators/Validators";

export default Validators.shape({
    description: "Standalone match details configuration",
    shape: {
        indexSettings: Validators.objectOf({
            description: "Settings for source indices",
            values: Validators.shape({
                description: "Settings for a single source index",
                shape: {
                    matchingStrategyName: Validators.string({
                        description: "Matching strategy to use when requesting the match details",
                    }).allowsUndefined,
                }
            }),
        }),
    },
});
