import PropTypes from "prop-types";
import {AVAILABLE_LOCALES} from "~/config/base-config";

export default PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({id: PropTypes.string.isRequired}),
    PropTypes.shape(AVAILABLE_LOCALES.reduce((theMap, locale) => {
        theMap[locale] = PropTypes.string;
        return theMap;
    }, {})),
]);
