import objectPath from "object-path";
import clone from "clone";
import {isPlainObject, isString} from "~/util/misc";
import {toObjectPath} from "~/util/object-path";

export default class ModifiableConfig {
    constructor(config = {}, variables = {}) {
        this.config = config;
        this.variables = variables;
        this.appliedModifiers = [];
        this.afterApiCallbacks = [];
    }

    clone() {
        return new ModifiableConfig(clone(this.config), clone(this.variables));
    }

    addAppliedModifier(name) {
        this.appliedModifiers.push(name);
    }

    hasAppliedModifier(name) {
        return this.appliedModifiers.includes(name);
    }

    /**
     * Registers a callback to be called after the API configuration overrides have been applied. Gives a last chance
     * to make changes to the config. The current config is passed to the callback.
     *
     * @param {ModifiableConfig~callback} callback
     */
    afterApi(callback) {
        this.afterApiCallbacks.push(callback);
    }

    afterApiBeforeOthers(callback) {
        this.afterApiCallbacks.unshift(callback);
    }

    performAfterApiCallbacks() {
        for (const callback of this.afterApiCallbacks) {
            callback(this);
        }
    }

    setVariable(name, value) {
        this.variables[name] = value;
    }

    setManyVariables(values) {
        for (const name in values) {
            if (!values.hasOwnProperty(name)) continue;
            this.setVariable(name, values[name]);
        }
    }

    set(path, value) {
        objectPath.set(this.config, toObjectPath(path), value);
    }

    setIfUndefined(path, value) {
        const objectPathPath = toObjectPath(path)

        if (objectPath.get(this.config, objectPathPath) === undefined) {
            objectPath.set(this.config, objectPathPath, value);
        }
    }

    setMany(values) {
        for (const path in values) {
            if (!values.hasOwnProperty(path)) continue;
            this.set(path, values[path]);
        }
    }

    push(path, value) {
        objectPath.push(this.config, toObjectPath(path), value);
    }

    merge(path, values) {
        const objectPathPath = toObjectPath(path);
        let existingValue = objectPath.get(this.config, objectPathPath);

        if (typeof existingValue !== "object" || existingValue === null) {
            existingValue = {};
            objectPath.set(this.config, objectPathPath, existingValue);
        }

        for (const key in values) {
            if (!values.hasOwnProperty(key)) continue;
            existingValue[key] = values[key];
        }
    }

    get(path, defaultValue) {
        if (path === undefined) {
            return this.applyVariables(clone(this.config));
        } else {
            return this.applyVariables(clone(objectPath.get(this.config, toObjectPath(path), defaultValue)));
        }
    }

    getRaw(path, defaultValue) {
        if (path === undefined) {
            return clone(this.config);
        } else {
            return clone(objectPath.get(this.config, toObjectPath(path), defaultValue));
        }
    }

    applyVariables = (item) => {
        if (isString(item)) {
            return this.applyVariablesToString(item);
        } else if (Array.isArray(item)) {
            return this.applyVariablesToArray(item);
        } else if (isPlainObject(item)) {
            return this.applyVariablesToObject(item);
        } else {
            return item;
        }
    }

    applyVariablesToString(string) {
        const match = VARIABLE_REGEX_SINGLE.exec(string);

        if (match) {
            return this.variables[match[1]];
        }

        return string.replaceAll(VARIABLE_REGEX, (match, p1) => {
            if (this.variables[p1] !== undefined) {
                return this.variables[p1];
            } else {
                throw new Error(`Unresolved variable in config: ${p1}`);
            }
        });
    }

    applyVariablesToArray(array) {
        return array.map(this.applyVariables);
    }

    applyVariablesToObject(object) {
        const result = {};

        for (const property in object) {
            if (object.hasOwnProperty(property)) {
                result[String(this.applyVariables(property))] = this.applyVariables(object[property]);
            }
        }

        return result;
    }
}

const VARIABLE_REGEX_SINGLE = /^#{(.+?)}$/;
const VARIABLE_REGEX = /#{(.+?)}/g;

/**
 * This is the callback passed to the afterApi() function.
 * @callback ModifiableConfig~callback
 * @param {ModifiableConfig} config
 */
